import { AdminAPI } from "../../helper/API/API.base";
import { getFormDataHeaders, getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  ADD_FRAME_TYPE_SUCCESS,
  DELETE_FRAME_TYPE_SUCCESS,
  GET_ALL_FRAME_TYPES_FAILURE,
  GET_ALL_FRAME_TYPES_REQUEST,
  GET_ALL_FRAME_TYPES_SUCCESS,
  UPDATE_FRAME_TYPE_SUCCESS,
} from "../constants/frameTypes";

export const addFrameType = (frameType) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = frameType;

      // API
      const response = await AdminAPI.post("/frame-type", body, { headers });

      dispatch({
        type: ADD_FRAME_TYPE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame Type added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

// TODO: Remove Headers
export const getAllFrameTypes = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_FRAME_TYPES_REQUEST });

      // API
      const response = await AdminAPI.get("/frame-type", { headers });

      const frameTypesList = response?.data.map(
        ({ _id: id, frame_type, image, is_active }) => {
          return { id, frame_type, image, is_active };
        }
      );
      dispatch({
        type: GET_ALL_FRAME_TYPES_SUCCESS,
        payload: frameTypesList,
      });

      resolve(response);
    } catch (error) {

      dispatch({
        type: GET_ALL_FRAME_TYPES_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteFrameType = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const frameTypeId = values.id;

      // API
      const response = await AdminAPI.delete(`/frame-type/${frameTypeId}`, {
        headers,
      });
      dispatch({
        type: DELETE_FRAME_TYPE_SUCCESS,
        payload: frameTypeId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame Type deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const editFrameType = (values, frameTypeId) => async (dispatch) => {
  try {
    const headers = await getFormDataHeaders();

    // AXIOS BODY
    const body = values;

    // API
    const response = await AdminAPI.put(`/frame-type/${frameTypeId}`, body, {
      headers,
    });

    let {
      frameType: { _id: id, image, frame_type, is_active },
    } = response.data;
    dispatch({
      type: UPDATE_FRAME_TYPE_SUCCESS,
      payload: { id, image, frame_type, is_active },
    });

    if (response?.data?.message?.status === 200) {
      SuccessToast("Frame Type updated successfully!");
    } else if (response?.data?.res?.status === 500) {
      ErrorToast(response?.data?.res?.message);
    }
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error")
      ErrorToast(toastMessage?.NETWORK_ERROR);
    else return;
  }
};
