/**
 * The `ModalCloseButton` component is a React component that renders a close button for a modal.
 * @returns The `ModalCloseButton` component is returning a JSX element. It renders a `<div>` element
 * with the class name "modal_close_btn_main_top" and within it, another `<div>` element with the class
 * name "modal_close_btn_main". Inside the second `<div>` element, there is a `ModalCloseButtonWrapper`
 * component that wraps the `<CgClose />` component from the
 */
import React from "react";
import { CgClose } from "react-icons/cg";
import "./modalCloseButton.css";
import { ModalCloseButtonWrapper } from "../../assets/css/styled";

const ModalCloseButton = () => {
  return (
    <>
      <div className="modal_close_btn_main_top">
        <div className="modal_close_btn_main">
          <ModalCloseButtonWrapper>
            <CgClose />
          </ModalCloseButtonWrapper>
        </div>
      </div>
    </>
  );
};

export default ModalCloseButton;
