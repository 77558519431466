import {
  GOOGLE_SIGN_IN_FAILURE,
  GOOGLE_SIGN_IN_REQUEST,
  GOOGLE_SIGN_IN_SUCCESS,
  LOGIN_ADMIN_FAILURE,
  LOGIN_ADMIN_REQUEST,
  LOGIN_ADMIN_SUCCESS,
  OTP_VERIFY_ADMIN_FAILURE,
  OTP_VERIFY_ADMIN_REQUEST,
  OTP_VERIFY_ADMIN_SUCCESS,
  RESET_USER_PASS_FAILURE,
  RESET_USER_PASS_REQUEST,
  RESET_USER_PASS_SUCCESS,
} from "../../helper/config/constantsRedux.config";

/**
 * The `loginUserReducer` function is a reducer that handles different actions related to logging in a
 * user and updates the state accordingly.
 * @param [state] - The state parameter represents the current state of the loginUserReducer. It is an
 * object that contains the loginUser property, which holds the data of the logged-in user.
 * @param action - The `action` parameter represents the action object that is dispatched to the
 * reducer. It contains information about the action type and any additional data that is needed to
 * update the state.
 * @returns The `loginUserReducer` function returns an object with the properties `loading`,
 * `loginUser`, and `error`. The initial state of `state` is `{ loginUser: {} }`.
 */
export const loginAdminReducer = (state = { loginAdmin: {} }, action) => {
  switch (action.type) {
    case LOGIN_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_ADMIN_SUCCESS:
      return {
        loading: false,
        loginAdmin: action?.payload,
      };

    case LOGIN_ADMIN_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};

export const googleSignInReducer = (state = { googleSignIn: {} }, action) => {
  switch (action.type) {
    case GOOGLE_SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GOOGLE_SIGN_IN_SUCCESS:
      return {
        loading: false,
        googleSignIn: action?.payload,
      };

    case GOOGLE_SIGN_IN_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};

/**
 * The `otpVerifyUserReducer` function is a reducer that handles different actions related to verifying
 * OTP for a user.
 * @param [state] - The state parameter represents the current state of the reducer. In this case, it
 * is an object with a property called "otpVerifyUser" which initially has an empty object as its
 * value.
 * @param action - The `action` parameter represents the action object that is dispatched by the Redux
 * store. It contains information about the action type and any additional data that is passed along
 * with the action. In this case, the action object is expected to have a `type` property that
 * corresponds to one of the action types
 * @returns The reducer is returning an updated state object based on the action type. If the action
 * type is "OTP_VERIFY_USER_REQUEST", it sets the "loading" property to true. If the action type is
 * "OTP_VERIFY_USER_SUCCESS", it sets the "loading" property to false and updates the "otpVerifyUser"
 * property with the payload from the action. If the action type is "OTP_VERIFY_USER
 */
export const otpVerifyAdminReducer = (
  state = { otpVerifyAdmin: {} },
  action
) => {
  switch (action.type) {
    case OTP_VERIFY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OTP_VERIFY_ADMIN_SUCCESS:
      return {
        loading: false,
        otpVerifyAdmin: action?.payload,
      };

    case OTP_VERIFY_ADMIN_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};

/**
 * The `resetUserPassReducer` function is a Redux reducer that handles the state updates for resetting
 * a user's password.
 * @param [state] - The state parameter represents the current state of the reducer. In this case, it
 * is an object with a property called "resetUserPass" which initially has an empty object as its
 * value.
 * @param action - The `action` parameter is an object that contains information about the action being
 * dispatched. It typically has a `type` property that specifies the type of action being performed,
 * and may also have a `payload` property that contains additional data related to the action.
 * @returns The reducer is returning an updated state object based on the action type. If the action
 * type is RESET_USER_PASS_REQUEST, it sets the loading property to true. If the action type is
 * RESET_USER_PASS_SUCCESS, it sets the loading property to false and updates the resetUserPass
 * property with the payload from the action. If the action type is RESET_USER_PASS_FAILURE, it sets
 * the loading property to false
 */
export const resetUserPassReducer = (state = { resetUserPass: {} }, action) => {
  switch (action.type) {
    case RESET_USER_PASS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RESET_USER_PASS_SUCCESS:
      return {
        loading: false,
        resetUserPass: action?.payload,
      };

    case RESET_USER_PASS_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
