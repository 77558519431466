import React, { useEffect, useState } from "react";
import {
  ButtonWrapper,
  FormInputLabel,
  Head24Tag,
  MainPadding,
} from "../../assets/css/styled";
import { Box, Modal } from "@mui/material";
import Header from "../../components/Header";
import Table from "../../components/Table";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { EditIcon } from "../../components/typography/Icons";
import { modelStyle } from "../../assets/css/muiStyled";
import { getFormDataHeaders, getHeaders } from "../../helper/API/API.header";
import { AdminAPI } from "../../helper/API/API.base";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Field, Form, Formik } from "formik";
import { walletBalanceSchema } from "../../helper/yup.schema";
import FormErrorMessage from "../../components/FormErrorMessage";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";

const ReferEarn = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({
    walletBalance: null,
  });
  const [editId, setEditId] = useState("");

  useEffect(() => {
    const ApiFetchUsers = async () => {
      try {
        const headers = await getHeaders();

        const response = await AdminAPI.get("/users", {
          headers,
        });
        const list = [];
        await response?.data?.forEach((x) => {
          list.push({
            id: x?._id,
            walletBalance: x?.walletBalance,
            name: x?.username || `${x?.firstname} ${x?.lastname}`
          });
        });

        await setData(list);
      } catch (error) {
        throw new Error(error)
      }
    };

    ApiFetchUsers();
  }, [open]);

  useEffect(() => {
    const handleGetSingle = async () => {
      const headers = await getHeaders();
      try {
        const response = await AdminAPI.get(`/users/${editId}`, {
          headers,
        });
        setFormInitialValues({
          walletBalance: response.data.walletBalance,
        });
      } catch (error) {
        throw new Error(error)
      }
    };

    if (editId) {
      handleGetSingle();
    }
  }, [open]);

  const handleEditClick = async (id) => {
    setEditId(id);
    setOpen(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "User Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      // renderCell: (params) => params.value.id,
    },
    {
      field: "walletBalance",
      headerName: "Wallet Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setFormInitialValues({
      walletBalance: null,
    });
  };

  const handleSubmit = async (values) => {
    const headers = await getFormDataHeaders();
    try {
      const formData = new FormData();
      formData.append("walletBalance", values.walletBalance);

      const response = await AdminAPI.put(`/users/${editId}`, formData, {
        headers,
      });
      if (response?.data?.message?.status === 200) {
        SuccessToast("Wallet Balance updated successfully!");
        setOpen(false);
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;
    }
  };

  return (
    <>
      <MainPadding p_bottom={20} p_top={20}>
        <Box m="20px" mb={0} mt={0}>
          <div className="flex w-full justify-between items-center">
            <Header
              title="Referal Code"
              subtitle="View All The Referal Codes"
            />
          </div>
          <Table columns={columns} rows={data} />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...modelStyle, height: "auto" }}>
              <div className="category_modal_add_form_main_div">
                <div className="payment_model_inner_head_main">
                  <Head24Tag color="#0E1213" weight="600">
                    {`Update Referal Wallet Balance`}
                  </Head24Tag>
                </div>
                <div
                  className="network_insurance_modal_close"
                  onClick={handleClose}
                >
                  <ModalCloseButton />
                </div>
              </div>
              <div className="category_add_frame_form">
                <Formik
                  initialValues={formInitialValues}
                  validationSchema={walletBalanceSchema}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({ touched, errors }) => (
                    <Form>
                      <div className="grid gap-5">
                        <div>
                          <FormInputLabel>Wallet Balance</FormInputLabel>
                          <div className="form_input_main">
                            <Field
                              type="number"
                              placeholder="Enter Wallet Balance"
                              name="walletBalance"
                              className="from_input_tag"
                            />
                          </div>
                          {touched.walletBalance && errors.walletBalance && (
                            <FormErrorMessage errors={errors.walletBalance} />
                          )}
                        </div>
                      </div>
                      <div className="form_sign_in_btn_main_inner mt-5">
                        <ButtonWrapper type="submit" auto={"auto"}>{`Update`}</ButtonWrapper>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Box>
          </Modal>
        </Box>
      </MainPadding>
    </>
  );
};

export default ReferEarn;
