/**
 * The `OtpVerification` component is a React component that renders a form for OTP verification,
 * including an input field for the OTP code, a timer for the OTP code expiration, and a button to
 * resend the OTP code.
 * @returns The component is returning a section element with a class name "auth_slider_main forgot
 * otp_verification". Inside the section, there is a grid with two columns. The first column contains
 * an AuthSlider component, and the second column contains the OTP verification form.
 */
import React from "react";
import "./otpVerification.css";
import AuthSlider from "../authSlider/AuthSlider";
import Timer from "otp-timer";
import OtpInput from "react-otp-input";
import {
  ButtonWrapper,
  Head48Tag,
  Para16Tag,
  MainPadding,
} from "../../../assets/css/styled";
import Back from "../../Back";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../../helper/Auth";
import { otpVerifyAdminAction } from "../../../redux/actions/adminAuthAction";

const OtpVerification = () => {
  const [otp, setOtp] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state?.otpVerifyAdmin);

  /**
   * The `hiddenEmail` function takes an email address as input and returns a masked version of the
   * email address with the username partially hidden.
   * @param email - The `email` parameter is a string that represents an email address.
   * @returns The function `hiddenEmail` returns a masked email address where only the first two
   * characters of the username are visible, and the rest are replaced with asterisks. The masked email
   * address is formed by concatenating the masked username with the original domain.
   */
  const hiddenEmail = (email) => {
    const [username, domain] = email.split("@");
    const maskedUsername =
      username.substring(0, 2) + "*".repeat(username.length - 2);

    return `${maskedUsername}@${domain}`;
  };

  /* The code `const originalEmail = Auth?.getUserEmail();` is retrieving the user's email address
  using the `getUserEmail()` function from the `Auth` object. The `Auth` object is likely a helper
  or utility function that provides authentication-related functionality. */
  const originalEmail = Auth?.getAdminEmail();
  const maskedEmail = hiddenEmail(originalEmail);

  /**
   * The handleSubmit function is used to handle form submission and verify an OTP (one-time password)
   * for a user.
   * @param e - The parameter `e` is an event object that is passed to the `handleSubmit` function. It
   * is typically an event object that is triggered when a form is submitted.
   * @returns The code is returning a Promise.
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (otp) {
      return new Promise(async (resolve, reject) => {
        try {
          await dispatch(otpVerifyAdminAction(otp, navigate));
          resolve();
        } catch (error) {
          reject(error);
        }
      }).then(() => {
        setOtp("");
      });
    }
  };

  /**
   * The function `resendOtp` asynchronously calls the `handleSubmit` function.
   */
  const resendOtp = async (e) => {
    e.preventDefault();

    await handleSubmit();
  };

  return (
    <section className="auth_slider_main login  otp_verification">
      <div className="grid grid-cols-2">
        <div className="login_left_main_page">
          <AuthSlider />
        </div>
        <div className="login_right_main_page">
          <div className="login_right_back_page_main_div_inner">
            <MainPadding p_bottom={20}>
              <Back text={"Back"} />
            </MainPadding>
          </div>
          <div className="login_right_inner_outer_info">
            <div className="login_text_main_title_top_div_inner">
              <div className="login_text_main_title">
                <Head48Tag style={{ textAlign: "center" }}>
                  Otp Verification
                </Head48Tag>
              </div>
              <div className="login_text_main_title_para_inner_information">
                <Para16Tag weight={500} color={"#868A8B"} center={"center"}>
                  {"Please enter the Verification code sent to "}
                  <span
                    style={{ fontWeight: "bold" }}
                    className="opt_send_number_inner"
                  >
                    {maskedEmail}
                  </span>
                </Para16Tag>
              </div>
            </div>
            <div className="auth_form_div">
              <form className="auth_page_form_main">
                <div className="auth_page_from_inner_div">
                  <form id="login">
                    <div className="otp_input_text_filed_set">
                      <OtpInput
                        value={otp}
                        // inputType={"number"}
                        onChange={setOtp}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                        isInputNum={true}
                        inputStyle="inputClass"
                      />
                    </div>
                    <div className="timer_btn_value">
                      <Timer
                        seconds={20}
                        minutes={1}
                        resend={resendOtp}
                        text={
                          <span className="otp_verify_code_num_inner_second">
                            Verification code
                          </span>
                        }
                        ButtonText={
                          <span
                            style={{ background: "transparent" }}
                            className="resend_otp"
                          >
                            <Para16Tag weight={500} color="#219EBC">
                              Resend OTP
                            </Para16Tag>
                          </span>
                        }
                        className="oyp_verification_add_code_num"
                      />
                    </div>
                  </form>
                </div>
                <div className="form_sign_in_btn_main">
                  <ButtonWrapper width="100%" onClick={handleSubmit}>
                    {loading ? "loading..." : "Verify"}
                  </ButtonWrapper>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default OtpVerification;
