import {
  ADD_TRENDING_SEARCH_SUCCESS,
  LIST_ALL_TRENDING_SEARCHES_REQUEST,
  LIST_ALL_TRENDING_SEARCHES_SUCCESS,
  LIST_ALL_TRENDING_SEARCHES_FAILURE,
  UPDATE_TRENDING_SEARCH_SUCCESS,
  CREATE_TRENDING_SEARCH_SUCCESS,
  DELETE_TRENDING_SEARCH_SUCCESS,
} from "../constants/trendingSearch";

const initialState = {
  trendingSearchList: [],
  loading: false,
  error: null,
};

let trendingSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TRENDING_SEARCH_SUCCESS:
      return {
        ...state,
        trendingSearchList: [...state.trendingSearchList, action.payload],
      };

    case LIST_ALL_TRENDING_SEARCHES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_TRENDING_SEARCHES_SUCCESS:
      return {
        ...state,
        trendingSearchList: action?.payload,
        loading: false,
      };

    case LIST_ALL_TRENDING_SEARCHES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_TRENDING_SEARCH_SUCCESS: {
      const updatedTrendingSearch = action.payload.trendingSearch;
      return {
        ...state,
        trendingSearchList: state.trendingSearchList.map((trendingSearch) => {
          if (trendingSearch.id === updatedTrendingSearch._id) {
            return {
              ...trendingSearch,
              title: updatedTrendingSearch.title,
            };
          }
          return trendingSearch;
        }),
      };
    }

    case CREATE_TRENDING_SEARCH_SUCCESS: {
      const { trendingSearch } = action.payload;
      const newTrendingSearch = {
        id: trendingSearch._id,
        title: trendingSearch.title,
      };
      return {
        ...state,
        trendingSearchList: [...state.trendingSearchList, newTrendingSearch],
      };
    }

    case DELETE_TRENDING_SEARCH_SUCCESS: {
      const trendingSearchId = action.payload;
      return {
        ...state,
        trendingSearchList: state.trendingSearchList.filter((trendingSearch) => trendingSearch.id !== trendingSearchId),
      };
    }

    default:
      return state;
  }
};

export default trendingSearchReducer;
