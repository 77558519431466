import { Button, styled } from "@mui/material";
import React, { useState } from "react";
import FrameShapes from "../frameShapes/FrameShapes";
// import Brands from "../brands/Brands";
import Category from "../category/Category";
import FaceTypes from "../faceTypes/FaceTypes";
import FrameTypes from "../frameTypes/FrameTypes";
import FrameMaterial from "../frameMaterial/FrameMaterial";
import FrameSize from "../frameSize/FrameSize";

const tabs = [
  // { key: "brand", label: "Brand" },
  { key: "category", label: "Category" },
  { key: "face_types", label: "Face types" },
  { key: "frame_material", label: "Frame Material" },
  { key: "frame_types", label: "Frame Types" },
  { key: "frame_size", label: "Frame Size" },
  { key: "frame_shapes", label: "Frame Shapes" },
];

const Wrapper = () => {
  const [activeTab, setActiveTab] = useState("brand");

  const handleClick = (tabName) => {
    setActiveTab(tabName);
  };

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    // borderRadius: "14px",
    lineHeight: 1.5,
    backgroundColor: "#219ebc",
    color: "white",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      backgroundColor: "#178AA6",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#178AA6",
      borderColor: "none",
    },
    "&:focus": {
      borderColor: "none",
    },
  });

  return (
    <>
      <div className="flex m-5 justify-start gap-4">
        {tabs.map((tab) => (
          <BootstrapButton
            key={tab.key}
            variant={activeTab === tab.key ? "contained" : "text"}
            onClick={() => handleClick(tab.key)}
          >
            {tab.label}
          </BootstrapButton>
        ))}
      </div>
      {activeTab === "frame_shapes" ? (
        <FrameShapes />
      ) : activeTab === "face_types" ? (
        <FaceTypes />
      ) : activeTab === "frame_types" ? (
        <FrameTypes />
      ) : activeTab === "frame_material" ? (
        <FrameMaterial />
      ) : activeTab === "frame_size" ? (
        <FrameSize />
      ) : (
        <Category />
      )}
    </>
  );
};

export default Wrapper;
