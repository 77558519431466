/**
 * The `Forgot` component is a React functional component that renders a form for users to reset their
 * password by entering their email.
 * @returns The `Forgot` component is returning a JSX element.
 */
import React from "react";
import "./forgot.css";
import AuthSlider from "../authSlider/AuthSlider";
import { EmailIcon } from "../../typography/Icons";
import { ButtonWrapper, FormInputLabel, FormInputOuter, Head48Tag, MainPadding, Para16Tag } from "../../../assets/css/styled";
import { Field, Form, Formik } from "formik";
import Back from "../../Back";
import { forgotPasswordSchema } from "../../../helper/yup.schema";
import { useNavigate } from "react-router-dom";
import { ApiForgotPassword } from "../../../helper/API/APICall";
import { ErrorToast, SuccessToast } from "../../../helper/Toast";
import { routesConfig } from "../../../helper/config/routes.config";
import FormErrorMessage from "../../../components/FormErrorMessage";
import { Auth } from "../../../helper/Auth";

const Forgot = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      // API
      const response = await ApiForgotPassword(values);

      if (response?.data?.status === 200) {
        Auth?.setAdminEmail(values?.email);
        SuccessToast("Validation code sent successfully.. expired in 2 minutes..");
        navigate(routesConfig?.verifyEmail);
        resetForm();
      } else if (response?.data?.status === 400) ErrorToast(response?.data?.message);
      else {
        ErrorToast("Please check your email!");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="auth_slider_main forgot">
        <div className="grid grid-cols-2">
          <div className="login_left_main_page">
            <AuthSlider />
          </div>

          <div className="login_right_main_page">
            <div className="login_right_back_page_main_div_inner">
              <MainPadding p_bottom={20}>
                <Back text={"Back"} />
              </MainPadding>
            </div>
            <div className="login_right_inner_outer_info">
              <div className="login_text_main_title_top_div_inner">
                <div className="login_text_main_title">
                  <Head48Tag style={{ textAlign: "center" }}>Forgot Password?</Head48Tag>{" "}
                </div>
                <div className="login_text_main_title_para_inner_information">
                  <Para16Tag
                    weight={500}
                    color={"#868A8B"}
                    center={"center"}>
                    {"No worries,we’ll send you reset instruction"}
                  </Para16Tag>
                </div>
              </div>

              <div className="auth_form_div">
                <Formik
                  initialValues={initialValues}
                  validationSchema={forgotPasswordSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched }) => (
                    <Form className="auth_page_form_main">
                      <div className="auth_page_from_inner_div">
                        <div className="form_input_list_email_pass_other_data">
                          <div className="form_input_flex_div_mx_bottom">
                            <div className="form_inner_input_div_main">
                              <FormInputLabel>Email Address</FormInputLabel>
                              <FormInputOuter>
                                <EmailIcon stroke={"#000000"} />
                                <Field
                                  type="text"
                                  placeholder="Enter your email address"
                                  name="email"
                                  className="from_input_tag"
                                />
                              </FormInputOuter>
                              {touched.email && errors.email && <FormErrorMessage errors={errors.email} />}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_sign_in_btn_main">
                        <ButtonWrapper
                          width="100%"
                          type="submit">
                          {isLoading ? "Loading..." : "Forgot Password"}
                        </ButtonWrapper>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Forgot;
