import { StepConnector, stepConnectorClasses, styled } from "@mui/material";

// Order status Stepper QontoConnector
export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#219EBC",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#219EBC",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    border_topWidth: 2,
    // borderRadius: 1,
  },
}));

// Order status Stepper QontoStepIconRoot
export const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#219EBC",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#219EBC",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

/* The `modelStyle` constant is an object that defines the CSS styles for a model. It includes
properties such as `position`, `top`, `left`, `transform`, `bg_color`, `background`, `border`, `p`,
and `borderRadius`. These styles are used to position and style the model element on the page. */
export const modelStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bg_color: "background.paper",
  background: "#FFF",
  border: "none",
  p: 5,
  borderRadius: 5,
  width: "fit-content",
  height: "fit-content",
  maxHight: '90vh',
  overflow: "scroll",
};
