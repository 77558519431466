import {
  ADD_FRAME_SHAPE_SUCCESS,
  DELETE_FRAME_SHAPE_SUCCESS,
  GET_ALL_FRAME_SHAPES_FAILURE,
  GET_ALL_FRAME_SHAPES_REQUEST,
  GET_ALL_FRAME_SHAPES_SUCCESS,
  UPDATE_FRAME_SHAPE_SUCCESS,
} from "../constants/frameShapes";

const initialState = {
  frameShapesList: [],
  loading: false,
  error: null,
};

export const frameShapesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FRAME_SHAPES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_FRAME_SHAPES_SUCCESS:
      return {
        ...state,
        frameShapesList: action.payload,
        loading: false,
      };

    case GET_ALL_FRAME_SHAPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case ADD_FRAME_SHAPE_SUCCESS:
      const {
        frameShape: { is_active, frame_shape, frame_image, _id: id },
      } = action.payload;
      const newFrameShape = { is_active, frame_shape, frame_image, id };
      return {
        ...state,
        frameShapesList: [...state.frameShapesList, newFrameShape],
      };

    case DELETE_FRAME_SHAPE_SUCCESS:
      return {
        ...state,
        frameShapesList: state.frameShapesList.filter((x) => x.id !== action.payload),
      };

    case UPDATE_FRAME_SHAPE_SUCCESS:
      return {
        ...state,
        frameShapesList: state.frameShapesList.map((x) => {
          if (x.id === action.payload.id) {
            return action.payload;
          }
          return x;
        }),
      };
    default:
      return state;
      break;
  }
};

// export const addFrameShapeReducer = (state = { addFrameShape: {} }, action) => {
//   switch (action.type) {
//     case ADD_FRAME_SHAPE_REQUEST:
//       return { ...state, loading: true };
//     case ADD_FRAME_SHAPE_SUCCESS:
//       return {
//         loading: false,
//         addFrameShape: action?.payload,
//       };
//     case ADD_FRAME_SHAPE_FAILURE:
//       return {
//         loading: false,
//         error: action?.payload,
//       };
//     default:
//       return state;
//   }
// };

// export const getAllFrameShapeReducer = (
//   state = { getAllFrameShape: [] },
//   action
// ) => {
//   switch (action.type) {
//     case GET_ALL_FRAME_SHAPE_REQUEST:
//       return { getAllFrameShape: [], loading: true };
//     case GET_ALL_FRAME_SHAPE_SUCCESS:
//       return {
//         loading: false,
//         getAllFrameShape: action?.payload,
//       };
//     case GET_ALL_FRAME_SHAPE_FAILURE:
//       return {
//         loading: false,
//         error: action?.payload,
//       };
//     default:
//       return state;
//   }
// };
