/**
 * This is a React component that renders a checkbox with a custom style.
 * @returns The CheckBox component is being returned.
 */
import React from "react";
import {
  CheckboxContainer,
  CheckboxIcon,
  HiddenCheckbox,
  StyledCheckbox,
} from "../assets/css/styled";

const CheckBox = ({ id, name, className, checked, onChange }) => {
  return (
    <>
      <CheckboxContainer className={className}>
        <HiddenCheckbox
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <StyledCheckbox checked={checked}>
          <CheckboxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </CheckboxIcon>
        </StyledCheckbox>
      </CheckboxContainer>
    </>
  );
};

export default CheckBox;

export const CheckBoxCustom = ({ id, name, className, checked, onChange, ...props }) => {
  return (
    <>
      <CheckboxContainer className={className}>
        <HiddenCheckbox
          id={id}
          name={name}
          checked={checked}
          onChange={onChange} // Pass the onChange handler to update Formik state
          {...props}
        />
        <StyledCheckbox checked={checked}>
          <CheckboxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </CheckboxIcon>
        </StyledCheckbox>
      </CheckboxContainer>
    </>
  );
};
