import { applyMiddleware, combineReducers, createStore } from "redux";
import { googleSignInReducer, loginAdminReducer, otpVerifyAdminReducer } from "./reducer/adminAuthReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { frameReducer } from "./reducer/frameReducer";
import { addColorReducer, getAllColorReducer } from "./reducer/colorReducer";
import { productReducer } from "./reducer/productReducer";
import categoryReducer from "./reducer/categoriesReducer";
import { brandReducer } from "./reducer/brandReducer";
import { frameShapesReducer } from "./reducer/frameShapeReducer";
import { frameTypesReducer } from "./reducer/frameTypeReducer";
import faceTypeReducer from "./reducer/faceTypeReducer";
import frameMaterialReducer from "./reducer/frameMaterialReducer";
import frameSizeReducer from "./reducer/frameSizeReducer";
import promoCodeReducer from "./reducer/promoCodeReducer";
import lenseReducer from "./reducer/lensesReducer";
import singleVisionTypesReducer from "./reducer/singleVisionTypesReducer";
import lenseTypeReducer from "./reducer/lensesTypeReducer";
import materialTypeReducer from "./reducer/materialTypesReducer";
import bannerReducer from "./reducer/bannerReducer";
import accessoryReducer from "./reducer/accessoryReducer";
import trendingSearchReducer from "./reducer/trendingSearchReducer";
import wearTheTrendReducer from "./reducer/wearTheTrendReducer";
import newsLetterReducer from "./reducer/newsLetterReducer";
import couponReducer from "./reducer/couponReducer";

/* The code `const reducer = combineReducers({ crateUser: createUserReducer });` is combining multiple
reducers into a single reducer function. In this case, it is combining the `createUserReducer` into
a single reducer called `crateUser`. This allows you to manage different parts of your application
state using separate reducers, and then combine them into a single reducer to be used in the Redux
store. */
const reducer = combineReducers({
  // Authentication
  loginAdmin: loginAdminReducer,
  googleSignIn: googleSignInReducer,
  otpVerifyAdmin: otpVerifyAdminReducer,

  // Face Type
  faceTypes: faceTypeReducer,
  // faceTypeGet: getFaceTypeReducer,

  // Color
  colorAdd: addColorReducer,
  colorAllGet: getAllColorReducer,

  // Frame
  frameAdd: frameReducer,

  // Frame Material
  frameMaterial: frameMaterialReducer,

  // Frame Material
  frameSize: frameSizeReducer,

  // Frame Shape
  // addFrameShape: addFrameShapeReducer,
  // getAllFrameShape: getAllFrameShapeReducer,

  // Product
  product: productReducer,

  // Brand
  brand: brandReducer,
  frameShapes: frameShapesReducer,
  frameTypes: frameTypesReducer,
  // addBrand: addProductBrandReducer,
  // getAllBrand: getAllProductBrandReducer,

  // Categories
  categories: categoryReducer,

  // Categories
  accessories: accessoryReducer,

  // promo codes
  promoCodes: promoCodeReducer,

  // Lenses
  lenses: lenseReducer,

  // Single Vision Types
  singleVisionTypes: singleVisionTypesReducer,

  // Lenses Types
  lensesTypes: lenseTypeReducer,

  // Material Types
  materialTypes: materialTypeReducer,

  // Banner
  banners: bannerReducer,

  // Coupon
  coupons: couponReducer,

  // Wear The Trend
  wearTheTrends: wearTheTrendReducer,

  // Trending Search
  trendingSearch: trendingSearchReducer,

  // News Letter
  newsLetter: newsLetterReducer,
});

/* The line `const middleware = [thunk];` is creating an array called `middleware` and assigning it the
value `[thunk]`. */
const middleware = [thunk];

/* The code is creating the main Redux store using the `createStore` function from the Redux library. */
const store = createStore(
  reducer,
  middleware,
  /* `composeWithDevTools(applyMiddleware(...middleware))` is a function that enhances the Redux store
  with additional debugging capabilities. */
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
