import React from "react";
import FormErrorMessage from "../../components/FormErrorMessage";
import { Field, Form, Formik } from "formik";
import { ButtonWrapper, FormInputLabel, Para16Tag } from "../../assets/css/styled";
import { productFrameSchema } from "../../helper/yup.schema";
import { CheckBoxCustom } from "../../components/typography/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { getAllFrameTypes } from "../../redux/actions/frameTypeAction";
import { getAllFrameShapes } from "../../redux/actions/frameShapeAction";
import { listAllFrameMaterial } from "../../redux/actions/frameMaterialAction";
import { listAllCategories } from "../../redux/actions/categoriesAction";
import { listAllFaceTypes } from "../../redux/actions/faceAction";
import { addProductFrameAction, updateProductFrameAction } from "../../redux/actions/frameAction";
import { useParams } from "react-router-dom";
import { ApiGetSingleProductFrame } from "../../helper/API/APICall";
import Loader from "../../components/Loader";

const FrameInformation = ({ handleComplete }) => {
  const [initFormikValues, setInitFormikValues] = React.useState({
    product_name: "",
    // description: "",
    model_no: "",
    price: "",
    category_id: "",
    face_id: [],
    frame_type_id: "",
    frame_shape_id: "",
    frame_material_id: "",
    condition: "null",
    collection_name: "",
    age_range: "",
    material: "null",
    temple_material: "null",
    is_latest: false,
    is_featured: false,
    is_trending: false,
    is_popular: false,
    is_warranty: false,
    product_warranty: "",
    gender: [],
    is_active: true,
    // is_deleted: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const { categoryList } = useSelector((state) => state?.categories);
  const { frameTypesList } = useSelector((state) => state?.frameTypes);
  const { frameShapesList } = useSelector((state) => state?.frameShapes);
  const { frameMaterialList } = useSelector((state) => state?.frameMaterial);
  const { faceTypesList } = useSelector((state) => state?.faceTypes);

  const handleSubmit = async (values, { resetForm }) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (id) {
          await dispatch(updateProductFrameAction(id, values, handleComplete, resetForm));
       
          resolve();
          return;
        }
      
        await dispatch(addProductFrameAction(values, handleComplete, resetForm));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const getSingleProductFrame = async (id) => {
    setIsLoading(true);
    try {
      const response = await ApiGetSingleProductFrame(id);
      const data = response?.data;
      if (data?._id) {
        setInitFormikValues({
          product_name: data?.product_name,
          // description: data?.description,
          model_no: data?.model_no,
          price: data?.price,
          category_id: data?.category_id,
          face_id: data?.face_id,
          frame_type_id: data?.frame_type_id,
          frame_shape_id: data?.frame_shape_id,
          frame_material_id: data?.frame_material_id,
          condition: "null",
          collection_name: data?.collection_name,
          age_range: data?.age_range,
          material: "null",
          temple_material: "null",
          is_latest: data?.is_latest,
          is_featured: data?.is_featured,
          is_trending: data?.is_trending,
          is_popular: data?.is_popular,
          is_warranty: data?.is_warranty,
          product_warranty: data?.product_warranty,
          gender: data?.gender,
          is_active: data?.is_active,
          // is_deleted: false,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (id) {
      getSingleProductFrame(id);
    }
  }, []);

  React?.useEffect(() => {
    dispatch(listAllCategories());
    dispatch(getAllFrameTypes());
    dispatch(getAllFrameShapes());
    dispatch(listAllFrameMaterial());
    dispatch(listAllFaceTypes());
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initFormikValues}
          validationSchema={productFrameSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {({ errors, touched, values, handleChange }) => (
            <Form>
              <div className="grid grid-cols-3 gap-5">
                <div>
                  <FormInputLabel>Product name</FormInputLabel>
                  <div className="form_input_main">
                    <Field
                      type="text"
                      placeholder="Enter product name"
                      name="product_name"
                      className="from_input_tag"
                    />
                  </div>
                  {touched.product_name && errors.product_name && <FormErrorMessage errors={errors.product_name} />}
                </div>
                {/* <div>
                  <FormInputLabel>Description</FormInputLabel>
                  <div className="face_select_add_inner_face_select_input_data">
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      placeholder="Enter your product description"
                      rows={1} // Specify the number of rows for the textarea
                    />
                    {touched.description && errors.description && <FormErrorMessage errors={errors.description} />}
                  </div>
                </div> */}
                <div>
                  <FormInputLabel>Model no.</FormInputLabel>
                  <div className="face_select_add_inner_face_select_input_data">
                    <div className="form_input_main">
                      <Field
                        type="text"
                        placeholder="Enter Model Number"
                        name="model_no"
                        className="from_input_tag"
                      />
                    </div>
                    {touched.model_no && errors.model_no && <FormErrorMessage errors={errors.model_no} />}
                  </div>
                </div>
                <div>
                  <FormInputLabel>Price</FormInputLabel>
                  <div className="form_input_main">
                    <Field
                      type="number"
                      placeholder="Enter price"
                      name="price"
                      className="from_input_tag"
                    />
                  </div>
                  {touched.price && errors.price && <FormErrorMessage errors={errors.price} />}
                </div>
                <div>
                  <FormInputLabel>Category</FormInputLabel>
                  <Field
                    as="select"
                    name="category_id">
                    <option
                      value=""
                      disabled>
                      Select a Category
                    </option>
                    {!categoryList.length
                      ? "Loading..."
                      : categoryList?.map((value, index) => {
                          return (
                            <option
                              key={index}
                              value={value?.id}>
                              {value?.type}
                            </option>
                          );
                        })}
                  </Field>
                  {touched.category_id && errors.category_id && <FormErrorMessage errors={errors.category_id} />}
                </div>
                <div>
                  <FormInputLabel>Frame type</FormInputLabel>
                  <Field
                    as="select"
                    name="frame_type_id">
                    <option
                      value=""
                      disabled>
                      Select a Frame type
                    </option>
                    {!frameTypesList.length
                      ? "Loading..."
                      : frameTypesList?.map((value, index) => {
                          return (
                            <option
                              key={index}
                              value={value?.id}>
                              {value?.frame_type}
                            </option>
                          );
                        })}
                  </Field>
                  {touched.frame_type_id && errors.frame_type_id && <FormErrorMessage errors={errors.frame_type_id} />}
                </div>
                <div>
                  <FormInputLabel>Frame Shape</FormInputLabel>
                  <Field
                    as="select"
                    name="frame_shape_id">
                    <option
                      value=""
                      disabled>
                      Select a Frame type
                    </option>
                    {!frameShapesList.length
                      ? "Loading..."
                      : frameShapesList?.map((value, index) => {
                          return (
                            <option
                              key={index}
                              value={value?.id}>
                              {value?.frame_shape}
                            </option>
                          );
                        })}
                  </Field>
                  {touched.frame_shape_id && errors.frame_shape_id && <FormErrorMessage errors={errors.frame_shape_id} />}
                </div>
                <div>
                  <FormInputLabel>Frame Material</FormInputLabel>
                  <Field
                    as="select"
                    name="frame_material_id">
                    <option
                      value=""
                      disabled>
                      Select a Frame type
                    </option>
                    {!frameMaterialList.length
                      ? "Loading..."
                      : frameMaterialList?.map((value, index) => {
                          return (
                            <option
                              key={index}
                              value={value?.id}>
                              {value?.frame_material}
                            </option>
                          );
                        })}
                  </Field>
                  {touched.frame_material_id && errors.frame_material_id && <FormErrorMessage errors={errors.frame_material_id} />}
                </div>
                {/* <div>
                <FormInputLabel>Condition</FormInputLabel>
                <div className="form_input_main">
                  <Field
                    type="text"
                    placeholder="Enter Condition"
                    name="condition"
                    className="from_input_tag"
                  />
                </div>
                {touched.condition && errors.condition && <FormErrorMessage errors={errors.condition} />}
              </div> */}
                <div>
                  <FormInputLabel>Collection name</FormInputLabel>
                  <div className="form_input_main">
                    <Field
                      type="text"
                      placeholder="Enter Collation name"
                      name="collection_name"
                      className="from_input_tag"
                    />
                  </div>
                  {touched.collection_name && errors.collection_name && <FormErrorMessage errors={errors.collection_name} />}
                </div>
                <div>
                  <FormInputLabel>Age Group</FormInputLabel>
                  <div className="form_input_main">
                    <Field
                      type="text"
                      placeholder="Enter age group"
                      name="age_range"
                      className="from_input_tag"
                    />
                  </div>
                  {touched.age_range && errors.age_range && <FormErrorMessage errors={errors.age_range} />}
                </div>
                {/* <div>
                <FormInputLabel>Material</FormInputLabel>
                <div className="form_input_main">
                  <Field
                    type="text"
                    placeholder="Enter Material"
                    name="material"
                    className="from_input_tag"
                  />
                </div>
                {touched.material && errors.material && (
                  <FormErrorMessage errors={errors.material} />
                )}
              </div>
              <div>
                <FormInputLabel>Temple Material</FormInputLabel>
                <div className="form_input_main">
                  <Field
                    type="text"
                    placeholder="Enter Temple Material"
                    name="temple_material"
                    className="from_input_tag"
                  />
                </div>
                {touched.temple_material && errors.temple_material && <FormErrorMessage errors={errors.temple_material} />}
              </div> */}
                <div>
                  <FormInputLabel>Product Warranty</FormInputLabel>
                  <div className="form_input_main">
                    <Field
                      type="text"
                      placeholder="Enter Product Warranty"
                      name="product_warranty"
                      className="from_input_tag"
                    />
                  </div>
                  {touched.product_warranty && errors.product_warranty && <FormErrorMessage errors={errors.product_warranty} />}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-5 mt-5">
                <div>
                  <FormInputLabel>Gender</FormInputLabel>
                  <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                    <div className="flex cursor-pointer">
                      <div
                        className="flex cursor-pointer"
                        style={{
                          marginRight: "10px",
                        }}>
                        <CheckBoxCustom
                          id="gender_male"
                          name="gender"
                          value="male"
                          checked={values.gender.includes("male")}
                          onChange={handleChange}
                        />
                      </div>
                      <Para16Tag color={"#A7ABAC"}>Male</Para16Tag>
                    </div>
                  </label>
                  <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                    <div className="flex cursor-pointer">
                      <div
                        className="flex cursor-pointer"
                        style={{
                          marginRight: "10px",
                        }}>
                        <CheckBoxCustom
                          id="gender_female"
                          name="gender"
                          value="female"
                          checked={values.gender.includes("female")}
                          onChange={handleChange}
                        />
                      </div>
                      <Para16Tag color={"#A7ABAC"}>Female</Para16Tag>
                    </div>
                  </label>
                  <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                    <div className="flex cursor-pointer">
                      <div
                        className="flex cursor-pointer"
                        style={{
                          marginRight: "10px",
                        }}>
                        <CheckBoxCustom
                          id="gender_boys"
                          name="gender"
                          value="boys"
                          checked={values.gender.includes("boys")}
                          onChange={handleChange}
                        />
                      </div>

                      <Para16Tag color={"#A7ABAC"}>Boy's</Para16Tag>
                    </div>
                  </label>
                  <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                    <div className="flex cursor-pointer">
                      <div
                        className="flex cursor-pointer"
                        style={{
                          marginRight: "10px",
                        }}>
                        <CheckBoxCustom
                          id="gender_girls"
                          name="gender"
                          value="girls"
                          checked={values.gender.includes("girls")}
                          onChange={handleChange}
                        />
                      </div>

                      <Para16Tag color={"#A7ABAC"}>Girl's</Para16Tag>
                    </div>
                  </label>
                  <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                    <div className="flex cursor-pointer">
                      <div
                        className="flex cursor-pointer"
                        style={{
                          marginRight: "10px",
                        }}>
                        <CheckBoxCustom
                          id="gender_other"
                          name="gender"
                          value="other"
                          checked={values.gender.includes("other")}
                          onChange={handleChange}
                        />
                      </div>

                      <Para16Tag color={"#A7ABAC"}>Other</Para16Tag>
                    </div>
                  </label>
                  {touched.gender && errors.gender && <FormErrorMessage errors={errors.gender} />}
                </div>
                <div>
                  {/* Face Type GET API */}
                  <FormInputLabel>Face Type</FormInputLabel>
                  {faceTypesList?.map((face_types, index) => {
                    return (
                      <label
                        className="add_new_add_default_address flex flex-col cursor-pointer items-start"
                        key={index}>
                        <div className="flex cursor-pointer">
                          <div
                            className="flex cursor-pointer"
                            style={{
                              marginRight: "10px",
                            }}>
                            <CheckBoxCustom
                              id={`face_id_${face_types?.id}`}
                              name="face_id"
                              value={face_types?.id}
                              checked={values.face_id.includes(face_types?.id)}
                              onChange={handleChange}
                            />
                          </div>
                          <Para16Tag color={"#A7ABAC"}>{face_types?.face_type}</Para16Tag>
                        </div>
                      </label>
                    );
                  })}
                  {touched.face_id && errors.face_id && <FormErrorMessage errors={errors.face_id} />}
                </div>
              </div>

              <div className="grid grid-cols-5 gap-5 mt-5">
                <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                  <div className="flex cursor-pointer">
                    <div
                      className="flex cursor-pointer"
                      style={{
                        marginRight: "10px",
                      }}>
                      <CheckBoxCustom
                        id="is_latest"
                        name="is_latest"
                        checked={values.is_latest}
                        onChange={handleChange}
                      />
                    </div>

                    <Para16Tag color={"#A7ABAC"}>Latest Product</Para16Tag>
                  </div>
                  {touched.is_latest && errors.is_latest && <FormErrorMessage errors={errors.is_latest} />}
                </label>
                <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                  <div className="flex cursor-pointer">
                    <div
                      className="flex cursor-pointer"
                      style={{
                        marginRight: "10px",
                      }}>
                      <CheckBoxCustom
                        id="is_featured"
                        name="is_featured"
                        checked={values.is_featured}
                        onChange={handleChange}
                      />
                    </div>

                    <Para16Tag color={"#A7ABAC"}>Featured Product</Para16Tag>
                  </div>
                  {touched.is_featured && errors.is_featured && <FormErrorMessage errors={errors.is_featured} />}
                </label>
                <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                  <div className="flex cursor-pointer">
                    <div
                      className="flex cursor-pointer"
                      style={{
                        marginRight: "10px",
                      }}>
                      <CheckBoxCustom
                        id="is_trending"
                        name="is_trending"
                        checked={values.is_trending}
                        onChange={handleChange}
                      />
                    </div>

                    <Para16Tag color={"#A7ABAC"}>Trending Product</Para16Tag>
                  </div>
                  {touched.is_trending && errors.is_trending && <FormErrorMessage errors={errors.is_trending} />}
                </label>
                <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                  <div className="flex cursor-pointer">
                    <div
                      className="flex cursor-pointer"
                      style={{
                        marginRight: "10px",
                      }}>
                      <CheckBoxCustom
                        id="is_popular"
                        name="is_popular"
                        checked={values.is_popular}
                        onChange={handleChange}
                      />
                    </div>

                    <Para16Tag color={"#A7ABAC"}>Popular Product</Para16Tag>
                  </div>
                  {touched.is_popular && errors.is_popular && <FormErrorMessage errors={errors.is_popular} />}
                </label>
                <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                  <div className="flex cursor-pointer">
                    <div
                      className="flex cursor-pointer"
                      style={{
                        marginRight: "10px",
                      }}>
                      <CheckBoxCustom
                        id="is_warranty"
                        name="is_warranty"
                        checked={values.is_warranty}
                        onChange={handleChange}
                      />
                    </div>

                    <Para16Tag color={"#A7ABAC"}>Warranty</Para16Tag>
                  </div>
                  {touched.is_warranty && errors.is_warranty && <FormErrorMessage errors={errors.is_warranty} />}
                </label>
                <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                  <div className="flex cursor-pointer">
                    <div
                      className="flex cursor-pointer"
                      style={{
                        marginRight: "10px",
                      }}>
                      <CheckBoxCustom
                        id="is_active"
                        name="is_active"
                        checked={values.is_active}
                        onChange={handleChange}
                      />
                    </div>

                    <Para16Tag color={"#A7ABAC"}>Active</Para16Tag>
                  </div>
                  {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                </label>
                {/* <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                <div className="flex cursor-pointer">
                  <div
                    className="flex cursor-pointer"
                    style={{
                      marginRight: "10px",
                    }}>
                    <CheckBoxCustom
                      id="is_deleted"
                      name="is_deleted"
                      checked={values.is_deleted}
                      onChange={handleChange}
                    />
                  </div>

                  <Para16Tag color={"#A7ABAC"}>Delete Product</Para16Tag>
                </div>
                {touched.is_deleted && errors.is_deleted && <FormErrorMessage errors={errors.is_deleted} />}
              </label> */}
              </div>

              <div className="form_sign_in_btn_main_inner mt-5">
                <ButtonWrapper
                  type="submit"
                  // disabled={isSubmitting}
                  // onClick={handleComplete}
                >
                  {id ? "Update" : "Submit"}
                </ButtonWrapper>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FrameInformation;
