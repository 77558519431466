import { AdminAPI } from "../../helper/API/API.base";
import { getFormDataHeaders, getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  ADD_FRAME_SHAPE_SUCCESS,
  DELETE_FRAME_SHAPE_SUCCESS,
  GET_ALL_FRAME_SHAPES_FAILURE,
  GET_ALL_FRAME_SHAPES_REQUEST,
  GET_ALL_FRAME_SHAPES_SUCCESS,
  UPDATE_FRAME_SHAPE_SUCCESS,
} from "../constants/frameShapes";

export const addFrameShape = (frameShape) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = frameShape;

      // API
      const response = await AdminAPI.post("/frame-shapes", body, { headers });

      dispatch({
        type: ADD_FRAME_SHAPE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame Shape added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const getAllFrameShapes = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: GET_ALL_FRAME_SHAPES_REQUEST });

      // API
      const response = await AdminAPI.get("/frame-shapes", { headers });

      const frameShapeList = response?.data.map(({ _id: id, frame_shape, frame_image, is_active }) => {
        return { id, frame_shape, frame_image, is_active };
      });

      dispatch({
        type: GET_ALL_FRAME_SHAPES_SUCCESS,
        payload: frameShapeList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_FRAME_SHAPES_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteFrameShape = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const frameShapeId = values.id;

      // API
      const response = await AdminAPI.delete(`/frame-shapes/${frameShapeId}`, {
        headers,
      });

      dispatch({
        type: DELETE_FRAME_SHAPE_SUCCESS,
        payload: frameShapeId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame Shape deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const editFrameShape = (values, frameShapeId) => async (dispatch) => {
  try {
    const headers = await getFormDataHeaders();

    // AXIOS BODY
    // const frameShapeId = id;
    const body = values;

    // API
    const response = await AdminAPI.put(`/frame-shapes/${frameShapeId}`, body, {
      headers,
    });

    let {
      frameShape: { _id: id, frame_image, frame_shape, is_active },
    } = response.data;
    dispatch({
      type: UPDATE_FRAME_SHAPE_SUCCESS,
      payload: { id, frame_image, frame_shape, is_active },
    });

    if (response?.data?.message?.status === 200) {
      SuccessToast("Frame Shape updated successfully!");
    } else if (response?.data?.res?.status === 500) {
      ErrorToast(response?.data?.res?.message);
    }
  } catch (error) {

    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return;
  }
};
