import {
  ADD_SINGLE_VISION_TYPES_SUCCESS,
  LIST_ALL_SINGLE_VISION_TYPE_REQUEST,
  LIST_ALL_SINGLE_VISION_TYPE_SUCCESS,
  LIST_ALL_SINGLE_VISION_TYPE_FAILURE,
  UPDATE_SINGLE_VISION_TYPES_SUCCESS,
  CREATE_SINGLE_VISION_TYPES_SUCCESS,
  DELETE_SINGLE_VISION_TYPE_SUCCESS,
} from "../constants/singleVisionTypes";

const initialState = {
  singleVisionTypesList: [],
  loading: false,
  error: null,
};

let singleVisionTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SINGLE_VISION_TYPES_SUCCESS:
      return {
        ...state,
        singleVisionTypesList: [...state.singleVisionTypesList, action.payload],
      };

    case LIST_ALL_SINGLE_VISION_TYPE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_SINGLE_VISION_TYPE_SUCCESS:
      return {
        ...state,
        singleVisionTypesList: action?.payload,
        loading: false,
      };

    case LIST_ALL_SINGLE_VISION_TYPE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_SINGLE_VISION_TYPES_SUCCESS: {
      const updatedSingleVisionType = action.payload.singleVisionType;
      return {
        ...state,
        singleVisionTypesList: state.singleVisionTypesList.map((singleVisionType) => {
          if (singleVisionType.id === updatedSingleVisionType._id) {
            return {
              ...singleVisionType,
              single_vision_type: updatedSingleVisionType.single_vision_type,
              description: updatedSingleVisionType.description,
              price: updatedSingleVisionType.price,
              is_active: updatedSingleVisionType.is_active,
            };
          }
          return singleVisionType;
        }),
      };
    }

    case CREATE_SINGLE_VISION_TYPES_SUCCESS: {
      const { singleVisionType } = action.payload;
      const newSingleVisionType = {
        id: singleVisionType._id,
        single_vision_type: singleVisionType.single_vision_type,
        description: singleVisionType.description,
        price: singleVisionType.price,
        is_active: singleVisionType.is_active,
      };
      return {
        ...state,
        singleVisionTypesList: [...state.singleVisionTypesList, newSingleVisionType],
      };
    }

    case DELETE_SINGLE_VISION_TYPE_SUCCESS: {
      const singleVisionTypeId = action.payload;
      return {
        ...state,
        singleVisionTypesList: state.singleVisionTypesList.filter((singleVisionType) => singleVisionType.id !== singleVisionTypeId),
      };
    }

    default:
      return state;
  }
};

export default singleVisionTypesReducer;
