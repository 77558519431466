import {
  ADD_WEAR_THE_TREND_SUCCESS,
  LIST_ALL_WEAR_THE_TREND_REQUEST,
  LIST_ALL_WEAR_THE_TREND_SUCCESS,
  LIST_ALL_WEAR_THE_TREND_FAILURE,
  UPDATE_WEAR_THE_TREND_SUCCESS,
  CREATE_WEAR_THE_TREND_SUCCESS,
  DELETE_WEAR_THE_TREND_SUCCESS,
  LOADING_TRUE,
  LOADING_FALSE,
} from "../constants/wearByTrend";

const initialState = {
  wearTheTrendList: [],
  loading: false,
  error: null,
};

let wearTheTrendReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    case LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };

    case ADD_WEAR_THE_TREND_SUCCESS:
      return {
        ...state,
        wearTheTrendList: [...state.wearTheTrendList, action.payload],
      };

    case LIST_ALL_WEAR_THE_TREND_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_WEAR_THE_TREND_SUCCESS:
      return {
        ...state,
        wearTheTrendList: action?.payload,
        loading: false,
      };

    case LIST_ALL_WEAR_THE_TREND_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_WEAR_THE_TREND_SUCCESS: {
      const updatedWearTheTrend = action.payload.wearTheTrend;
      return {
        ...state,
        wearTheTrendList: state.wearTheTrendList.map((wearTheTrend) => {
          if (wearTheTrend.id === updatedWearTheTrend?._id) {
            return {
              ...wearTheTrend,
              id: updatedWearTheTrend?._id,
              image: updatedWearTheTrend?.image,
              title: updatedWearTheTrend?.title,
              gender: updatedWearTheTrend?.gender,
              is_active: updatedWearTheTrend?.is_active,
              createdAt: updatedWearTheTrend?.createdAt,
              updateAt: updatedWearTheTrend?.updateAt,
            };
          }
          return wearTheTrend;
        }),
        loading: false,
      };
    }

    case CREATE_WEAR_THE_TREND_SUCCESS: {
      const { wearTheTrend } = action.payload;
      const newWearTheTrend = {
        id: wearTheTrend?._id,
        image: wearTheTrend?.image,
        title: wearTheTrend?.title,
        gender: wearTheTrend?.gender,
        is_active: wearTheTrend?.is_active,
        createdAt: wearTheTrend?.createdAt,
        updateAt: wearTheTrend?.updateAt,
      };
      return {
        ...state,
        wearTheTrendList: [...state.wearTheTrendList, newWearTheTrend],
        loading: false,
      };
    }

    case DELETE_WEAR_THE_TREND_SUCCESS: {
      const wearTheTrendId = action.payload;
      return {
        ...state,
        wearTheTrendList: state.wearTheTrendList.filter((wearTheTrend) => wearTheTrend.id !== wearTheTrendId),
      };
    }

    default:
      return state;
  }
};

export default wearTheTrendReducer;
