import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { modelStyle } from "../../assets/css/muiStyled";
import Table from "../../components/Table";
// import Loader from "../../components/Loader";
import { addFrameShape, deleteFrameShape, editFrameShape, getAllFrameShapes } from "../../redux/actions/frameShapeAction";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { frameShapeSchema } from "../../helper/yup.schema";
import {
  ButtonWrapper,
  FormInputLabel,
  Head24Tag,
  Para16Tag,
  Para20Tag,
  DashboardDetailsBorder,
  MainPadding,
  Para14Tag,
} from "../../assets/css/styled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { ErrorToast } from "../../helper/Toast";
import { UploadImagesIcon } from "../../components/typography/Icons";
import { CheckBoxCustom } from "../../components/typography/CheckBox";

const FrameShapes = () => {
  const [formInitialValues, setFormInitialValues] = useState({
    frame_shape: "",
  });
  const [selectedFrameShapeId, setSelectedFrameShapeId] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);

  const dispatch = useDispatch();

  const { frameShapesList } = useSelector((state) => state?.frameShapes);

  useEffect(() => {
    dispatch(getAllFrameShapes());
  }, [dispatch]);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteFrameShape({ id }));
  };

  const handleEditClick = (id) => () => {
    setSelectedFrameShapeId(id);
    frameShapesList.forEach((x) => {
      if (x.id === id) {
        setFormInitialValues({ frame_shape: x.frame_shape });
        setImageURL(x.frame_image);
        setActive(x?.is_active);
      }
    });
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "frame_image",
      headerName: "Frame Image",
      width: 120,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`Frame Shape ${params.row.id}`}
        />
      ),
    },
    {
      field: "frame_shape",
      headerName: "Frame Shape",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "is_active",
      headerName: "Active",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddFrameshape = () => {
    setOpen(true);
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setActive(false);
    setImageFile(null);
    setImageURL(null);
    setFormInitialValues({ frame_shape: "" });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!imageURL) {
      ErrorToast("Please select an image!");
      return;
    }
    try {
      if (edit) {
        const updateFormData = new FormData();
        updateFormData.append("frame_shape", values?.frame_shape);
        updateFormData.append("is_active", active);
        imageFile && updateFormData.append("frame_image", imageFile);
        // Append each field to the formData object
        dispatch(editFrameShape(updateFormData, selectedFrameShapeId));
        setOpen(false);
        setEdit(false);
        setActive(false);
        setFormInitialValues({ frame_shape: "" });
        setImageFile(null);
        setImageURL(null);
        return;
      }

      const newFormData = new FormData();
      newFormData.append("id", selectedFrameShapeId);
      newFormData.append("frame_image", imageFile);
      newFormData.append("frame_shape", values?.frame_shape);
      newFormData.append("is_active", active);

      // Create API
      dispatch(addFrameShape(newFormData));
      resetForm();
      setOpen(false);
      setActive(false);
      setImageFile(null);
      setImageURL(null);
    } catch (error) {
      ErrorToast(error);
      throw new Error(error)
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageURL(url);
    setImageFile(file);

    // Reset the input value to allow re-selecting the same file
    e.target.value = null;
  };

  return (
    <>
      <MainPadding
        p_bottom={20}
        p_top={20}>
        <Box
          m="20px"
          mb={0}
          mt={0}>
          <div className="flex w-full justify-between items-center">
            <Header
              title="Frame Shapes"
              subtitle="View All The Frame Shapes"
            />
            <div className="flex justify-end">
              <ButtonWrapper
                variant="outlined"
                onClick={handleAddFrameshape}>
                Add Frame Shape
              </ButtonWrapper>
            </div>
          </div>
          <Table
            rows={frameShapesList}
            columns={columns}
          />
        </Box>
      </MainPadding>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={modelStyle}>
          <div className="category_modal_add_form_main_div">
            <div className="payment_model_inner_head_main">
              <Head24Tag
                color="#0E1213"
                weight="600">
                {`${edit ? "Update" : "Add"} Frame Shape`}
              </Head24Tag>
            </div>
            <div
              className="network_insurance_modal_close"
              onClick={handleClose}>
              <ModalCloseButton />
            </div>
          </div>

          <div className="form_inner_input_div_main">
            <FormInputLabel>Select Frame Shape</FormInputLabel>
          </div>

          <div>
            <div>
              <div>
                <div className="border border-blue-300 rounded-md p-5 my-2">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="thumbnail_upload_image"
                    id="thumbnail_upload_image"
                    accept="image/png, image/jpeg"
                    onChange={handleFile}
                  />
                  <div className="frame_info_images_outer_inner_three_images_show">
                    <label htmlFor="thumbnail_upload_image">
                      {imageURL && (
                        <img
                          style={{ cursor: "pointer" }}
                          src={imageURL}
                          alt="img"
                          className="frame_info_image_inner_open_inner"
                        />
                      )}
                      <div className="frame_info_text_files_name_browse">
                        <UploadImagesIcon />
                        <Para20Tag color={"#000"}>
                          Drop your Files here Or.
                          <span style={{ color: "#219EBC" }}> Browse</span>
                        </Para20Tag>
                      </div>
                      <Para16Tag>You can only select up to one images.</Para16Tag>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame_info_image_inner_open_bottom_div_inner">
              {imageURL && (
                <DashboardDetailsBorder padding={"10px"}>
                  <div
                    style={{ gap: "40px" }}
                    className="frame_show_images_selected_file_inner">
                    <div className="frame_show_images_name_complete_done_div">
                      <img
                        src={imageURL}
                        alt="img"
                        className="frame_show_images_selected_file_inner_outer"
                      />
                    </div>
                    <div className="frame_info_images_inner_open_right_delete_icon_remove">
                      <button
                        className="frame_info_images_remove_icon_inner_delete"
                        onClick={() => {
                          setImageURL(null);
                          setImageFile(null);
                        }}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                </DashboardDetailsBorder>
              )}
            </div>
          </div>

          <Formik
            initialValues={formInitialValues}
            validationSchema={frameShapeSchema}
            onSubmit={handleSubmit}>
            {({ touched, errors }) => (
              <Form>
                <div className="technical_information_text_filed">
                  <div className="form_inner_input_div_main mt-4">
                    <FormInputLabel>Frame Shape</FormInputLabel>
                    <div className="form_input_main">
                      <Field
                        type="text"
                        placeholder="Enter Frame Shape"
                        name="frame_shape"
                        className="from_input_tag"
                      />
                    </div>
                    {touched.frame_shape && errors.frame_shape && <FormErrorMessage errors={errors.frame_shape} />}
                  </div>
                </div>
                {/* Active checkbox */}
                <div className="technical_information_text_filed">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="form_input_forget_text_main_pass_inner mt-4">
                    <div className="form_get_update_text_data_title">
                      <div
                        style={{ cursor: "pointer" }}
                        className="add_new_add_default_address">
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}>
                          <div
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            className="main_check_box">
                            <CheckBoxCustom
                              id="is_active"
                              name="is_active"
                              checked={active}
                              onChange={() => setActive(!active)}
                            />
                          </div>
                          <div className="label_text">
                            <Para14Tag
                              weight={500}
                              color="#A7ABAC">
                              Active
                            </Para14Tag>
                          </div>
                          {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form_sign_in_btn_main_inner_update">
                  <ButtonWrapper auto={"auto"}>{`${edit ? "Update" : "Create"}`}</ButtonWrapper>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default FrameShapes;
