import React from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { MainPadding } from "../../assets/css/styled";
import {
  ApiCreateShipment,
  ApiGet,
  ApiGetAllOrders,
  ApiUpdateOrderStatus,
} from "../../helper/API/APICall";
import { orderSchema } from "../../helper/yup.schema";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import {
  ButtonWrapper,
  FormInputLabel,
  Head24Tag,
} from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { SuccessToast } from "../../helper/Toast";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import { GrView } from "react-icons/gr";
import { Link } from "react-router-dom";
import { routesConfig } from "../../helper/config/routes.config";
import Papa from "papaparse";

const Order = () => {
  const [formInitialValues, setFormInitialValues] = React.useState({
    orderId: "",
    userId: "",
    status: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [selectedOrderId, setSelectedOrderId] = React.useState(null);
  const [orderData, setOrderData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [statusList] = React.useState([
    "pending",
    "confirmed",
    "processing",
    "shipped",
    "delivered",
    "canceled",
    "return",
  ]);

  const getAllOrders = async () => {
    setIsLoading(true);
    try {
      const response = await ApiGetAllOrders();
      setOrderData(response?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const createData = (
    id,
    customer,
    totalAmount,
    status,
    location,
    zipCode,
    orderDate,
    useMyFrame,
    items
  ) => {
    return {
      id,
      customer,
      totalAmount,
      status,
      location,
      zipCode,
      orderDate,
      useMyFrame,
      products: items?.map((item) => {
        return {
          id: item?._id,
          image:
            item?.product_image ||
            item?.accessory_image ||
            item?.lenses?.use_my_frame_id?.frame_image?.[0],
          name: item?.product_name || item?.accessory_name || "-",
          color: item?.product_color || "-",
          quantity: item?.quantity,
          price: item?.product_price || item?.accessory_price || "-",
          size: item?.product_size_name || "-",
          skuNo: item?.product_sku_no || "-",
          modelNo: item?.product_model_no || "-",
        };
      }),
    };
  };

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left">{row?.id}</TableCell>
          <TableCell align="left">{row?.customer}</TableCell>
          <TableCell align="left">{row?.totalAmount}</TableCell>
          <TableCell align="left">{row?.status}</TableCell>
          <TableCell align="left">{row?.location}</TableCell>
          <TableCell align="left">{row?.zipCode}</TableCell>
          <TableCell align="center">
            {moment(row?.orderDate).format("MMM DD Y, hh:mm A")}
          </TableCell>
          <TableCell align="center">{row?.useMyFrame}</TableCell>
          <TableCell align="center">
            <Link to={routesConfig.orderDetails + "/" + row?.id}>
              <GridActionsCellItem
                icon={<GrView />}
                label="Edit"
                color="inherit"
              />
            </Link>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              color="inherit"
              onClick={handleEditClick(row?.id)}
            />
          </TableCell>
        </TableRow>
        {/* Sizes details */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography
                  className="!font-semibold"
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  Products
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className="!font-semibold">Image</TableCell>
                      <TableCell className="!font-semibold">Name</TableCell>
                      <TableCell className="!font-semibold" align="right">
                        Color
                      </TableCell>
                      <TableCell className="!font-semibold" align="right">
                        Quantity
                      </TableCell>
                      <TableCell className="!font-semibold" align="right">
                        Price
                      </TableCell>
                      <TableCell className="!font-semibold" align="right">
                        Size
                      </TableCell>
                      <TableCell className="!font-semibold" align="right">
                        Sku No.
                      </TableCell>
                      <TableCell className="!font-semibold" align="right">
                        Model No.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.products?.map((x) => (
                      <TableRow key={x?.id}>
                        <TableCell component="th" scope="row">
                          <img
                            className="w-28"
                            src={x?.image}
                            alt={`product`}
                          />
                        </TableCell>
                        <TableCell>{x?.name}</TableCell>
                        <TableCell align="right">{x?.color}</TableCell>
                        <TableCell align="right">{x?.quantity}</TableCell>
                        <TableCell align="right">{x?.price}</TableCell>
                        <TableCell align="right">{x?.size}</TableCell>
                        <TableCell align="right">{x?.skuNo}</TableCell>
                        <TableCell align="right">{x?.modelNo}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    if (!open) {
      getAllOrders();
    }
  }, [open]);

  const rows =
    orderData &&
    orderData?.map((x) =>
      createData(
        x?._id,
        `${x?.shipping_address_data?.firstname} ${x?.shipping_address_data?.lastname}`,
        x?.total_price,
        x?.order_status,
        x?.shipping_address_data?.city,
        x?.shipping_address_data?.zipcode,
        x?.created_at,
        x?.items?.[0]?.lenses?.is_use_my_frame.toString(),
        x?.items
      )
    );

  const handleSubmit = async (values) => {
    setIsLoadingStatus(true);
    try {
      let shippingLabel = null;
      let trackingNumber = null;
      if (edit) {
        if (values.status === "shipped") {
          const {
            data: [shipper],
          } = await ApiGet("/fedex-data");

          const currentDate = moment().format("YYYY-MM-DD");
          const body = {
            labelResponseOptions: "LABEL",
            dummy: "",
            requestedShipment: {
              shipper: {
                contact: {
                  personName: shipper?.person_name,
                  phoneNumber: shipper?.phone_number,
                  companyName: shipper?.company_name,
                },
                address: {
                  streetLines: [shipper?.address_line1],
                  city: shipper?.city,
                  stateOrProvinceCode: shipper?.state_code,
                  postalCode: shipper?.postal_code,
                  countryCode: shipper?.country_code,
                },
              },
              recipients: [
                {
                  contact: {
                    personName: `${selectedOrder?.shipping_address_data?.firstname} ${selectedOrder?.shipping_address_data?.lastname}`,
                    phoneNumber:
                      selectedOrder?.shipping_address_data?.mobile_no,
                    companyName: `${selectedOrder?.shipping_address_data?.firstname} ${selectedOrder?.shipping_address_data?.lastname}`,
                  },
                  address: {
                    streetLines: [
                      selectedOrder?.shipping_address_data?.address,
                      "",
                    ],
                    city: selectedOrder?.shipping_address_data?.city,
                    stateOrProvinceCode:
                      selectedOrder?.shipping_address_data?.state,
                    postalCode: selectedOrder?.shipping_address_data?.zipcode,
                    countryCode: "US",
                  },
                },
              ],
              shipDatestamp: currentDate,
              serviceType: "FEDEX_2_DAY", // SMART_POST | STANDARD_OVERNIGHT
              packagingType: "FEDEX_ENVELOPE", // FEDEX_ENVELOPE | FEDEX_SMALL_BOX
              pickupType: "USE_SCHEDULED_PICKUP",
              blockInsightVisibility: false,
              shippingChargesPayment: {
                paymentType: "SENDER",
              },
              shipmentSpecialServices: {
                specialServiceTypes: ["FEDEX_ONE_RATE"],
              },
              labelSpecification: {
                imageType: "PDF",
                labelStockType: "PAPER_85X11_TOP_HALF_LABEL",
              },
              requestedPackageLineItems: [{}],
            },
            accountNumber: {
              value: shipper?.account_number,
            },
          };

          const response = await ApiCreateShipment(body);
          // shippingLabel =
          //   response?.data?.data?.output?.transactionShipments?.[0]
          //     ?.pieceResponses?.[0]?.packageDocuments?.[0]?.encodedLabel;
          // trackingNumber =
          //   response?.data?.data?.output?.transactionShipments?.[0]
          //     ?.pieceResponses?.[0]?.trackingNumber;
          if (response && response?.data && response?.data?.data) {
            const { output } = response?.data?.data;
            if (
              output &&
              output?.transactionShipments &&
              output?.transactionShipments?.length > 0
            ) {
              const pieceResponses =
                output?.transactionShipments[0]?.pieceResponses;
              if (pieceResponses && pieceResponses?.length > 0) {
                shippingLabel =
                  pieceResponses[0]?.packageDocuments[0]?.encodedLabel;
                trackingNumber = pieceResponses[0]?.trackingNumber;
              }
            }
          }
        }
        const body = {
          order_id: values?.orderId,
          user_id: values?.userId,
          order_status: values?.status,
        };

        if (shippingLabel) {
          body.shipping_lable = shippingLabel;
          body.tracking_no = trackingNumber;
        }
        const response = await ApiUpdateOrderStatus(body);
        if (response?.data?.message?.status === 200) {
          SuccessToast("Order status updated successfully");
          setEdit(false);
          setFormInitialValues({
            orderId: "",
            userId: "",
            status: "",
          });
          setOpen(false);
        }
        setIsLoadingStatus(false);
        return;
      }
    } catch (error) {
      setIsLoadingStatus(false);
    }
  };

  const handleEditClick = (id) => () => {
    setSelectedOrder(orderData?.find((order) => order._id === id));
    setSelectedOrderId(id);
    orderData?.forEach((x) => {
      if (x._id === id) {
        setFormInitialValues({
          orderId: x?._id,
          userId: x?.user_id,
          status: x?.order_status,
        });
      }
    });
    setOpen(true);
    setEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormInitialValues({ orderId: "", userId: "", status: "" });
    setSelectedOrder(null);
    setSelectedOrderId(null);
    setEdit(false);
  };
  
  const handleExportCSV = () => {
    const flattenOrderData = orderData.map((row) => {
      const orderItems = row.items.map((x, index) => {
        let product = {
          [`Product${index + 1}_Name`]: "",
          [`Product${index + 1}_LenseName`]: "",
          [`Product${index + 1}_Image`]: "",
          [`Product${index + 1}_Color`]: "",
          [`Product${index + 1}_Size`]: "",
          [`Product${index + 1}_Model`]: "",
          [`Product${index + 1}_SKU`]: "",
          [`Product${index + 1}_Price`]:
            x?.product_price || x?.accessory_price || 0,
          // [`Product${index + 1}_Quantity`]: x?.quantity,
          [`Product${index + 1}_ProductStatus`]: x?.product_status,
          [`Product${index + 1}_PrescriptionData_Right_Eye`]: JSON.stringify(
            x?.prescription_data?.right_eye_attributes
          ),
          [`Product${index + 1}_PrescriptionData_Left_Eye`]: JSON.stringify(
            x?.prescription_data?.left_eye_attributes
          ),
        };

        if (Object.hasOwnProperty.call(x, "product_image")) {
          product[`Product${index + 1}_Image`] = x?.product_image;
          product[`Product${index + 1}_Name`] = x?.product_name;
          product[`Product${index + 1}_Size`] = x?.product_size_name;
          product[`Product${index + 1}_Color`] = x?.product_color;
          product[`Product${index + 1}_SKU`] = x?.product_sku_no;

          if (x?.lenses?.lense_id) {
            product[`Product${index + 1}_LenseName`] +=
              x?.lenses?.lense_id?.prescription_type;
            product[`Product${index + 1}_Price`] += x?.lenses?.lens_price;
          }
          if (x?.lenses?.singlevision_id) {
            product[
              `Product${index + 1}_LenseName`
            ] += ` + ${x?.lenses?.singlevision_id?.single_vision_type}`;
            product[`Product${index + 1}_Price`] +=
              x?.lenses?.singlevision_price;
          }
          if (x?.lenses?.lense_type_id) {
            product[
              `Product${index + 1}_LenseName`
            ] += ` + ${x?.lenses?.lense_type_id?.lenses_type}`;
            product[`Product${index + 1}_Price`] += x?.lenses?.lens_type_price;
          }
          if (x?.lenses?.material_type_id) {
            product[
              `Product${index + 1}_LenseName`
            ] += ` + ${x?.lenses?.material_type_id?.lens_material_type}`;
            product[`Product${index + 1}_Price`] +=
              x?.lenses?.material_type_price;
          }
        } else if (Object.hasOwnProperty.call(x, "accessory_image")) {
          product[`Product${index + 1}_Image`] = x.accessory_image;
          product[`Product${index + 1}_Name`] = x?.accessory_name;
        } else if (x?.lenses?.is_use_my_frame) {
          product[`Product${index + 1}_Name`] =
            x?.lenses?.use_my_frame_id?.frame_name;
          product[`Product${index + 1}_Image`] =
            x?.lenses?.use_my_frame_id?.frame_image?.[0];
          if (x?.lenses?.lense_id) {
            product[`Product${index + 1}_LenseName`] +=
              x?.lenses?.lense_id?.prescription_type;
            product[`Product${index + 1}_Price`] += x?.lenses?.lense_id?.price;
          }
          if (x?.lenses?.singlevision_id) {
            product[
              `Product${index + 1}_LenseName`
            ] += ` + ${x?.lenses?.singlevision_id?.single_vision_type}`;
            product[`Product${index + 1}_Price`] +=
              x?.lenses?.singlevision_id?.price;
          }
          if (x?.lenses?.lense_type_id) {
            product[
              `Product${index + 1}_LenseName`
            ] += ` + ${x?.lenses?.lense_type_id?.lenses_type}`;
            product[`Product${index + 1}_Price`] +=
              x?.lenses?.lense_type_id?.price;
          }
          if (x?.lenses?.material_type_id) {
            product[
              `Product${index + 1}_LenseName`
            ] += ` + ${x?.lenses?.material_type_id?.lens_material_type}`;
            product[`Product${index + 1}_Price`] +=
              x?.lenses?.material_type_id?.price;
          }
        }
        return product;
      });

      const flattenedOrderItems = Object.assign({}, ...orderItems);

      return {
        SequenceNo: `#${row?.sequence_no}`,
        OrderID: `#${row?._id}`,
        OrderStatus: row?.order_status,
        OrderDate: moment(row?.created_at).format("MMM DD Y, hh:mm A"),
        IsGuestOrder: row?.is_guest_order,
        CustomerName:
          row?.user_id?.username ||
          `${row?.user_id?.firstname} ${row?.user_id?.lastname}`,
        Email: row?.user_id?.email,
        CustomerMobile: row?.user_id?.mobile,
        CustomerGender: row?.user_id?.gender,
        CustomerWalletBalance: row?.user_id?.walletBalance,
        ShippingAddress: row?.shipping_address_data?.address,
        ShippingAddressType: row?.shipping_address_data?.address_type,
        ShippingAddressCity: row?.shipping_address_data?.city,
        ShippingAddressEmail: row?.shipping_address_data?.email,
        ShippingAddressName:
          row?.shipping_address_data?.username ||
          `${row?.shipping_address_data?.firstname} ${row?.shipping_address_data?.lastname}`,
        ShippingAddressMobileNo: row?.shipping_address_data?.mobile_no,
        ShippingAddressState: row?.shipping_address_data?.state,
        ShippingAddressZipCode: row?.shipping_address_data?.zipcode,
        ...flattenedOrderItems,
        IsPromoCodeApply: row?.is_promo_code_applied,
        IsReferralCodeApplied: row?.is_referral_code_applied,
        UseWalletBalance: row?.use_wallet_balance,
        WalletBalanceUsed: `$${row?.wallet_balance_used}`,
        TotalCount: row?.total_count,
        Price: `$${row?.price}`,
        TotalPrice: `$${row?.total_price}`,
      };
    });
    // };

    const csv = Papa.unparse(flattenOrderData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "orders.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <MainPadding p_bottom={20} p_top={20}>
      <Box m="20px" mb={0} mt={0}>
        <div className="flex items-center justify-between">
          <Header title="All Orders" subtitle="List of Orders" />
          <ButtonWrapper onClick={handleExportCSV}>Export as CSV</ButtonWrapper>
        </div>
        <div className="mt-5">
          {isLoading ? (
            <Loader />
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell className="!font-semibold" align="left">
                      Order Id
                    </TableCell>
                    <TableCell className="!font-semibold" align="left">
                      Customer Name
                    </TableCell>
                    <TableCell className="!font-semibold" align="left">
                      Total Amount
                    </TableCell>
                    <TableCell className="!font-semibold" align="left">
                      Status
                    </TableCell>
                    <TableCell className="!font-semibold" align="left">
                      Location
                    </TableCell>
                    <TableCell className="!font-semibold" align="left">
                      Zip Code
                    </TableCell>
                    <TableCell className="!font-semibold" align="center">
                      Order Date
                    </TableCell>
                    <TableCell className="!font-semibold" align="center">
                      Use my frame
                    </TableCell>
                    <TableCell className="!font-semibold" align="center">
                      Edit
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => (
                    <Row key={index} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag color="#0E1213" weight="600">
                  Update status
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}
              >
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <Formik
                initialValues={formInitialValues}
                validationSchema={orderSchema}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, setFieldValue }) => (
                  <Form>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Order Id</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder=""
                            name="orderId"
                            className="from_input_tag"
                            readOnly
                          />
                        </div>
                        {touched.orderId && errors.orderId && (
                          <FormErrorMessage errors={errors.orderId} />
                        )}
                      </div>
                    </div>
                    <div>
                      <FormInputLabel>Order Status</FormInputLabel>
                      <div className=" flex gap-4 items-center ">
                        <div className="flex w-[27.125rem] items-center ">
                          <Field
                            as="select"
                            name="status"
                            onChange={(e) => {
                              setFieldValue("status", e.target.value);
                            }}
                          >
                            <option value="">Select a status</option>
                            {!statusList?.length
                              ? "Loading..."
                              : statusList?.map((value, index) => {
                                  return (
                                    <option key={index} value={value}>
                                      {value}
                                    </option>
                                  );
                                })}
                          </Field>
                        </div>
                      </div>
                      {touched.status && errors.status && (
                        <FormErrorMessage errors={errors.status} />
                      )}
                    </div>
                    <div className="form_sign_in_btn_main_inner_update mt-4">
                      <ButtonWrapper type="submit" auto={"auto"}>
                        {isLoadingStatus ? "Loading..." : "Update"}
                      </ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default Order;
