import { AdminAuthAPI } from "../../helper/API/API.base";
import { APIHeaderCors } from "../../helper/API/API.header";
import { Auth } from "../../helper/Auth";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import {
  GOOGLE_SIGN_IN_FAILURE,
  GOOGLE_SIGN_IN_REQUEST,
  GOOGLE_SIGN_IN_SUCCESS,
  LOGIN_ADMIN_FAILURE,
  LOGIN_ADMIN_REQUEST,
  LOGIN_ADMIN_SUCCESS,
  OTP_VERIFY_ADMIN_FAILURE,
  OTP_VERIFY_ADMIN_REQUEST,
  OTP_VERIFY_ADMIN_SUCCESS,
} from "../../helper/config/constantsRedux.config";
import { routesConfig } from "../../helper/config/routes.config";
import { toastMessage } from "../../helper/config/toastMessage.config";

/**
 * This function is an action creator that handles the login process for a user in a JavaScript
 * application.
 * @param userData - The `userData` parameter is an object that contains the user's data, specifically
 * their email.
 * @returns The loginUserAction function returns a Promise.
 */
export const getLoginAction = (adminData, navigate) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LOGIN_ADMIN_REQUEST });

      // AXIOS BODY
      const body = {
        email: adminData?.email,
        password: adminData?.password,
      };

      // API
      const response = await AdminAuthAPI.post("/admin-signin", body, APIHeaderCors);

      dispatch({
        type: LOGIN_ADMIN_SUCCESS,
        payload: response?.data?.message,
      });

      if (response?.data?.token) {
        Auth?.setAdminEmail(adminData?.email);
        Auth?.setAdminToken(response?.data?.token);
        SuccessToast("Login successfully");
        navigate(routesConfig?.homePath);
      } else if (response?.data?.status === 401) ErrorToast(toastMessage?.LOGIN_USER_INVALID_EMAIL_OR_PASSWORD);
      else if (response?.data?.status === 403) ErrorToast(toastMessage?.LOGIN_USER_INVALID_EMAIL_OR_PASSWORD);
      else return;

      resolve(response);
    } catch (error) {

      dispatch({
        type: LOGIN_ADMIN_FAILURE,
        payload: error?.message,
      });

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const googleSignInAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: GOOGLE_SIGN_IN_REQUEST });

      // API
      const response = await AdminAuthAPI.get("/google", APIHeaderCors);

      dispatch({
        type: GOOGLE_SIGN_IN_SUCCESS,
        payload: response?.data?.message,
      });

      // if (response?.data?.status === 200) {
      //   SuccessToast(response?.data?.message);
      // } else if (response?.data?.status === 401)
      //   ErrorToast(toastMessage?.LOGIN_USER_INVALID_EMAIL);
      // else return;

      resolve(response);
    } catch (error) {

      dispatch({
        type: GOOGLE_SIGN_IN_FAILURE,
        payload: error?.message,
      });

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

/**
 * The `otpVerifyUserAction` function is a JavaScript action that verifies a user's OTP (One-Time
 * Password) and performs certain actions based on the response.
 * @param otp - The OTP (One-Time Password) entered by the user for verification.
 * @param navigate - The `navigate` parameter is a function that is used to navigate to a different
 * page or route in the application. It is typically provided by a navigation library such as React
 * Router or React Navigation.
 * @returns The function `otpVerifyUserAction` returns a Promise.
 */
export const otpVerifyAdminAction = (otp, navigate) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: OTP_VERIFY_ADMIN_REQUEST });

      let adminEmail = "";
      if (Auth?.getAdminEmail()) {
        adminEmail = Auth?.getAdminEmail();
      }

      // AXIOS BODY
      const body = {
        email: adminEmail,
        otp: otp,
      };

      // API
      const response = await AdminAuthAPI.post("/verify-signin-otp", body, APIHeaderCors);

      dispatch({
        type: OTP_VERIFY_ADMIN_SUCCESS,
        payload: response?.data?.message?.message,
      });

      if (response?.data?.message?.status === 200) {
        Auth?.setAdminToken(response?.data?.token);

        SuccessToast(response?.data?.message?.message);

        navigate("/");
      } else if (response?.data?.status === 400) ErrorToast(response?.data?.message);
      else return;

      resolve(response);
    } catch (error) {

      dispatch({
        type: OTP_VERIFY_ADMIN_FAILURE,
        payload: error?.message,
      });

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
