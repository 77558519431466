import { AdminAPI, AdminCommonAPI } from "../../helper/API/API.base";
import { APIHeaderCors, getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  ADD_PRODUCT_BRAND_SUCCESS,
  DELETE_PRODUCT_BRAND_SUCCESS,
  GET_ALL_PRODUCT_BRAND_FAILURE,
  GET_ALL_PRODUCT_BRAND_REQUEST,
  GET_ALL_PRODUCT_BRAND_SUCCESS,
  UPDATE_PRODUCT_BRAND_SUCCESS,
} from "../constants/brand";

export const addBrand = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = {
        brand_name: values,
      };

      // API
      const response = await AdminAPI.post("/brands", body?.brand_name, {
        headers,
      });

      const {
        brand: { brand_name, is_activated, _id: id },
      } = response?.data;
      dispatch({
        type: ADD_PRODUCT_BRAND_SUCCESS,
        payload: { brand_name, is_activated, id },
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product Brand added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateBrand = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const brandId = values.id;
      let { brand_name: name, is_activated: activated } = values;
      const body = { brand_name: name, is_activated: activated };

      // API
      const response = await AdminAPI.put(`/brands/${brandId}`, body, {
        headers,
      });

      let {
        brand: { brand_name, is_activated, _id: id },
      } = response.data;
      dispatch({
        type: UPDATE_PRODUCT_BRAND_SUCCESS,
        payload: { brand_name, is_activated, id },
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product Brand updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const listBrands = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: GET_ALL_PRODUCT_BRAND_REQUEST });

      // API
      const response = await AdminCommonAPI.get("/brands", APIHeaderCors);

      const brandList = [];
      response?.data?.map((x) => {
        brandList.push({
          id: x._id,
          brand_name: x.brand_name,
          is_activated: x.is_activated,
        });
      });
      dispatch({
        type: GET_ALL_PRODUCT_BRAND_SUCCESS,
        payload: brandList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: GET_ALL_PRODUCT_BRAND_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else 
      if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteBrand = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const brandId = values.id;

      // API
      const response = await AdminAPI.delete(`/brands/${brandId}`, { headers });

      dispatch({
        type: DELETE_PRODUCT_BRAND_SUCCESS,
        payload: brandId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product brand deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
