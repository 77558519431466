import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../addProduct/customColorInput.css";
import { Box } from "@mui/system";
import { Modal } from "@mui/material";
import { modelStyle } from "../../assets/css/muiStyled";
import Table from "../../components/Table";
import { Field, Form, Formik } from "formik";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import {
  ButtonWrapper,
  DashboardDetailsBorder,
  FormInputLabel,
  Head24Tag,
  MainPadding,
  Para14Tag,
  Para16Tag,
  Para20Tag,
} from "../../assets/css/styled";
import FormErrorMessage from "../../components/FormErrorMessage";
import { baseColorSchema } from "../../helper/yup.schema";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { ApiAddColor, ApiGetAllColor, ApiUpdateColor, ApiDeleteColor } from "../../helper/API/APICall";
import { UploadImagesIcon } from "../../components/typography/Icons";
import { CheckBoxCustom } from "../../components/typography/CheckBox";

const BaseColor = ({ activeTab }) => {
  const [colorList, setColorList] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({ color_name: "" });
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [imageURL, setImageURL] = React.useState(null);
  const [imageFile, setImageFile] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDeleteTriggered, setIsDeleteTriggered] = React.useState(false);
  const [active, setActive] = React.useState(false);

  const isFrameColor = activeTab === "frame_color" ? true : false;

  const handleDeleteClick = async (id) => {
    // API
    try {
      // API
      const response = await ApiDeleteColor(id);

      if (response?.data?.message?.status === 200) {
        setIsDeleteTriggered(!isDeleteTriggered);
        SuccessToast("Base color deleted successfully");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleEditClick = (id) => {
    setSelectedColorId(id);
    colorList.map((x) => {
      if (x?.id === id) {
        setFormInitialValues({ color_name: x?.color_name });
        setImageURL(x.image);
        setActive(x?.is_active);
      }
    });
    setOpen(true);
    setEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setActive(false);
    setImageFile(null);
    setImageURL(null);
    setFormInitialValues({ color_name: "" });
  };

  const handleAddColor = () => {
    setEdit(false);
    setOpen(true);
  };

  const columns = [
    {
      field: "image",
      headerName: "Color Image",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <img
          src={params?.value}
          alt={`Color ${params?.row?.id}`}
          style={{ width: "35px", height: "35px", borderRadius: "50%" }}
        />
      ),
      // renderCell: (params) => (
      //   <div
      //     className="custom_color_input_main_color_set_add"
      //     style={{ backgroundColor: params.row.base_color_code, width: "32px", height: "32px", borderRadius: "50%" }}></div>
      // ),
    },
    {
      field: "color_name",
      headerName: "Color Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "is_active",
      headerName: "Active",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleSubmit = async (values, { resetForm }) => {
    if (!imageURL) {
      ErrorToast("Please select an image!");
      return;
    }
    setIsLoading(true);
    try {
      if (edit) {
        const id = selectedColorId;
        const updateFormData = new FormData();
        updateFormData.append("color_name", values?.color_name);
        imageFile && updateFormData.append("image", imageFile);
        updateFormData.append("is_active", active);

        // API
        const response = await ApiUpdateColor(id, updateFormData);

        if (response?.data?.message?.status === 200) {
          SuccessToast("Base color updated successfully");
          setOpen(false);
          setEdit(false);
          setActive(false);
          setIsLoading(false);
          setSelectedColorId(null);
          setImageFile(null);
          setImageURL(null);
          resetForm();
        } else if (response?.data?.res?.status === 500) {
          setIsLoading(false);
          ErrorToast(response?.data?.res?.message);
        } else setIsLoading(false);
        return;
      }

      const newFormData = new FormData();
      newFormData.append("color_name", values?.color_name);
      newFormData.append("image", imageFile);
      newFormData.append("is_frame_color", isFrameColor);
      newFormData.append("is_active", active);

      // API
      const response = await ApiAddColor(newFormData);

      if (response?.data?.message?.status === 200) {
        SuccessToast("Base color added successfully");
        setOpen(false);
        setActive(false);
        setIsLoading(false);
        setImageFile(null);
        setImageURL(null);
        resetForm();
      } else if (response?.data?.res?.status === 500) {
        setIsLoading(false);
        ErrorToast(response?.data?.res?.message);
      } else setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllBaseColors = async () => {
    try {
      const response = await ApiGetAllColor(isFrameColor);
      const data = [];
      response?.data?.map((x) => {
        data.push({
          id: x?._id,
          color_name: x?.color_name,
          image: x?.image,
          is_frame_color: x?.is_frame_color,
          is_active: x?.is_active,
          created_at: x?.created_at,
          updated_at: x?.updated_at,
        });
      });
      setColorList(data);
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageURL(url);
    setImageFile(file);

    // Reset the input value to allow re-selecting the same file
    e.target.value = null;
  };

  useEffect(() => {
    getAllBaseColors();
  }, [activeTab, open, isDeleteTriggered]);

  return (
    <>
      <MainPadding
        p_bottom={20}
        p_top={20}>
        <Box
          m="20px"
          mb={0}
          mt={0}>
          <div className="flex w-full justify-between items-center">
            <Header
              title={`${isFrameColor ? "Frame" : "Lense"} Colors`}
              subtitle={`View All ${isFrameColor ? "Frame" : "Lense"} Colors`}
            />
            <div className="flex justify-end">
              <ButtonWrapper
                variant="outlined"
                onClick={handleAddColor}>
                {`Add ${isFrameColor ? "Frame" : "Lense"} Color`}
              </ButtonWrapper>
            </div>
          </div>
          {/* {isLoading ? (
            <Loader />
          ) : ( */}
          <Table
            rows={colorList}
            columns={columns}
          />
          {/* )} */}
        </Box>
      </MainPadding>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={modelStyle}>
          <div className="category_modal_add_form_main_div">
            <div className="payment_model_inner_head_main">
              <Head24Tag
                color="#0E1213"
                weight="600">
                {`${edit ? "Update" : "Add"} ${isFrameColor ? "Frame" : "Lense"} Color`}
              </Head24Tag>
            </div>
            <div
              className="network_insurance_modal_close"
              onClick={handleClose}>
              <ModalCloseButton />
            </div>
          </div>

          <div className="category_add_frame_form">
            <div className="form_inner_input_div_main">
              <FormInputLabel>Select Color Image</FormInputLabel>
            </div>

            <div>
              <div>
                <div>
                  <div className="border border-blue-300 rounded-md p-5 my-2">
                    <input
                      style={{ display: "none" }}
                      type="file"
                      name="thumbnail_upload_image"
                      id="thumbnail_upload_image"
                      accept="image/png, image/jpeg"
                      onChange={handleFile}
                    />
                    <div className="frame_info_images_outer_inner_three_images_show">
                      <label htmlFor="thumbnail_upload_image">
                        {imageURL && (
                          <img
                            style={{ cursor: "pointer" }}
                            src={imageURL}
                            alt="img"
                            className="frame_info_image_inner_open_inner"
                          />
                        )}
                        <div className="frame_info_text_files_name_browse">
                          <UploadImagesIcon />
                          <Para20Tag color={"#000"}>
                            Drop your Files here Or.
                            <span style={{ color: "#219EBC" }}> Browse</span>
                          </Para20Tag>
                        </div>
                        <Para16Tag>You can only select up to one images.</Para16Tag>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame_info_image_inner_open_bottom_div_inner">
                {imageURL && (
                  <DashboardDetailsBorder padding={"10px"}>
                    <div
                      style={{ gap: "40px" }}
                      className="frame_show_images_selected_file_inner">
                      <div className="frame_show_images_name_complete_done_div">
                        <img
                          src={imageURL}
                          alt="img"
                          className="frame_show_images_selected_file_inner_outer"
                        />
                      </div>
                      <div className="frame_info_images_inner_open_right_delete_icon_remove">
                        <button
                          className="frame_info_images_remove_icon_inner_delete"
                          onClick={() => {
                            setImageURL(null);
                            setImageFile(null);
                          }}>
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  </DashboardDetailsBorder>
                )}
              </div>
            </div>
            <Formik
              initialValues={formInitialValues}
              validationSchema={baseColorSchema}
              onSubmit={handleSubmit}>
              {({ touched, errors }) => (
                <Form>
                  <div className="technical_information_text_filed">
                    {/* <div
                      className="form_inner_input_div_main"
                      style={{ marginBottom: "10px" }}>
                      <FormInputLabel>Select Base Color</FormInputLabel>
                      <div className="custom_color_input">
                        <div className="input-group product-form-field">
                          <input
                            name="color_code"
                            type="color"
                            id="color_code"
                            onChange={handleColorChange}
                            // value={selectedColor}
                          />
                        </div>

                        <div className="product-form-field">
                          <span className="selected_color_inner">Selected Color</span>
                          {selectedColor && (
                            <div
                              className="color-chooser"
                              style={{ justifyContent: "center" }}>
                              <div
                                onClick={() => removeColor()}
                                className="color-item color-item-deletable"
                                title={`Remove ${selectedColor}`}
                                style={{ backgroundColor: selectedColor }}
                                role="presentation"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {touched.color_code && errors.color_code && <FormErrorMessage errors={errors.color_code} />}
                    </div> */}
                    <div className="form_inner_input_div_main">
                      <FormInputLabel>Color Name</FormInputLabel>
                      <div className="form_input_main">
                        <Field
                          type="text"
                          placeholder="Enter Color Name"
                          name="color_name"
                          className="from_input_tag"
                        />
                      </div>
                      {touched.color_name && errors.color_name && <FormErrorMessage errors={errors.color_name} />}
                    </div>
                    {/* Active checkbox */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      className="form_input_forget_text_main_pass_inner mt-4">
                      <div className="form_get_update_text_data_title">
                        <div
                          style={{ cursor: "pointer" }}
                          className="add_new_add_default_address">
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}>
                            <div
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              className="main_check_box">
                              <CheckBoxCustom
                                id="is_active"
                                name="is_active"
                                checked={active}
                                onChange={() => setActive(!active)}
                              />
                            </div>
                            <div className="label_text">
                              <Para14Tag
                                weight={500}
                                color="#A7ABAC">
                                Active
                              </Para14Tag>
                            </div>
                            {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form_sign_in_btn_main_inner_update">
                    <ButtonWrapper
                      type="submit"
                      auto={"auto"}>
                      {isLoading ? "Loading..." : edit ? "Update" : "Create"}
                    </ButtonWrapper>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default BaseColor;
