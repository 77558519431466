import React from "react";
import { LazyLoader } from "../components/LazyLoader";
import { lazy } from "react";
import { ColorModeContext, useMode } from "../theme";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import { routesConfig } from "../helper/config/routes.config";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "../scenes/layout/Sidebar";
import Invoices from "../scenes/invoices/Invoices";
import OtpVerification from "../components/Auth/otpVerification/OtpVerification";
import Login from "../components/Auth/login/Login";
import Order from "../scenes/order/Order";
import Products from "../scenes/products/Products";
import { LoggedRouter, PrivateRouter } from "../helper/PrivateRouter";
// import Category from "../scenes/category/Category";
// import AddProductBrand from "../scenes/addProductBrand/AddProductBrand";
// import ProductCategory from "../scenes/productCategory/ProductCategory";
// import AddAddFrameShape from "../scenes/addFrameShape/AddFrameShape";
import Brands from "../scenes/brands/Brands";
import FrameShapes from "../scenes/frameShapes/FrameShapes";
// import AddProduct from "../scenes/addProduct/AddProduct";
import Wrapper from "../scenes/wrapper/Wrapper";
import Blogs from "../scenes/blogs/Blogs";
import AddBlog from "../scenes/blogs/addBlog/AddBlog";
// import Colors from "../scenes/colors/Colors";
import FrameTypes from "../scenes/frameTypes/FrameTypes";
import FaceTypes from "../scenes/faceTypes/FaceTypes";
import FrameMaterial from "../scenes/frameMaterial/FrameMaterial";
import FrameSize from "../scenes/frameSize/FrameSize";
import NewForm from "../scenes/newForm/NewForm";
import PromoCode from "../scenes/promoCode/PromoCode";
import Lense from "../scenes/lense/Lense";
import SingleVisionTypes from "../scenes/singleVisionTypes/SingleVisionTypes";
import LensesType from "../scenes/lensesType/LensesType";
import MaterialType from "../scenes/materialType/MaterialType";
import Banner from "../scenes/banner/Banner";
import ColorWrapper from "../scenes/colorWrapper/ColorWrapper";
import Forgot from "../components/Auth/forgot/Forgot";
import VerifyEmail from "../components/Auth/verifyEmail/VerifyEmail";
import ResetPassword from "../components/Auth/resetPassword/ResetPassword";
import Accessory from "../scenes/accessory/Accessory";
import TrendingSearch from "../scenes/trendingSearch/TrendingSearch";
import OrderDetails from "../scenes/order/OrderDetails";
import WearTheTrend from "../scenes/wearTheTrend/WearTheTrend";
import NewsLetter from "../scenes/newsLetter/NewsLetter";
import Coupon from "../scenes/coupon/Coupon";
import ReferEarn from "../scenes/referEarn/ReferEarn";

const Header = LazyLoader(lazy(() => import("../scenes/layout/Header")));
const Dashboard = LazyLoader(lazy(() => import("../scenes/dashboard/Dashboard")));

const RouterLink = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  /* `const { pathname } = useLocation();` is using the `useLocation` hook from the `react-router-dom`
  library to get the current location object. The `pathname` property of the location object
  represents the current URL path. By destructuring `pathname` from the location object, it allows
  you to access the current URL path directly. */
  const { pathname } = useLocation();

  /* The `includesPath` constant is an array that contains the paths for the authentication routes in
  the application. These routes are used for login, creating an account, password recovery, OTP
  verification, and resetting the password. */
  const includesPath = [
    routesConfig?.login,
    routesConfig?.forgot,
    routesConfig?.otpVerification,
    routesConfig?.verifyEmail,
    routesConfig?.resetPassword,
  ];

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {!includesPath?.includes(pathname) ? <Sidebar isSidebar={isSidebar} /> : null}
            <main className="content">
              {/* {!includesPath?.includes(pathname) ? <Header setIsSidebar={setIsSidebar} /> : null} */}
              <Routes>
                {/* Logged Router */}
                <Route
                  path={routesConfig?.login}
                  element={<LoggedRouter Component={Login} />}
                />
                <Route
                  path={routesConfig?.forgot}
                  element={<Forgot Component={Forgot} />}
                />
                <Route
                  path={routesConfig?.otpVerification}
                  element={<LoggedRouter Component={OtpVerification} />}
                />
                <Route
                  path={routesConfig?.verifyEmail}
                  element={<VerifyEmail Component={VerifyEmail} />}
                />
                <Route
                  path={routesConfig?.resetPassword}
                  element={<ResetPassword Component={ResetPassword} />}
                />

                {/* Private Router */}
                <Route
                  path={routesConfig?.referalCode}
                  element={<PrivateRouter Component={ReferEarn} />}
                />
                <Route
                  path={routesConfig?.homePath}
                  element={<PrivateRouter Component={Dashboard} />}
                />
                <Route
                  path={routesConfig?.addProduct}
                  element={<PrivateRouter Component={NewForm} />}
                />
                <Route
                  path={`${routesConfig?.editProduct}/:id`}
                  element={<PrivateRouter Component={NewForm} />}
                />
                {/* <Route
                  path={routesConfig?.addBrand}
                  element={<PrivateRouter Component={AddProductBrand} />}
                />
                <Route
                  path={routesConfig?.addCategories}
                  element={<PrivateRouter Component={ProductCategory} />}
                />
                <Route
                  path={routesConfig?.addFrameShape}
                  element={<PrivateRouter Component={AddAddFrameShape} />}
                /> */}
                <Route
                  path={routesConfig?.getFrameShape}
                  element={<PrivateRouter Component={FrameShapes} />}
                />
                <Route
                  path={routesConfig?.getFrameTypes}
                  element={<PrivateRouter Component={FrameTypes} />}
                />
                <Route
                  path={routesConfig?.getFaceTypes}
                  element={<PrivateRouter Component={FaceTypes} />}
                />
                <Route
                  path={routesConfig?.getFrameMaterial}
                  element={<PrivateRouter Component={FrameMaterial} />}
                />
                <Route
                  path={routesConfig?.getFrameSize}
                  element={<PrivateRouter Component={FrameSize} />}
                />
                <Route
                  path={routesConfig?.products}
                  element={<PrivateRouter Component={Products} />}
                />
                <Route
                  path={routesConfig?.order}
                  element={<PrivateRouter Component={Order} />}
                />
                <Route
                  path={routesConfig?.orderDetailsId}
                  element={<PrivateRouter Component={OrderDetails} />}
                />
                <Route
                  path={routesConfig?.categories}
                  // element={<PrivateRouter Component={Category} />}
                  element={<PrivateRouter Component={Wrapper} />}
                />
                <Route
                  path={routesConfig?.getBrand}
                  element={<PrivateRouter Component={Brands} />}
                />
                <Route
                  path="/invoices"
                  element={<PrivateRouter Component={Invoices} />}
                />
                <Route
                  path={routesConfig?.accessories}
                  element={<PrivateRouter Component={Accessory} />}
                />
                <Route
                  path={routesConfig?.blogs}
                  element={<PrivateRouter Component={Blogs} />}
                />
                <Route
                  path={routesConfig?.addBlog}
                  element={<PrivateRouter Component={AddBlog} />}
                />
                <Route
                  path={`${routesConfig?.editBlog}/:id`}
                  element={<PrivateRouter Component={AddBlog} />}
                />
                <Route
                  path={`${routesConfig?.colors}`}
                  // element={<PrivateRouter Component={Colors} />}
                  element={<PrivateRouter Component={ColorWrapper} />}
                />
                <Route
                  path={routesConfig.promoCode}
                  element={<PrivateRouter Component={PromoCode} />}
                />
                <Route
                  path={routesConfig.lenses}
                  element={<PrivateRouter Component={Lense} />}
                />
                <Route
                  path={routesConfig.singleVisionTypes}
                  element={<PrivateRouter Component={SingleVisionTypes} />}
                />
                <Route
                  path={routesConfig.lensesTypes}
                  element={<PrivateRouter Component={LensesType} />}
                />
                <Route
                  path={routesConfig.materialTypes}
                  element={<PrivateRouter Component={MaterialType} />}
                />
                <Route
                  path={routesConfig.coupon}
                  element={<PrivateRouter Component={Coupon} />}
                />
                <Route
                  path={routesConfig.banner}
                  element={<PrivateRouter Component={Banner} />}
                />
                <Route
                  path={routesConfig.wearTheTrend}
                  element={<PrivateRouter Component={WearTheTrend} />}
                />
                <Route
                  path={routesConfig.trendingSearch}
                  element={<PrivateRouter Component={TrendingSearch} />}
                />
                <Route
                  path={routesConfig.newsLetter}
                  element={<PrivateRouter Component={NewsLetter} />}
                />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default RouterLink;
