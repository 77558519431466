import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { promoCodeSchema } from "../../helper/yup.schema";
import {
  ButtonWrapper,
  FormInputLabel,
  Head24Tag,
  MainPadding,
  Para14Tag,
} from "../../assets/css/styled";
import {
  listAllPromoCodes,
  createPromoCode,
  updatePromoCode,
} from "../../redux/actions/promoCodeAction";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxCustom } from "../../components/typography/CheckBox";

const PromoCode = () => {
  const [formInitialValues, setFormInitialValues] = useState({
    promo_code: "",
    discount_per: "",
    upto_amount: "",
    limit: "",
    promocode_category: "",
  });
  const [active, setActive] = React.useState(false);
  const [custom, setCustom] = React.useState(false);
  const [publicCode, setPublicCode] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const { promoCodeList } = useSelector((state) => state.promoCodes);

  // const handleDeleteClick = (id) => () => {
  //   dispatch(deletePromoCode({ id }));
  // };

  const handleEditClick = (id) => () => {
    const promoCode = promoCodeList.find((promo_code) => promo_code.id === id);
    setFormInitialValues(promoCode);
    setActive(promoCode?.is_active);
    setCustom(promoCode?.is_custom);
    setPublicCode(promoCode?.is_public);
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "promo_code",
      headerName: "Promo Code",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "discount_per",
      headerName: "Discount (%)",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "upto_amount",
      headerName: "Amount (Up to)",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "limit",
      headerName: "Limit (Per user)",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "promocode_category",
      headerName: "Promocode Category",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "is_active",
      headerName: "Active",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "is_custom",
      headerName: "Custom",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "is_public",
      headerName: "Public",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  const handleAddFrameSize = () => {
    setOpen(true);
    setEdit(false);
  };
  const handleClose = () => {
    setOpen(false);
    setFormInitialValues({
      promo_code: "",
      discount_per: "",
      upto_amount: "",
      limit: "",
      promocode_category: "",
    });
    setActive(false);
    setCustom(false);
    setPublicCode(false);
  };

  const handleSubmit = (values, { resetForm }) => {
    try {
      const body = {
        promo_code: values?.promo_code,
        discount_per: values?.discount_per,
        upto_amount: values?.upto_amount,
        limit: values?.limit,
        promocode_category: values.promocode_category,
        is_active: active,
        is_custom: custom,
        is_public: publicCode,
      };
      if (edit) {
        const id = values?.id;
        dispatch(updatePromoCode(body, id));
        setOpen(false);
        setActive(false);
        setCustom(false);
        setPublicCode(false);
        resetForm();
        return;
      }

      // Create promo code API
      dispatch(createPromoCode(body));
      setOpen(false);
      setActive(false);
      setCustom(false);
      setPublicCode(false);
      resetForm();
    } catch (error) {
      throw new Error(error)
    }
  };

  useEffect(() => {
    dispatch(listAllPromoCodes());
  }, [dispatch]);

  return (
    <MainPadding p_bottom={20} p_top={20}>
      <Box m="20px" mb={0} mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header title="Promo Codes" subtitle="View All The Promo Codes" />
          <div className="flex justify-end">
            <ButtonWrapper variant="outlined" onClick={handleAddFrameSize}>
              Add New Promo Code
            </ButtonWrapper>
          </div>
        </div>
        <Table columns={columns} rows={promoCodeList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag color="#0E1213" weight="600">
                  {`${edit ? "Update" : "Add"} Promo Code`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}
              >
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <Formik
                initialValues={formInitialValues}
                validationSchema={promoCodeSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({ touched, errors }) => (
                  <Form>
                    <div className="grid gap-5">
                      <div>
                        <FormInputLabel>Promo code</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Promo code"
                            name="promo_code"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.promo_code && errors.promo_code && (
                          <FormErrorMessage errors={errors.promo_code} />
                        )}
                      </div>
                      <div>
                        <FormInputLabel>Discount (%)</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Discount"
                            name="discount_per"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.discount_per && errors.discount_per && (
                          <FormErrorMessage errors={errors.discount_per} />
                        )}
                      </div>
                      <div>
                        <FormInputLabel>Amount (Up to)</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Amount"
                            name="upto_amount"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.upto_amount && errors.upto_amount && (
                          <FormErrorMessage errors={errors.upto_amount} />
                        )}
                      </div>
                      <div>
                        <FormInputLabel>Limit (Per user)</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="number"
                            placeholder="Enter limit"
                            name="limit"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.limit && errors.limit && (
                          <FormErrorMessage errors={errors.limit} />
                        )}
                      </div>
                      <div>
                        <FormInputLabel>Promocode category</FormInputLabel>
                        <div className="face_select_add_inner_face_select_input_data">
                          <Field as="select" name="promocode_category">
                            <option value="">
                              Select a Promocode category
                            </option>
                            <option value="all">All</option>
                            <option value="men">Men</option>
                            <option value="women">Women</option>
                            <option value="boy">Boy</option>
                            <option value="girl">Girl</option>
                            <option value="kid">Kid</option>
                            <option value="other">Other</option>
                          </Field>
                        </div>
                        {touched.promocode_category &&
                          errors.promocode_category && (
                            <FormErrorMessage
                              errors={errors.promocode_category}
                            />
                          )}
                      </div>
                    </div>
                    {/* Active checkbox */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      className="form_input_forget_text_main_pass_inner mt-4"
                    >
                      <div className="form_get_update_text_data_title">
                        <div
                          style={{ cursor: "pointer" }}
                          className="add_new_add_default_address"
                        >
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              className="main_check_box"
                            >
                              <CheckBoxCustom
                                id="is_featured"
                                name="is_featured"
                                checked={active}
                                onChange={() => setActive(!active)}
                              />
                            </div>
                            <div className="label_text">
                              <Para14Tag weight={500} color="#A7ABAC">
                                Active
                              </Para14Tag>
                            </div>
                            {touched.is_active && errors.is_active && (
                              <FormErrorMessage errors={errors.is_active} />
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Custom checkbox */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      className="form_input_forget_text_main_pass_inner mt-4"
                    >
                      <div className="form_get_update_text_data_title">
                        <div
                          style={{ cursor: "pointer" }}
                          className="add_new_add_default_address"
                        >
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              className="main_check_box"
                            >
                              <CheckBoxCustom
                                id="is_featured"
                                name="is_featured"
                                checked={custom}
                                onChange={() => setCustom(!custom)}
                              />
                            </div>
                            <div className="label_text">
                              <Para14Tag weight={500} color="#A7ABAC">
                                Custom
                              </Para14Tag>
                            </div>
                            {touched.is_custom && errors.is_custom && (
                              <FormErrorMessage errors={errors.is_custom} />
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Public checkbox */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      className="form_input_forget_text_main_pass_inner mt-4"
                    >
                      <div className="form_get_update_text_data_title">
                        <div
                          style={{ cursor: "pointer" }}
                          className="add_new_add_default_address"
                        >
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              className="main_check_box"
                            >
                              <CheckBoxCustom
                                id="is_public"
                                name="is_public"
                                checked={publicCode}
                                onChange={() => setPublicCode(!publicCode)}
                              />
                            </div>
                            <div className="label_text">
                              <Para14Tag weight={500} color="#A7ABAC">
                                Public
                              </Para14Tag>
                            </div>
                            {touched.is_public && errors.is_public && (
                              <FormErrorMessage errors={errors.is_public} />
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner mt-5">
                      <ButtonWrapper type="submit" auto={"auto"}>{`${
                        edit ? "Update" : "Create"
                      }`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default PromoCode;
