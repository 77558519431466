import React, { useState } from "react";
import PropTypes from "prop-types";
import "./from.css";
import "../addProduct/addProductTab/productInformation.css";
import {
  ButtonWrapper,
  ContainerWrapper,
  MainPadding,
  Para18Tag,
  StyledConnector,
  StyledStep,
  StyledStepContent,
  StyledStepLabel,
  StyledStepper,
} from "../../assets/css/styled";
import { Drawer } from "@mui/material";
import DrawerSidebar from "./DrawerSidebar";
import FrameInformation from "./FrameInformation";
import ProductImage from "./ProductImage";
import { CompletedIcons } from "../../components/typography/Icons";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="review_tab_child">{children}</div>}
    </div>
  );
};

/* The code `CustomTabPanel.propTypes` is defining the prop types for the `CustomTabPanel` component.
It specifies that the `children` prop should be of type `PropTypes.node`, which means it can accept
any valid React node. It also specifies that the `index` and `value` props should be of type
`PropTypes.number` and are required, meaning they must be provided when using the component. */
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const NewForm = () => {
  const [drawer, setDrawer] = React.useState({ left: false });
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  // const [user, setUser] = useState("");
  // const [selectedAddress, setSelectedAddress] = useState(null);
  // const [cartItemsList, setCartItemsList] = useState([]);
  // const [initFormikValues, setInitFormikValues] = React.useState({
  //   modelNo: "",
  //   frame_type: "",
  //   frame_Material: "",
  //   gender: "",
  //   frameDimensions: "",
  //   frameWeight: "",
  //   frameHeight: "",
  //   frameWidth: "",
  //   frame_shape: "",
  //   frame_Size: "",
  //   frameStyle: "",
  //   face_type: "",
  //   product_name: "",
  //   description: "",
  //   price: "",
  //   condition: "",
  //   brand_type: "",
  //   type: "",
  //   quantity: "",
  //   weight_group: "",
  //   material: "",
  //   temple_material: "",
  //   collection_name: "",
  //   Product_Warrenty: "",
  // });

  const toggleDrawer = (open) => setDrawer({ ...drawer, left: open });

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => setActiveStep(0);

  const handleStepEdit = (index) => {
    let currElem = index + 1;

    if (currElem === 1) {
      setActiveStep(0);
      // setUser("");
      // setSelectedAddress(null);
    } else if (currElem === 2) {
      setActiveStep(1);
      // setSelectedAddress(null);
    }

    // setActiveStep(index);

    // if (activeStep === 1) setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // else if (activeStep === 2)
    //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      id: 1,
      label: "Frame information",
      className: "stepper_bg_inner_details_add_div",
    },
    {
      id: 2,
      label: "Product information",
      className: "stepper_bg_inner_details_add_div",
    },
  ];

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <FrameInformation handleComplete={handleComplete} />;
      case 1:
        return <ProductImage complete={handleComplete} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="add_product">
        <section className="checkout_main_section">
          <ContainerWrapper>
            <MainPadding p_top={48} p_bottom={48}>
              <div className="login_for_booking_order_stepper_main">
                <StyledStepper
                  activeStep={activeStep}
                  orientation="vertical"
                  connector={<StyledConnector />}
                >
                  {steps.map((step, index) => (
                    <StyledStep
                      style={{
                        backgroundColor: step?.bg_color,
                        border: step?.border,
                      }}
                      key={index}
                    >
                      <StyledStepLabel
                        className={index === activeStep ? "custom-active" : ""}
                        active={index === activeStep}
                        iconContainer="iconContainer"
                      >
                        <div
                          className={`login_for_booking_order_stepper_main_change_button ${
                            index === activeStep ? "custom-active" : ""
                          }`}
                        >
                          <div className="step_login_inner_outer_class_add_top_main flex items-center ">
                            <Para18Tag
                              className={`flex 
                                ${
                                  index === activeStep
                                    ? "custom-active-text"
                                    : ""
                                }
                              `}
                            >
                              <span className="step_number"> {index + 1}</span>
                            </Para18Tag>

                            <div className="step_login_inner_outer_class_add">
                              <div className="step_login_inner_outer_class_add_true flex">
                                <Para18Tag
                                  className={`
                                ${
                                  index === activeStep
                                    ? "custom-active-text"
                                    : ""
                                }
                              `}
                                >
                                  {step.label}
                                </Para18Tag>
                                {activeStep > index ? <CompletedIcons /> : ""}
                              </div>
                              {/* <div className="step_login_inner_outer_class_add_user_add_text_titile">
                                {(user || selectedAddress) && (
                                  <Para14Tag weight={600} color={"#2E3233"}>
                                    {index === 0
                                      ? user
                                      : index === 1
                                      ? selectedAddress
                                      : ""}
                                  </Para14Tag>
                                )}
                              </div> */}
                            </div>
                          </div>

                          {activeStep > index ? (
                            <>
                              <ButtonWrapper
                                onClick={() => handleStepEdit(index)}
                                bg_color={"#FFF"}
                                border_color={"#DEE2E4"}
                                color={"#5F6364"}
                                hover_color={"#2E3233"}
                                hover_bg={"#FFF"}
                                hover_border={"#DEE2E4"}
                                disabled={activeStep <= index}
                              >
                                Change
                              </ButtonWrapper>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </StyledStepLabel>
                      <StyledStepContent>
                        <div className={`checkout_main_div ${step?.className}`}>
                          {renderStepContent(activeStep)}
                          {/* {step?.details}
                            <div className="login_booking_stepper_continue">
                              {activeStep !== 1 && (
                                <ButtonWrapper
                                  variant="contained"
                                  onClick={handleComplete}
                                >
                                  Continue
                                </ButtonWrapper>
                              )}
                            </div> */}
                        </div>
                      </StyledStepContent>
                    </StyledStep>
                  ))}
                </StyledStepper>

                {/* {activeStep === steps.length && (
                  <Paper square elevation={0}>
                    <div>All steps completed - you&apos;re finished</div>
                    <button onClick={handleReset}>Reset</button>
                  </Paper>
                )} */}
              </div>
            </MainPadding>
          </ContainerWrapper>
        </section>
      </div>

      <Drawer
        anchor="right"
        open={drawer.left}
        onClose={() => toggleDrawer(false)}
      >
        <DrawerSidebar closeButton={() => toggleDrawer(false)} />
      </Drawer>
    </>
  );
};

export default NewForm;
