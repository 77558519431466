import {
  ADD_COUPON_SUCCESS,
  LIST_ALL_COUPONS_REQUEST,
  LIST_ALL_COUPONS_SUCCESS,
  LIST_ALL_COUPONS_FAILURE,
  UPDATE_COUPON_SUCCESS,
  CREATE_COUPON_SUCCESS,
  DELETE_COUPON_SUCCESS,
  LOADING_TRUE,
  LOADING_FALSE,
} from "../constants/coupon";

const initialState = {
  couponList: [],
  loading: false,
  error: null,
};

let couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    case LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };

    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        couponList: [...state.couponList, action.payload],
      };

    case LIST_ALL_COUPONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_COUPONS_SUCCESS:
      return {
        ...state,
        couponList: action?.payload,
        loading: false,
      };

    case LIST_ALL_COUPONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_COUPON_SUCCESS: {
      const updatedCoupon = action.payload.discount;
      return {
        ...state,
        couponList: state.couponList.map((coupon) => {
          if (coupon.id === updatedCoupon?._id) {
            return {
              ...coupon,
              id: updatedCoupon?._id,
              title: updatedCoupon?.title,
              image: updatedCoupon?.image,
              description: updatedCoupon?.description,
              PromoCode_id: updatedCoupon?.PromoCode_id,
              is_active: updatedCoupon?.is_active,
              created_at: updatedCoupon?.created_at,
              update_at: updatedCoupon?.update_at,
              is_deleted: updatedCoupon?.is_deleted,
            };
          }
          return coupon;
        }),
        loading: false,
      };
    }

    case CREATE_COUPON_SUCCESS: {
      const { discount: coupon } = action.payload;
      const newCoupon = {
        id: coupon?._id,
        title: coupon?.title,
        image: coupon?.image,
        description: coupon?.description,
        PromoCode_id: coupon?.PromoCode_id,
        is_active: coupon?.is_active,
        created_at: coupon?.created_at,
        update_at: coupon?.update_at,
        is_deleted: coupon?.is_deleted,
      };
      return {
        ...state,
        couponList: [...state.couponList, newCoupon],
        loading: false,
      };
    }

    case DELETE_COUPON_SUCCESS: {
      const couponId = action.payload;
      return {
        ...state,
        couponList: state.couponList.filter((coupon) => coupon.id !== couponId),
      };
    }

    default:
      return state;
  }
};

export default couponReducer;
