/* The code is exporting an object named `toastMessage` which contains several constants. These
constants are used to display toast messages in a JavaScript application. Each constant represents a
specific message that can be shown to the user in different scenarios. For example,
`CREATE_USER_SUCCESS_MESSAGE` represents a success message when a user is created successfully,
`LOGIN_USER_INVALID_EMAIL` represents an error message when the user enters an invalid email during
login, and `NETWORK_ERROR` represents an error message when there is no internet connection. */
export const toastMessage = {
  CREATE_USER_SUCCESS_MESSAGE: "",

  ALREADY_TAKEN_USER_SUCCESS_MESSAGE: "",

  LOGIN_USER_SUCCESS_MESSAGE: "",

  LOGIN_USER_INVALID_EMAIL: "Invalid email Please check your credentials.",

  LOGIN_USER_INVALID_EMAIL_OR_PASSWORD: "Invalid email or password Please check your credentials.",

  OTP_VERIFY_USER_SUCCESS_MESSAGE: "",

  ERROR_TRY_AGAIN: "Something went wrong! Try again in a few minutes",

  NETWORK_ERROR: "No internet connection Cannot connect to the server, Check your internet network",
};
