/* This code is creating an authentication context and provider using React's context API. */
import React from "react";
import { Auth } from "./Auth";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const token = Auth?.getAdminToken(); // Retrieve token from cookie
    if (token) setAuthenticated(true);
  }, []);

  return (
    <>
      <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
