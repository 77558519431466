import {
  ADD_FRAME_MATERIAL_SUCCESS,
  LIST_ALL_FRAME_MATERIALS_SUCCESS,
  UPDATE_FRAME_MATERIAL_SUCCESS,
  LIST_ALL_FRAME_MATERIALS_REQUEST,
  LIST_ALL_FRAME_MATERIALS_FAILURE,
  DELETE_FRAME_MATERIAL_SUCCESS,
  CREATE_FRAME_MATERIAL_SUCCESS,
} from "../constants/frameMaterial";

const initialState = {
  frameMaterialList: [],
  loading: false,
  error: null,
};

let frameMaterialReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FRAME_MATERIAL_SUCCESS:
      return {
        ...state,
        frameMaterialList: [...state.frameMaterialList, action.payload],
      };

    case LIST_ALL_FRAME_MATERIALS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_FRAME_MATERIALS_SUCCESS:
      return {
        ...state,
        frameMaterialList: action?.payload,
        loading: false,
      };

    case LIST_ALL_FRAME_MATERIALS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_FRAME_MATERIAL_SUCCESS: {
      const updatedMaterialType = action.payload.frameMaterial;
      return {
        ...state,
        frameMaterialList: state.frameMaterialList.map((frame_material) => {
          if (frame_material.id === updatedMaterialType._id) {
            return {
              ...frame_material,
              frame_material: updatedMaterialType.frame_material,
              is_active: updatedMaterialType.is_active,
            };
          }
          return frame_material;
        }),
      };
    }

    case CREATE_FRAME_MATERIAL_SUCCESS: {
      const frameMaterial = action.payload.frameMaterial[0];
      const newMaterialType = {
        id: frameMaterial._id,
        frame_material: frameMaterial.frame_material,
        is_active: frameMaterial.is_active,
      };
      return {
        ...state,
        frameMaterialList: [...state.frameMaterialList, newMaterialType],
      };
    }

    case DELETE_FRAME_MATERIAL_SUCCESS: {
      const frameMaterialId = action.payload;
      return {
        ...state,
        frameMaterialList: state.frameMaterialList.filter((frame_material) => frame_material.id !== frameMaterialId),
      };
    }
    default:
      return state;
  }
};

export default frameMaterialReducer;
