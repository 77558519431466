import { AdminAPI } from "../../helper/API/API.base";
import { getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  LIST_ALL_FRAME_SIZES_SUCCESS,
  UPDATE_FRAME_SIZE_SUCCESS,
  LIST_ALL_FRAME_SIZES_REQUEST,
  LIST_ALL_FRAME_SIZES_FAILURE,
  DELETE_FRAME_SIZE_SUCCESS,
  CREATE_FRAME_SIZE_SUCCESS,
} from "../constants/frameSizes";

export const listAllFrameSizes = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: LIST_ALL_FRAME_SIZES_REQUEST });

      // API
      const response = await AdminAPI.get("/frame-size", { headers });

      const frameSizeList = [];
      response?.data?.map((x) => {
        frameSizeList.push({
          id: x._id,
          frame_size: x.frame_size,
          is_active: x.is_active,
        });
      });

      dispatch({
        type: LIST_ALL_FRAME_SIZES_SUCCESS,
        payload: frameSizeList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_FRAME_SIZES_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateFrameSize = (values, frameSizeId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/frame-size/${frameSizeId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_FRAME_SIZE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame size updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteFrameSize = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const frameSizeId = values.id;

      // API
      const response = await AdminAPI.delete(`/frame-size/${frameSizeId}`, {
        headers,
      });

      dispatch({
        type: DELETE_FRAME_SIZE_SUCCESS,
        payload: frameSizeId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame size deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createFrameSize = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = {
        frame_size: values?.frame_size,
      };

      // API
      const response = await AdminAPI.post(`/frame-size`, body, { headers });

      dispatch({
        type: CREATE_FRAME_SIZE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame size added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
