import {
  ADD_PROMO_CODE_SUCCESS,
  LIST_ALL_PROMO_CODES_REQUEST,
  LIST_ALL_PROMO_CODES_SUCCESS,
  LIST_ALL_PROMO_CODES_FAILURE,
  UPDATE_PROMO_CODE_SUCCESS,
  CREATE_PROMO_CODE_SUCCESS,
  DELETE_PROMO_CODE_SUCCESS,
} from "../constants/promoCode";

const initialState = {
  promoCodeList: [],
  loading: false,
  error: null,
};

let promoCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodeList: [...state.promoCodeList, action.payload],
      };

    case LIST_ALL_PROMO_CODES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_PROMO_CODES_SUCCESS:
      return {
        ...state,
        promoCodeList: action?.payload,
        loading: false,
      };

    case LIST_ALL_PROMO_CODES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_PROMO_CODE_SUCCESS: {
      const updatedPromoCode = action.payload.promoCode;
      return {
        ...state,
        promoCodeList: state.promoCodeList.map((promoCode) => {
          if (promoCode.id === updatedPromoCode._id) {
            return {
              ...promoCode,
              promo_code: updatedPromoCode?.promo_code,
              discount_per: updatedPromoCode?.discount_per,
              upto_amount: updatedPromoCode?.upto_amount,
              limit: updatedPromoCode?.limit,
              is_active: updatedPromoCode?.is_active,
              is_custom: updatedPromoCode?.is_custom,
              is_public: updatedPromoCode?.is_public,
            };
          }
          return promoCode;
        }),
      };
    }

    case CREATE_PROMO_CODE_SUCCESS: {
      const promoCode = action.payload.promoCode;
      const newPromoCode = {
        id: promoCode._id,
        promo_code: promoCode?.promo_code,
        discount_per: promoCode?.discount_per,
        upto_amount: promoCode?.upto_amount,
        limit: promoCode?.limit,
        is_active: promoCode?.is_active,
        is_custom: promoCode?.is_custom,
        is_public: promoCode?.is_public,
      };
      return {
        ...state,
        promoCodeList: [...state.promoCodeList, newPromoCode],
      };
    }

    case DELETE_PROMO_CODE_SUCCESS: {
      const promoCodeId = action.payload;
      return {
        ...state,
        promoCodeList: state.promoCodeList.filter((promoCode) => promoCode.id !== promoCodeId),
      };
    }
    default:
      return state;
  }
};

export default promoCodeReducer;
