import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { blogSchema } from "../../../helper/yup.schema";
import {
  ButtonWrapper,
  FormInputLabel,
  Para16Tag,
  Para20Tag,
  DashboardDetailsBorder,
  ContainerWrapper,
  MainPadding,
  Para14Tag,
  Head28Tag,
} from "../../../assets/css/styled";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../../components/FormErrorMessage";
import { ErrorToast, SuccessToast } from "../../../helper/Toast";
import { UploadImagesIcon } from "../../../components/typography/Icons";
import JoditEditor from "jodit-react";
import {
  ApiAddBlog,
  ApiGetSingleBlog,
  ApiUpdateBlog,
} from "../../../helper/API/APICall";
import { CheckBoxCustom } from "../../../components/typography/CheckBox";
import { routesConfig } from "../../../helper/config/routes.config";
import Loader from "../../../components/Loader";
import Back from "../../../components/Back";
import { useDispatch, useSelector } from "react-redux";
import { listAllCategories } from "../../../redux/actions/categoriesAction";

const AddBlog = () => {
  const { id } = useParams();

  const [checked, setChecked] = React.useState(false);
  const [popular, setPopular] = React.useState(false);
  const [mostPopular, setMostPopular] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [content, setContent] = useState("");
  const [formInitialValues, setFormInitialValues] = useState({
    title: "",
    author: "",
    // read_time: "",
    meta_title: "",
    meta_description: "",
    meta_tag: "",
    // category_id: "",
  });
  const navigate = useNavigate();
  const editor = React.useRef(null);
  const dispatch = useDispatch();

  const { categoryList } = useSelector((state) => state.categories);

  const getSingleBlogDetails = async () => {
    setIsLoading(true);
    try {
      const response = await ApiGetSingleBlog(id);
      if (response?.status === 200) {
        setFormInitialValues({
          title: response?.data?.title,
          author: response?.data?.author,
          // read_time: response?.data?.read_time,
          meta_title: response?.data?.meta_title,
          meta_description: response?.data?.meta_description,
          meta_tag: response?.data?.meta_tag,
          // category_id: response?.data?.category_id?._id,
        });
        setContent(response?.data?.description);
        setChecked(response?.data?.is_featured);
        setPopular(response?.data?.is_popular);
        setMostPopular(response?.data?.is_most_popular);
        setImageURL(response?.data?.image);
        setImageFile(null);
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getSingleBlogDetails();
    }
    dispatch(listAllCategories());
  }, [id]);

  const handleSubmit = async (values, { resetForm }) => {
    if (!imageURL) {
      ErrorToast("Please select an image!");
      return;
    }
    try {
      setLoading(true);
      if (id) {
        const updateFormData = new FormData();

        // Append each field to the formData object
        imageFile && updateFormData.append("image", imageFile);
        updateFormData.append("title", values?.title);
        updateFormData.append("description", content);
        updateFormData.append("author", values?.author);
        // updateFormData.append("read_time", values?.read_time);
        updateFormData.append("meta_title", values?.meta_title);
        updateFormData.append("meta_description", values?.meta_description);
        updateFormData.append("meta_tag", values?.meta_tag);
        // updateFormData.append("category_id", values?.category_id);
        updateFormData.append("is_featured", checked);
        updateFormData.append("is_popular", popular);
        updateFormData.append("is_most_popular", mostPopular);

        // API
        const response = await ApiUpdateBlog(id, updateFormData);

        if (response?.data?.message?.status === 200) {
          SuccessToast("Blog updated successfully!");
          navigate(routesConfig?.blogs);
          setFormInitialValues({
            title: "",
            author: "",
            // read_time: "",
            meta_title: "",
            meta_description: "",
            meta_tag: "",
            // category_id: "",
          });
          setContent("");
          setChecked(false);
          setPopular(false);
          setMostPopular(false);
          setImageFile(null);
          setImageURL(null);
          setLoading(false);
          resetForm();
        } else if (response?.data?.res?.status === 500) {
          setLoading(false);
          ErrorToast(response?.data?.res?.message);
        } else setLoading(false);
        return;
      }

      const newFormData = new FormData();
      newFormData.append("image", imageFile);
      newFormData.append("title", values?.title);
      newFormData.append("description", content);
      newFormData.append("author", values?.author);
      // newFormData.append("read_time", values?.read_time);
      newFormData.append("meta_title", values?.meta_title);
      newFormData.append("meta_description", values?.meta_description);
      newFormData.append("meta_tag", values?.meta_tag);
      // newFormData.append("category_id", values?.category_id);
      newFormData.append("is_featured", checked);
      newFormData.append("is_popular", popular);
      newFormData.append("is_most_popular", mostPopular);

      // API
      const response = await ApiAddBlog(newFormData);

      if (response?.data?.message?.status === 200) {
        SuccessToast("Blog added successfully!");
        setLoading(false);
        navigate(routesConfig?.blogs);
        setImageFile(null);
        setImageURL(null);
        setContent("");
        setChecked(false);
        setPopular(false);
        setMostPopular(false);
        resetForm();
      } else if (response?.data?.res?.status === 500) {
        setLoading(false);
        ErrorToast(response?.data?.res?.message);
      } else setLoading(false);
    } catch (error) {
      setLoading(false);
      ErrorToast(error);
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageURL(url);
    setImageFile(file);

    // Reset the input value to allow re-selecting the same file
    e.target.value = null;
  };

  return (
    <>
      <ContainerWrapper>
        <MainPadding p_top={48} p_bottom={48}>
          <div className="login_right_back_page_main_div_inner">
            <Back text={"Back"} />
          </div>
          <div className="add_product_inner">
            <Head28Tag>{id ? "Edit" : "Add new"} blog</Head28Tag>
          </div>
          {/* </div> */}
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {/* Select Blog Image */}
              <div className="form_inner_input_div_main">
                <FormInputLabel>Select Image</FormInputLabel>
              </div>
              <div>
                <div className="border border-blue-300 rounded-md p-5 my-2">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="thumbnail_upload_image"
                    id="thumbnail_upload_image"
                    accept="image/png, image/jpeg"
                    onChange={handleFile}
                  />
                  <div className="frame_info_images_outer_inner_three_images_show">
                    <label htmlFor="thumbnail_upload_image">
                      {imageURL && (
                        <img
                          style={{ cursor: "pointer" }}
                          src={imageURL}
                          alt="img"
                          className="frame_info_image_inner_open_inner"
                        />
                      )}
                      <div className="frame_info_text_files_name_browse">
                        <UploadImagesIcon />
                        <Para20Tag color={"#000"}>
                          Drop your Files here Or.
                          <span style={{ color: "#219EBC" }}> Browse</span>
                        </Para20Tag>
                      </div>
                      <Para16Tag>
                        You can only select up to one images.
                      </Para16Tag>
                    </label>
                  </div>
                </div>

                <div className="frame_info_image_inner_open_bottom_div_inner">
                  {imageURL && (
                    <DashboardDetailsBorder padding={"10px"}>
                      <div
                        style={{ gap: "40px" }}
                        className="frame_show_images_selected_file_inner"
                      >
                        <div className="frame_show_images_name_complete_done_div">
                          <img
                            src={imageURL}
                            alt="img"
                            className="frame_show_images_selected_file_inner_outer"
                          />
                        </div>
                        <div className="frame_info_images_inner_open_right_delete_icon_remove">
                          <button
                            className="frame_info_images_remove_icon_inner_delete"
                            onClick={() => {
                              setImageURL(null);
                              setImageFile(null);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    </DashboardDetailsBorder>
                  )}
                </div>
              </div>

              <Formik
                initialValues={formInitialValues}
                validationSchema={blogSchema}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, values, setFieldValue }) => (
                  <Form>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Title</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Blog Title"
                            name="title"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.title && errors.title && (
                          <FormErrorMessage errors={errors.title} />
                        )}
                      </div>
                      <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Description</FormInputLabel>
                        {/* <div className="form_input_main" style={{ display: 'block' }}> */}
                        <JoditEditor
                          ref={editor}
                          value={content}
                          // config={config}
                          tabIndex={1}
                          // onBlur={(newContent) => setContent(newContent)}
                          onChange={(newContent) => setContent(newContent)}
                        />
                        {/* </div> */}
                        {touched.description && errors.description && (
                          <FormErrorMessage errors={errors.description} />
                        )}
                      </div>
                      {/* <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Category</FormInputLabel>
                        <Field name="category_id" as="select">
                          <option value="">Select a category</option>
                          {!categoryList.length
                            ? "Loading..."
                            : categoryList?.map((value, index) => {
                                return (
                                  <option key={index} value={value?.id}>
                                    {value?.type}
                                  </option>
                                );
                              })}
                        </Field>
                        {touched.category_id && errors.category_id && (
                          <FormErrorMessage errors={errors.category_id} />
                        )}
                      </div> */}
                      <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Author</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Author Name"
                            name="author"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.author && errors.author && (
                          <FormErrorMessage errors={errors.author} />
                        )}
                      </div>
                      {/* <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Read Time</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Read Time"
                            name="read_time"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.read_time && errors.read_time && (
                          <FormErrorMessage errors={errors.read_time} />
                        )}
                      </div> */}
                      <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Meta Title</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Meta Title"
                            name="meta_title"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.meta_title && errors.meta_title && (
                          <FormErrorMessage errors={errors.meta_title} />
                        )}
                      </div>
                      <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Meta Description</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Meta Description"
                            name="meta_description"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.meta_description &&
                          errors.meta_description && (
                            <FormErrorMessage
                              errors={errors.meta_description}
                            />
                          )}
                      </div>
                      <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Meta Tags</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Meta Tags"
                            name="meta_tag"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.meta_tag && errors.meta_tag && (
                          <FormErrorMessage errors={errors.meta_tag} />
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="form_input_forget_text_main_pass_inner mt-4"
                      >
                        <div className="form_get_update_text_data_title">
                          <div
                            style={{ cursor: "pointer" }}
                            className="add_new_add_default_address"
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                className="main_check_box"
                              >
                                <CheckBoxCustom
                                  id="is_featured"
                                  name="is_featured"
                                  checked={checked}
                                  onChange={() => setChecked(!checked)}
                                />
                              </div>
                              <div className="label_text">
                                <Para14Tag weight={500} color="#A7ABAC">
                                  Featured
                                </Para14Tag>
                              </div>
                              {touched.is_featured && errors.is_featured && (
                                <FormErrorMessage errors={errors.is_featured} />
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="form_input_forget_text_main_pass_inner mt-4"
                      >
                        <div className="form_get_update_text_data_title">
                          <div
                            style={{ cursor: "pointer" }}
                            className="add_new_add_default_address"
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                className="main_check_box"
                              >
                                <CheckBoxCustom
                                  id="is_popular"
                                  name="is_popular"
                                  checked={popular}
                                  onChange={() => setPopular(!popular)}
                                />
                              </div>
                              <div className="label_text">
                                <Para14Tag weight={500} color="#A7ABAC">
                                  Popular
                                </Para14Tag>
                              </div>
                              {touched.is_featured && errors.is_featured && (
                                <FormErrorMessage errors={errors.is_featured} />
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="form_input_forget_text_main_pass_inner mt-4"
                      >
                        <div className="form_get_update_text_data_title">
                          <div
                            style={{ cursor: "pointer" }}
                            className="add_new_add_default_address"
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                className="main_check_box"
                              >
                                <CheckBoxCustom
                                  id="is_mostPopular"
                                  name="is_mostPopular"
                                  checked={mostPopular}
                                  onChange={() => setMostPopular(!mostPopular)}
                                />
                              </div>
                              <div className="label_text">
                                <Para14Tag weight={500} color="#A7ABAC">
                                  Most Popular
                                </Para14Tag>
                              </div>
                              {touched.is_featured && errors.is_featured && (
                                <FormErrorMessage errors={errors.is_featured} />
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner_update">
                      <ButtonWrapper type="submit" auto={"auto"}>
                        {loading ? "Loading..." : `${id ? "Update" : "Create"}`}
                      </ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </MainPadding>
      </ContainerWrapper>
    </>
  );
};

export default AddBlog;
