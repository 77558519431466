/**
 * The forgotPassword component is a form that allows users to enter their email address to reset their password.
 * @returns The forgotPassword component is returning a JSX element.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import "./resetPassword.css";
import AuthSlider from "../authSlider/AuthSlider";
import { ButtonWrapper, FormInputLabel, FormInputOuter, Head48Tag, MainPadding, Para16Tag } from "../../../assets/css/styled";
import Back from "../../Back";
import { Field, Form, Formik } from "formik";
import { resetPasswordSchema } from "../../../helper/yup.schema";
import { ApiResetPassword } from "../../../helper/API/APICall";
import { ErrorToast, SuccessToast } from "../../../helper/Toast";
import { Auth } from "../../../helper/Auth";
import { PasswordIcon } from "../../typography/Icons";
import { routesConfig } from "../../../helper/config/routes.config";
import FormErrorMessage from "../../FormErrorMessage";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const adminEmail = Auth?.getAdminEmail() || null;

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  /**
   * The handleSubmit function is a JavaScript function that handles form submission, dispatches a
   * loginUserAction, resets the form, and resolves or rejects a promise based on the success or
   * failure of the login action.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It typically includes properties for each form field, with the field names as keys and the
   * user input as values.
   * @returns The handleSubmit function returns a Promise.
   */
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const body = {
        email: adminEmail,
        newpassword: values?.password,
      };
   
      // API
      const response = await ApiResetPassword(body);

      if (response?.data?.status === 200) {
        SuccessToast("Password reset successfully");
        navigate(routesConfig?.homePath);
        resetForm();
        Auth?.setAdminEmail(null);
      } else if (response?.data?.status === 400) ErrorToast(response?.data?.message);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="auth_slider_main login">
        <div className="grid grid-cols-2">
          <div className="login_left_main_page">
            <AuthSlider />
          </div>
          <div className="login_right_main_page">
            <div className="login_right_back_page_main_div_inner">
              <MainPadding p_bottom={20}>
                <Back text={"Back"} />
              </MainPadding>
            </div>
            <div className="login_right_inner_outer_info">
              <div className="login_text_main_title_top_div_inner">
                <div className="login_text_main_title">
                  <Head48Tag className="text-center">Reset Password</Head48Tag>
                </div>
                <div className="login_text_main_title_para_inner_information">
                  <Para16Tag
                    weight={500}
                    color={"#868A8B"}
                    center={"center"}>
                    {"No worries,we’ll send you reset instruction"}
                  </Para16Tag>
                </div>
              </div>
              <div className="auth_form_div">
                <Formik
                  initialValues={initialValues}
                  validationSchema={resetPasswordSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched }) => (
                    <Form className="auth_page_form_main">
                      <div className="auth_page_from_inner_div">
                        <div className="form_input_list_email_pass_other_data">
                          <div className="form_input_flex_div_mx_bottom">
                            <div className="form_inner_input_div_main">
                              <FormInputLabel>Password</FormInputLabel>
                              <FormInputOuter>
                                <PasswordIcon stroke={"#000000"} />
                                <Field
                                  className="from_input_tag"
                                  type="password"
                                  placeholder="Enter your password"
                                  name="password"
                                />
                              </FormInputOuter>
                              {touched.password && errors.password && <FormErrorMessage errors={errors.password} />}
                            </div>
                          </div>
                        </div>
                        <div className="form_input_list_email_pass_other_data">
                          <div className="form_input_flex_div_mx_bottom">
                            <div className="form_inner_input_div_main">
                              <FormInputLabel>Confirm Password</FormInputLabel>
                              <FormInputOuter>
                                <PasswordIcon stroke={"#000000"} />
                                <Field
                                  className="from_input_tag"
                                  type="password"
                                  placeholder="Enter your confirm password"
                                  name="confirmPassword"
                                />
                              </FormInputOuter>
                              {touched.confirmPassword && errors.confirmPassword && <FormErrorMessage errors={errors.confirmPassword} />}
                            </div>
                          </div>
                        </div>
                        <div className="form_sign_in_btn_main">
                          <ButtonWrapper
                            width="100%"
                            type="submit">
                            {isLoading ? "Loading..." : "Verify"}
                          </ButtonWrapper>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
