import { AdminAPI } from "../../helper/API/API.base";
import { getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  LIST_ALL_SINGLE_VISION_TYPE_REQUEST,
  LIST_ALL_SINGLE_VISION_TYPE_SUCCESS,
  LIST_ALL_SINGLE_VISION_TYPE_FAILURE,
  UPDATE_SINGLE_VISION_TYPES_SUCCESS,
  DELETE_SINGLE_VISION_TYPE_SUCCESS,
  CREATE_SINGLE_VISION_TYPES_SUCCESS,
} from "../constants/singleVisionTypes";

export const listAllSingleVisionType = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_SINGLE_VISION_TYPE_REQUEST });

      // headers
      const headers = await getHeaders();

      // API
      const response = await AdminAPI.get("/single-vision-type", { headers });

      const singleVisionTypesList = [];
      response?.data?.forEach((x) => {
        singleVisionTypesList.push({
          id: x._id,
          single_vision_type: x.single_vision_type,
          description: x.description,
          price: x.price,
          is_active: x.is_active,
        });
      });

      dispatch({
        type: LIST_ALL_SINGLE_VISION_TYPE_SUCCESS,
        payload: singleVisionTypesList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_SINGLE_VISION_TYPE_FAILURE,
        payload: error?.message,
      });

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateSingleVisionType = (values, SingleVisionTypeId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/single-vision-type/${SingleVisionTypeId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_SINGLE_VISION_TYPES_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Single vision type updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteSingleVisionType = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const singleVisionTypeId = values.id;

      // API
      const response = await AdminAPI.delete(`/single-vision-type/${singleVisionTypeId}`, { headers });

      dispatch({
        type: DELETE_SINGLE_VISION_TYPE_SUCCESS,
        payload: singleVisionTypeId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Single vision type deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createSingleVisionType = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/single-vision-type`, body, { headers });

      dispatch({
        type: CREATE_SINGLE_VISION_TYPES_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Single vision type added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
