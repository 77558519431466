import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../components/Table";
import { lensesTypesSchema } from "../../helper/yup.schema";
import { Para16Tag, ButtonWrapper, FormInputLabel, Head24Tag, MainPadding, Para14Tag } from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { listAllLensesTypes, createLenseType, updateLenseType, deleteLenseType } from "../../redux/actions/lensesTypesAction";
import { CheckBoxCustom } from "../../components/typography/CheckBox";
import { ApiGetAllColor } from "../../helper/API/APICall";

const LensesType = () => {
  const [formInitialValues, setFormInitialValues] = useState({
    lenses_type: "",
    description: "",
    price: "",
    colors: [],
  });
  const [selectedLenseTypesId, setSelectedLenseTypesId] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [colors, setColors] = React.useState([]);
  const [active, setActive] = React.useState(false);

  const dispatch = useDispatch();
  const { lensesTypesList } = useSelector((state) => state.lensesTypes);

  const getAllColors = async () => {
    try {
      const response = await ApiGetAllColor(false);
      const colorsData = await response?.data.map((color) => ({
        id: color?._id,
        color_name: color?.color_name,
        image: color?.image,
        is_frame_color: color?.is_frame_color,
        is_active: color?.is_active,
        created_at: color?.created_at,
      }));
      setColors(colorsData);
    } catch (error) {
      throw new Error(error)
    }
  };

  useEffect(() => {
    getAllColors();
  }, []);

  useEffect(() => {
    dispatch(listAllLensesTypes());
  }, [dispatch, open]);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteLenseType({ id }));
  };

  const handleEditClick = (id) => () => {
    setSelectedLenseTypesId(id);
    lensesTypesList.forEach((x) => {
      if (x.id === id) {
        const colors = x?.colors?.map((color) => color?.color_id?._id);
        setFormInitialValues({
          lenses_type: x?.lenses_type,
          description: x?.description,
          price: x?.price,
          colors: colors,
        });
        setActive(x?.is_active);
      }
    });
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "lenses_type",
      headerName: "Lense Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
    },
    {
      field: "colors",
      headerName: "Colors",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
      renderCell: (params) => {
        const { value: colorsArray } = params;
        return (
          <div>
            {colorsArray.length ? colorsArray?.map((color, index) => <p key={index}>{color?.color_id?.color_name}</p>) : <p>No color</p>}
          </div>
        );
      },
    },
    {
      field: "is_active",
      headerName: "Active",
      headerAlign: "center",
      flex: 1,
      align: "center",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddCategory = () => {
    setOpen(true);
    setEdit(false);
  };
  const handleClose = () => {
    setOpen(false);
    setActive(false);
    setFormInitialValues({
      lenses_type: "",
      description: "",
      price: "",
      colors: [],
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    try {
      const body = {
        lenses_type: values?.lenses_type,
        description: values?.description,
        price: values?.price,
        colors: values?.colors?.map((color) => {
          return { color_id: color };
        }),
        is_active: active,
      };
      if (edit) {
        dispatch(updateLenseType(body, selectedLenseTypesId));
        setOpen(false);
        setEdit(false);
        setActive(false);
        setFormInitialValues({
          lenses_type: "",
          description: "",
          price: "",
          colors: [],
        });
        return;
      }

      // Create lense type API
      dispatch(createLenseType(body));
      resetForm();
      setSelectedLenseTypesId(null);
      setActive(false);
      setOpen(false);
    } catch (error) {
      throw new Error(error)
    }
  };

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <div>
          <Header
            title="Lenses Types"
            subtitle="View All The Lenses Types"
          />
          <Para16Tag className="font-black mt-2 max-w-xl">Note: Do not change any words because our logic and conditions are based on this text. If you change any word casing, then our flow will not work</Para16Tag>
          </div>
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddCategory}>
              Add New Lense Type
            </ButtonWrapper>
          </div>
        </div>
        <Table
          columns={columns}
          rows={lensesTypesList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Lense Type`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <Formik
                initialValues={formInitialValues}
                validationSchema={lensesTypesSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}>
                {({ touched, errors, values, handleChange }) => (
                  <Form>
                    <div className="technical_information_text_filed mt-5">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Lense Type</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Lense Type"
                            name="lenses_type"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.lenses_type && errors.lenses_type && <FormErrorMessage errors={errors.lenses_type} />}
                      </div>
                    </div>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Lense Type Description</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Lense Description"
                            name="description"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.description && errors.description && <FormErrorMessage errors={errors.description} />}
                      </div>
                    </div>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Lense Type Price</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Lense Type Description"
                            name="price"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.price && errors.price && <FormErrorMessage errors={errors.price} />}
                      </div>
                    </div>
                    <div className="technical_information_text_filed">
                      <div className="grid grid-cols-2 gap-5 mt-5">
                        <div>
                          <FormInputLabel>Color</FormInputLabel>
                          {colors?.map((color, index) => {
                            return (
                              <label
                                key={index}
                                className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                                <div className="flex cursor-pointer">
                                  <div
                                    className="flex cursor-pointer"
                                    style={{
                                      marginRight: "10px",
                                    }}>
                                    <CheckBoxCustom
                                      id="gender_male"
                                      name="colors"
                                      value={color?.id}
                                      checked={values.colors.includes(`${color?.id}`)}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <Para16Tag color={"#A7ABAC"}>{color?.color_name}</Para16Tag>
                                </div>
                              </label>
                            );
                          })}
                          {touched.colors && errors.colors && <FormErrorMessage errors={errors.colors} />}
                        </div>
                      </div>
                    </div>
                    {/* Active checkbox */}
                    <div className="technical_information_text_filed">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="form_input_forget_text_main_pass_inner mt-4">
                        <div className="form_get_update_text_data_title">
                          <div
                            style={{ cursor: "pointer" }}
                            className="add_new_add_default_address">
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}>
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                className="main_check_box">
                                <CheckBoxCustom
                                  id="is_active"
                                  name="is_active"
                                  checked={active}
                                  onChange={() => setActive(!active)}
                                />
                              </div>
                              <div className="label_text">
                                <Para14Tag
                                  weight={500}
                                  color="#A7ABAC">
                                  Active
                                </Para14Tag>
                              </div>
                              {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner_update">
                      <ButtonWrapper
                        auto={"auto"}
                        type="submit">{`${edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default LensesType;
