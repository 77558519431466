import {
  ADD_PRODUCT_BRAND_SUCCESS,
  DELETE_PRODUCT_BRAND_SUCCESS,
  GET_ALL_PRODUCT_BRAND_FAILURE,
  GET_ALL_PRODUCT_BRAND_REQUEST,
  GET_ALL_PRODUCT_BRAND_SUCCESS,
  UPDATE_PRODUCT_BRAND_SUCCESS,
} from "../constants/brand";

const initialState = {
  brandList: [],
  loading: false,
  error: null,
};

export const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_BRAND_SUCCESS: {
      return {
        ...state,
        brandList: [...state.brandList, action.payload],
      };
    }
    case GET_ALL_PRODUCT_BRAND_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ALL_PRODUCT_BRAND_SUCCESS:
      return {
        ...state,
        brandList: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCT_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case DELETE_PRODUCT_BRAND_SUCCESS: {
      return {
        ...state,
        brandList: state.brandList.filter(
          (brand) => brand.id !== action.payload
        ),
      };
    }

    case UPDATE_PRODUCT_BRAND_SUCCESS: {
      return {
        ...state,
        brandList: state.brandList.map((brand) => {
          if (brand.id === action.payload.id) {
            return action.payload;
          }
          return brand;
        }),
      };
    }
    default:
      return state;
  }
};
