import React from "react";
import RouterLink from "./routerLink/RouterLink";
import { GlobalStyled } from "./assets/css/globalStyled";
import "./assets/css/index";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./helper/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Auth } from "./helper/Auth";

const queryClient = new QueryClient();

const App = () => {

  React.useEffect(() => {
    const delayedEffect = setTimeout(
      () => {
        // Your code to be executed after 10 hours
        Auth?.userClear();
        // Reload the window after 10 hours
        window.location.reload(true);
      },
      10 * 60 * 60 * 1000
    ); // 10 hours

    // Clear the timeout if the component unmounts or if you want to reset the timer
    return () => clearTimeout(delayedEffect);
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  return (
    <>
      <GlobalStyled />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <RouterLink />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </AuthProvider>
      <Toaster />
    </>
  );
};

export default App;
