//------------------------------------------------------------------//
// PRODUCT CONSTANTS //
//------------------------------------------------------------------//
// ADD PRODUCT CONSTANTS
export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export const GET_ALL_PRODUCT_REQUEST = 'GET_ALL_PRODUCT_REQUEST';
export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS';
export const GET_ALL_PRODUCT_FAILURE = 'GET_ALL_PRODUCT_FAILURE';

export const GET_SINGLE_PRODUCT_REQUEST = 'GET_SINGLE_PRODUCT_REQUEST';
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS';
export const GET_SINGLE_PRODUCT_FAILURE = 'GET_SINGLE_PRODUCT_FAILURE';

export const PUT_PRODUCT_REQUEST = 'PUT_PRODUCT_REQUEST';
export const PUT_PRODUCT_SUCCESS = 'PUT_PRODUCT_SUCCESS';
export const PUT_PRODUCT_FAILURE = 'PUT_PRODUCT_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';
