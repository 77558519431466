/* The code snippet defines a JavaScript object called `routesConfig` which contains various paths for
different pages or functionalities of a website. */
const homePath = "/";
const id = homePath + ":id";

// routesConfig
// SEO configuration
export const routesConfig = {
  // homepage
  homePath: homePath,

  // product
  addProduct: homePath + "add-product",
  editProduct: homePath + "edit-product",
  productBrand: homePath + "product-brand",
  productCategory: homePath + "product-category",
  products: homePath + "products",
  productDetails: homePath + "product-details",

  // brand
  addBrand: homePath + "add-brand",
  getBrand: homePath + "brands",

  // categories
  addCategories: homePath + "add-categories",
  categories: homePath + "manage",

  // Frame Types
  addFrameTypes: homePath + "add-frame-types",
  getFrameTypes: homePath + "frame-types",

  // Frame Shape
  addFrameShape: homePath + "add-frame-shape",
  getFrameShape: homePath + "frame-shapes",

  // Frame Type
  addFaceTypes: homePath + "add-face-types",
  getFaceTypes: homePath + "face-types",

  // Frame Material
  addFrameMaterial: homePath + "add-frame-material",
  getFrameMaterial: homePath + "frame-material",

  // Frame Material
  addFrameSize: homePath + "add-frame-size",
  getFrameSize: homePath + "frame-size",

  // accessories
  accessories: homePath + "accessories",

  // blogs
  blogs: homePath + "blogs",
  addBlog: homePath + "add-blog",
  editBlog: homePath + "edit-blog",

  // colors
  colors: homePath + "colors",

  // authentication configuration
  login: homePath + "login",
  forgot: homePath + "forgot",
  verifyEmail: homePath + "verify-email",
  otpVerification: homePath + "otp-verification",
  // resetPassword: homePath + "reset-password",

  // order
  order: homePath + "orders",
  orderDetails: homePath + "order-details",
  orderDetailsId: homePath + "order-details" + id,

  // promo code
  promoCode: homePath + "promo-code",

  // nwe product form
  newForm: homePath + "new-form",

  // lenses
  lenses: homePath + "lenses",

  // single vision type
  singleVisionTypes: homePath + "single-vision-types",

  // lenses type
  lensesTypes: homePath + "lenses-types",

  // material type
  materialTypes: homePath + "material-types",

  // banner
  banner: homePath + "banner",

  // coupon
  coupon: homePath + "coupon",

  // wear by trend
  wearTheTrend: homePath + "wear-the-trend",

  // trending search
  trendingSearch: homePath + "trending-search",

  // news letter
  newsLetter: homePath + "news-letter",

  referalCode: homePath + "referal-code",

  // error
  error: "*",
};
