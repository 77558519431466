import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import profile from "../../assets/images/user.png";
import { routesConfig } from "../../helper/config/routes.config";
import { Auth } from "../../helper/Auth";
import { TbBrandBlogger } from "react-icons/tb";
import { BsBox, BsMinecartLoaded } from "react-icons/bs";
import { HiOutlineLogout, HiTrendingUp } from "react-icons/hi";
import { CgColorPicker } from "react-icons/cg";
import { FiSettings, FiSearch } from "react-icons/fi";
import { FaEye } from "react-icons/fa";
import { MdDiscount } from "react-icons/md";
import { GiSpectacleLenses, GiGlassBall, GiKnightBanner, GiRolledCloth } from "react-icons/gi";
import { SiFuturelearn, SiMaterialdesign } from "react-icons/si";
import { SlEnvolopeLetter } from "react-icons/sl";
import { RiCoupon2Line } from "react-icons/ri";
import { SuccessToast } from "../../helper/Toast";

const Item = ({ title, to, icon, selected, setSelected, logout }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const handleUserLogout = async () => {
  //   Auth?.userLogout();
  //   navigate(routesConfig?.homePath);
  // };
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}>
      {/* {logout ? (
        <Typography onClick={handleUserLogout}>Logout</Typography>
      ) : ( */}
      <>
        <Typography>{title}</Typography> <Link to={to} />
      </>
      {/* )} */}
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const navigate = useNavigate();

  const handleUserLogout = () => {
    Auth?.userLogout();
    // window.location.reload();
    navigate(routesConfig?.login);
    SuccessToast("You have successfully logged out of the application");
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}>
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}>
                  Optics Haven
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={profile}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}>
                  Henry Obazee
                </Typography>
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}>
                  Optics Haven Admin
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Products"
              to={routesConfig?.products}
              icon={<BsMinecartLoaded />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Order Information"
              to={routesConfig?.order}
              icon={<BsBox />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Manage"
              to={routesConfig?.categories}
              icon={<FiSettings />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Invoices Balances"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Accessories"
              to={routesConfig?.accessories}
              icon={<GiRolledCloth />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Blogs"
              to={routesConfig?.blogs}
              icon={<TbBrandBlogger />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Colors"
              to={routesConfig?.colors}
              icon={<CgColorPicker />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Promo Code"
              to={routesConfig?.promoCode}
              icon={<MdDiscount />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Lenses"
              to={routesConfig?.lenses}
              icon={<GiSpectacleLenses />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Single Vision Types"
              to={routesConfig?.singleVisionTypes}
              icon={<FaEye />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Lenses Types"
              to={routesConfig?.lensesTypes}
              icon={<GiGlassBall />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Material Types"
              to={routesConfig?.materialTypes}
              icon={<SiMaterialdesign />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Banner"
              to={routesConfig?.banner}
              icon={<GiKnightBanner />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Coupon"
              to={routesConfig?.coupon}
              icon={<RiCoupon2Line />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Wear The Trend"
              to={routesConfig?.wearTheTrend}
              icon={<HiTrendingUp />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Trending Search"
              to={routesConfig?.trendingSearch}
              icon={<FiSearch />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="News Letter"
              to={routesConfig?.newsLetter}
              icon={<SlEnvolopeLetter />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Referal Code"
              to={routesConfig?.referalCode}
              icon={<SiFuturelearn  />}
              selected={selected}
              setSelected={setSelected}
            />
            <br />
            <MenuItem
              // active={selected === "Logout"}
              style={{
                color: colors.grey[100],
              }}
              onClick={handleUserLogout}
              // setSelected("Logout");
              icon={<HiOutlineLogout />}>
              <Typography>Logout</Typography>
            </MenuItem>
            {/* <Item
              // logout={handleUserLogout}
              logout={true}
              title="Logout"
              to={"/"}
              icon={<HiOutlineLogout />}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
