import { Box, useTheme } from "@mui/system";
import React from "react";
import { tokens } from "../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Table = ({ rows, columns }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontFamily: "Poppins",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "100px !important",
            minHeight: "100px !important",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none !important",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#219EBC",
            borderBottom: "none",
            color: "#FFFFFF",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#219EBC",
            color: "#FFFFFF",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-root": {
            color: "#FFFFFF",
          },
        }}
      >
        <DataGrid
          editable={true}
          checkboxSelection
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </>
  );
};

export default Table;
