import React from 'react';
import { Box } from '@mui/material';
import Header from '../../components/Header';
import { ApiDeleteBlog, ApiGetAllBlog } from '../../helper/API/APICall';
import Loader from '../../components/Loader';
import EditIcon from '@mui/icons-material/Edit';
import Table from '../../components/Table';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '../../helper/config/routes.config';
import { ButtonWrapper, MainPadding } from '../../assets/css/styled';
import moment from 'moment';
import { ErrorToast, SuccessToast } from '../../helper/Toast';

const Products = () => {
  const [allBlogData, setAllBlogData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleteTriggered, setIsDeleteTriggered] = React.useState(false);

  const navigate = useNavigate();

  const allBlogs = async () => {
    setIsLoading(true);
    try {
      const data = [];

      setIsLoading(true);
      const response = await ApiGetAllBlog();

      response?.data.map((x) => {
        data.push({
          id: x?._id,
          image: x?.image,
          title: x?.title,
          author: x?.author,
          timestamp: moment(x?.timestamp).format('MMMM D, YYYY'),
        });
      });

      setAllBlogData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      field: 'image',
      headerName: 'Thumbnail',
      width: '100%',
      height: '100%',
      flex: 1,
      renderCell: (params) => (
        <img
          style={{ height: '100%', objectFit: 'cover', width: '100px' }}
          src={params.value}
          alt={`Product Thumbnail ${params.row.id}`}
        />
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'author',
      headerName: 'Author',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'timestamp',
      headerName: 'Date (Created)',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            color="inherit"
            onClick={() => navigate(`${routesConfig?.editBlog}/${id}`)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  React.useEffect(() => {
    allBlogs();
  }, [isDeleteTriggered]);

  const handleDeleteClick = async (id) => {
    try {
      const response = await ApiDeleteBlog(id);
      if (response?.data?.message?.status === 200) {
        SuccessToast('Blog deleted successfully!');
        setIsDeleteTriggered(!isDeleteTriggered);
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
    } catch (error) {
      setIsLoading(!isLoading);
    }
  };

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header
            title="All Blogs"
            subtitle="View The All Blogs"
          />
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={() => navigate(routesConfig?.addBlog)}>
              Add Blog
            </ButtonWrapper>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            rows={allBlogData}
            columns={columns}
          />
        )}
      </Box>
    </MainPadding>
  );
};

export default Products;
