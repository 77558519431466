import { AdminAPI, AdminCommonAPI } from "../../helper/API/API.base";
import {
  APIHeaderCors,
  getFormDataHeaders,
  getHeaders,
} from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  CREATE_PRODUCT_CATEGORIES_SUCCESS,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  LIST_ALL_CATEGORIES_FAILURE,
  LIST_ALL_CATEGORIES_REQUEST,
  LIST_ALL_CATEGORIES_SUCCESS,
  UPDATE_PRODUCT_CATEGORIES_SUCCESS,
} from "../constants/category";

export const listAllCategories = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_CATEGORIES_REQUEST });

      // API
      const response = await AdminCommonAPI.get("/categories", APIHeaderCors);

      const categoryList = [];
      response?.data?.map((x) => {
        categoryList.push({
          id: x._id,
          type: x.type,
          description: x.description,
          image: x.image,
          is_active: x.is_active,
        });
      });

      dispatch({
        type: LIST_ALL_CATEGORIES_SUCCESS,
        payload: categoryList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_CATEGORIES_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateCategory = (values, categoryId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/categories/${categoryId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_PRODUCT_CATEGORIES_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product Categories updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteCategory = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const categoryId = values.id;

      // API
      const response = await AdminAPI.delete(`/categories/${categoryId}`, {
        headers,
      });

      dispatch({
        type: DELETE_PRODUCT_CATEGORY_SUCCESS,
        payload: categoryId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product Categories deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createCategory = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/categories`, body, { headers });

      dispatch({
        type: CREATE_PRODUCT_CATEGORIES_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product Categories added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
