import React from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../components/Table";
import { wearByTrendSchema } from "../../helper/yup.schema";
import {
  ButtonWrapper,
  DashboardDetailsBorder,
  FormInputLabel,
  Head24Tag,
  MainPadding,
  Para14Tag,
  Para16Tag,
  Para20Tag,
} from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { createWearTheTrend, deleteWearTheTrend, listAllWearTheTrend, updateWearTheTrend } from "../../redux/actions/wearTheTrendAction";
import { UploadImagesIcon } from "../../components/typography/Icons";
import { CheckBoxCustom } from "../../components/typography/CheckBox";
import { ErrorToast } from "../../helper/Toast";
import { GenderData } from "../../data/data";

const WearByTrend = () => {
  const [formInitialValues, setFormInitialValues] = React.useState({
    title: "",
    gender: "",
  });
  const [selectedWearTheTrendId, setSelectedWearTheTrendId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [imageURL, setImageURL] = React.useState(null);
  const [imageFile, setImageFile] = React.useState(null);
  const [active, setActive] = React.useState(false);

  const dispatch = useDispatch();
  const { loading, wearTheTrendList } = useSelector((state) => state.wearTheTrends);

  React.useEffect(() => {
    dispatch(listAllWearTheTrend());
  }, [dispatch]);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteWearTheTrend({ id }));
  };

  const handleEditClick = (id) => () => {
    setSelectedWearTheTrendId(id);
    wearTheTrendList?.forEach((x) => {
      if (x.id === id) {
        const gender = x.gender;
        setFormInitialValues({
          title: x?.title,
          gender: gender,
        });
        setImageURL(x?.image);
        setActive(x?.is_active);
      }
    });
    setEdit(true);
    setOpen(true);
  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 120,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`WearByTrend ${params.row.id}`}
        />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params) => <div style={{ textTransform: "capitalize" }}>{params?.value?.charAt(0)?.toUpperCase() + params?.value?.slice(1)}</div>,
    },
    {
      field: "is_active",
      headerName: "Active",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddCategory = () => {
    setEdit(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormInitialValues({
      title: "",
      gender: "",
    });
    setEdit(false);
    setActive(false);
    setImageFile(null);
    setImageURL(null);
    setSelectedWearTheTrendId(null);
  };

  const handleSubmit = (values, { resetForm }) => {
    try {
      // Edit new wear by trend API
      if (edit) {
        const updateFormData = new FormData();
        imageFile && updateFormData.append("image", imageFile);
        updateFormData.append("title", values?.title);
        updateFormData.append("gender", values?.gender);
        updateFormData.append("is_active", active);

        dispatch(updateWearTheTrend(updateFormData, selectedWearTheTrendId, handleClose));

        return;
      }

      // Create new wear by trend API
      if (!imageFile) {
        ErrorToast("Please select an image!");
        return;
      }

      const newFormData = new FormData();
      newFormData.append("image", imageFile);
      newFormData.append("title", values?.title);
      newFormData.append("gender", values?.gender);
      newFormData.append("is_active", active);

      dispatch(createWearTheTrend(newFormData, handleClose));
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageURL(url);
    setImageFile(file);

    // Reset the input value to allow re-selecting the same file
    e.target.value = null;
  };

  const handleRemoveImage = () => {
    setImageURL(null);
    setImageFile(null);
  };

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header
            title="Wear The Trend"
            subtitle="View All The Wear The Trend"
          />
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddCategory}>
              Add New Wear The Trend
            </ButtonWrapper>
          </div>
        </div>
        <Table
          columns={columns}
          rows={wearTheTrendList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-position">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Wear The Trend`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="add_product_images_add_inner_div_main flex-col">
              <div className="form_inner_input_div_main">
                <FormInputLabel>Select Image</FormInputLabel>
              </div>

              <div className="technical_information_text_filed">
                <div>
                  <div>
                    <div className="border border-blue-300 rounded-md p-5 my-2">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="thumbnail_upload_image"
                        id="thumbnail_upload_image"
                        accept="image/png, image/jpeg"
                        onChange={handleFile}
                      />
                      <div className="frame_info_images_outer_inner_three_images_show">
                        <label htmlFor="thumbnail_upload_image">
                          {imageURL && (
                            <img
                              style={{ cursor: "pointer" }}
                              src={imageURL}
                              alt="img"
                              className="frame_info_image_inner_open_inner"
                            />
                          )}
                          <div className="frame_info_text_files_name_browse">
                            <UploadImagesIcon />
                            <Para20Tag color={"#000"}>
                              Drop your Files here Or.
                              <span style={{ color: "#219EBC" }}> Browse</span>
                            </Para20Tag>
                          </div>
                          <Para16Tag>You can only select up to one images.</Para16Tag>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame_info_image_inner_open_bottom_div_inner mt-3">
                  {imageURL && (
                    <DashboardDetailsBorder padding={"10px"}>
                      <div
                        style={{ gap: "40px" }}
                        className="frame_show_images_selected_file_inner">
                        <div className="frame_show_images_name_complete_done_div">
                          <img
                            src={imageURL}
                            alt="img"
                            className="frame_show_images_selected_file_inner_outer"
                          />
                        </div>
                        <div className="frame_info_images_inner_open_right_delete_icon_remove">
                          <button
                            className="frame_info_images_remove_icon_inner_delete"
                            onClick={() => handleRemoveImage()}>
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    </DashboardDetailsBorder>
                  )}
                </div>
              </div>

              <Formik
                initialValues={formInitialValues}
                validationSchema={wearByTrendSchema}
                onSubmit={handleSubmit}>
                {({ touched, errors, setFieldValue }) => (
                  <Form>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Title</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter title"
                            name="title"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.title && errors.title && <FormErrorMessage errors={errors.title} />}
                      </div>
                    </div>
                    <div className="">
                      <FormInputLabel>Gender</FormInputLabel>
                      <div className="flex w-[27.125rem] items-center ">
                        <Field
                          as="select"
                          name="gender"
                          // disabled={variant}
                          onChange={(e) => {
                            setFieldValue("gender", e.target.value);
                          }}>
                          <option value="">Select a Gender</option>
                          {!GenderData.length
                            ? "Loading..."
                            : GenderData?.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item.value}>
                                    {item?.gender}
                                  </option>
                                );
                              })}
                        </Field>
                      </div>
                      {touched.gender && errors.gender && <FormErrorMessage errors={errors.gender} />}
                    </div>
                    {/* Active checkbox */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      className="form_input_forget_text_main_pass_inner mt-4">
                      <div className="form_get_update_text_data_title">
                        <div
                          style={{ cursor: "pointer" }}
                          className="add_new_add_default_address">
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}>
                            <div
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              className="main_check_box">
                              <CheckBoxCustom
                                id="is_active"
                                name="is_active"
                                checked={active}
                                onChange={() => setActive(!active)}
                              />
                            </div>
                            <div className="label_text">
                              <Para14Tag
                                weight={500}
                                color="#A7ABAC">
                                Active
                              </Para14Tag>
                            </div>
                            {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner_update">
                      <ButtonWrapper
                        auto={"auto"}
                        type="submit">{`${loading ? "Loading..." : edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default WearByTrend;
