import {
  ADD_MATERIAL_TYPE_SUCCESS,
  LIST_ALL_MATERIAL_TYPES_REQUEST,
  LIST_ALL_MATERIAL_TYPES_SUCCESS,
  LIST_ALL_MATERIAL_TYPES_FAILURE,
  UPDATE_MATERIAL_TYPE_SUCCESS,
  CREATE_MATERIAL_TYPE_SUCCESS,
  DELETE_MATERIAL_TYPE_SUCCESS,
} from "../constants/materialType";

const initialState = {
  materialTypesList: [],
  loading: false,
  error: null,
};

let materialTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MATERIAL_TYPE_SUCCESS:
      return {
        ...state,
        materialTypesList: [...state.materialTypesList, action.payload],
      };

    case LIST_ALL_MATERIAL_TYPES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_MATERIAL_TYPES_SUCCESS:
      return {
        ...state,
        materialTypesList: action?.payload,
        loading: false,
      };

    case LIST_ALL_MATERIAL_TYPES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_MATERIAL_TYPE_SUCCESS: {
      const updatedMaterialType = action.payload.lensMaterialType;
      return {
        ...state,
        materialTypesList: state.materialTypesList.map((materialType) => {
          if (materialType.id === updatedMaterialType._id) {
            return {
              ...materialType,
              lens_material_type: updatedMaterialType.lens_material_type,
              description: updatedMaterialType.description,
              price: updatedMaterialType.price,
              is_active: updatedMaterialType.is_active,
            };
          }
          return materialType;
        }),
      };
    }

    case CREATE_MATERIAL_TYPE_SUCCESS: {
      const { lensMaterialType } = action.payload;
      const newMaterialType = {
        id: lensMaterialType._id,
        lens_material_type: lensMaterialType.lens_material_type,
        description: lensMaterialType.description,
        price: lensMaterialType.price,
        is_active: lensMaterialType.is_active,
      };
      return {
        ...state,
        materialTypesList: [...state.materialTypesList, newMaterialType],
      };
    }

    case DELETE_MATERIAL_TYPE_SUCCESS: {
      const lensMaterialTypeId = action.payload;
      return {
        ...state,
        materialTypesList: state.materialTypesList.filter((materialType) => materialType.id !== lensMaterialTypeId),
      };
    }

    default:
      return state;
  }
};

export default materialTypeReducer;
