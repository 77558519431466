import { DEVELOPMENT_HOSTNAME, PRODUCTION_HOSTNAME } from "../../utils/config";

/* This code is determining the API configuration based on the current hostname and port of the
website. */
const hostName = window.location.hostname;
const port = window.location.port;
let isLocalApi = port >= 3000;

// API's
const PRODUCTION_API = "https://opticshaven.online";
const DEVELOPMENT_API = "https://apidev.opticshaven.online";
const LOCAL_API = "http://localhost:3000";

let API_CONFIG = ''

if (hostName === 'localhost' && isLocalApi) {
  API_CONFIG = LOCAL_API
} else if (hostName === PRODUCTION_HOSTNAME) {
  API_CONFIG = PRODUCTION_API
} else if (hostName === DEVELOPMENT_HOSTNAME) {
  API_CONFIG = DEVELOPMENT_API
}

export { API_CONFIG }