import React from "react";
import "./authSlider.css";
import { Link } from "react-router-dom";
import { Head32Tag, Head48Tag, Para16Tag } from "../../../assets/css/styled";
import auth1 from "../../../assets/images/auth1.png";
import auth2 from "../../../assets/images/auth2.png";
import auth3 from "../../../assets/images/auth3.png";

const AuthSlider = () => {
  return (
    <>
      <div className="auth_frames">
        <Link to={"/"}>
          <div className="auth_frames_inner_logo_main_div"></div>
        </Link>
        <div
          style={{ flexDirection: "column" }}
          className="auth_slider_main_div"
        >
          <div
            style={{ display: "flex" }}
            className="auth_slider_main_images_top"
          >
            <img
              src={auth1}
              alt="img"
              className="auth_slider_main_images_inner"
            />
            <img
              src={auth2}
              alt="img"
              className="auth_slider_main_images_inner"
            />
            <img
              src={auth3}
              alt="img"
              className="auth_slider_main_images_inner"
            />
          </div>
          <div className="auth_slider_para_inner_text_stylish">
            <div className="auth_slider_para_inner_text_stylish_inner_div">
              <Head48Tag
                style={{ marginBottom: "10px" }}
                weight={600}
                color="#FFFFFF"
              >
                Log in start buy eyes wear
              </Head48Tag>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="auth_slider_para_inner_text_fashion_inner_div"
            >
              <Para16Tag color="#FFFFFF">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Para16Tag>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthSlider;
