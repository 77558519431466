import { AdminAPI } from "../../helper/API/API.base";
import { getFormDataHeaders, getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  LOADING_TRUE,
  LIST_ALL_BANNERS_REQUEST,
  LIST_ALL_BANNERS_SUCCESS,
  LIST_ALL_BANNERS_FAILURE,
  UPDATE_BANNER_SUCCESS,
  DELETE_BANNER_SUCCESS,
  CREATE_BANNER_SUCCESS,
  LOADING_FALSE,
} from "../constants/banner";

export const listAllBanners = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_BANNERS_REQUEST });

      // header
      const headers = await getHeaders();

      // API
      const response = await AdminAPI.get("/home-banners", { headers });

      const lensesList = [];
      response?.data?.forEach((x) => {
        lensesList.push({
          id: x?._id,
          banner_image: x?.banner_image,
          total_images: x?.banner_image?.length,
          position: x?.position,
          page: x?.page,
          is_active: x?.is_active,
          created_at: x?.created_at,
        });
      });

      dispatch({
        type: LIST_ALL_BANNERS_SUCCESS,
        payload: lensesList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_BANNERS_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateBanner = (values, lenseId, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/home-banners/${lenseId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_BANNER_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Banner updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      handleClose();
      resolve(response);
    } catch (error) {

      dispatch({
        type: LOADING_FALSE,
      });

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteBanner = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const bannerId = values.id;

      // API
      const response = await AdminAPI.delete(`/home-banners/${bannerId}`, { headers });

      dispatch({
        type: DELETE_BANNER_SUCCESS,
        payload: bannerId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Banner deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createBanner = (values, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/home-banners`, body, { headers });

      dispatch({
        type: CREATE_BANNER_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Banner added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      handleClose();
      resolve(response);
    } catch (error) {

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
