import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import Header from "../../components/Header";
import {
  ApiDeleteSingleProductById,
  ApiGetAllProductFrameDetail,
} from "../../helper/API/APICall";
import Loader from "../../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import Table from "../../components/Table";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../helper/config/routes.config";
import {
  ButtonWrapper,
  Head24Tag,
  MainPadding,
  Para20Tag,
} from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";

const Products = () => {
  const [productData, setProductData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleteTriggered, setIsDeleteTriggered] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [removeId, setRemoveId] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const allProducts = async () => {
    setIsLoading(true);
    try {
      const data = [];
      setIsLoading(true);
      const response = await ApiGetAllProductFrameDetail();
      response?.data?.forEach((item) => {
        const productData = item?.product_frame;
        const faceData = productData?.face_id?.map((face) => {
          return face?.face_type;
        });
        const faces = faceData?.join(", ");
        const genderData = productData?.gender;
        const gender = genderData?.join(", ");
        data.push({
          id: productData._id,
          product_name: productData?.product_name,
          product_price: productData?.price,
          category: productData?.category_id?.type,
          model_no: productData?.model_no,
          face_type: faces,
          frame_type: productData?.frame_type_id?.frame_type,
          frame_shape: productData?.frame_shape_id?.frame_shape,
          frame_material: productData?.frame_material_id?.frame_material,
          gender: gender,
          thumbnail_picture: item?.frames?.[0]?.thumbnail_picture,
          frameSize: item?.frames?.map((y) => {
            return y;
          }),
        });
      });
      setProductData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    allProducts();
  }, [isDeleteTriggered]);

  const columns = [
    {
      field: "thumbnail_picture",
      headerName: "Thumbnail Picture",
      width: "100%",
      height: "100%",
      flex: 1,
      renderCell: (params) => (
        <img
          style={{ height: "100%", objectFit: "cover", width: "100px" }}
          src={params.value}
          alt={`Product Thumbnail ${params.row.id}`}
        />
      ),
    },
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "product_price",
      headerName: "Price",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "model_no",
      headerName: "Model No",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "face_type",
      headerName: "Face Type",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "frame_type",
      headerName: "Frame Type",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "frame_shape",
      headerName: "Frame Shape",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "frame_material",
      headerName: "Frame Material",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      headerName: "Edit",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            color="inherit"
            onClick={() => navigate(`${routesConfig?.editProduct}/${id}`)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            // onClick={() => handleDeleteClick(id)}
            onClick={() => {
              handleOpen();
              setRemoveId(id);
            }}
            color="inherit"
          />,
        ];
      },
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   headerAlign: "center",
    //   flex: 1,
    //   align: "center",
    //   renderCell: ({ id }) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<EditIcon />}
    //         label="Edit"
    //         color="inherit"
    //         onClick={() => navigate(`${routesConfig?.editProduct}/${id}`)}
    //       />,
    //       <GridActionsCellItem
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         onClick={() => handleDeleteClick(id)}
    //         color="inherit"
    //       />,
    //     ];
    //   },
    // },
  ];

  const handleDeleteClick = async () => {
    try {
      const response = await ApiDeleteSingleProductById(removeId);
      if (response?.data?.message?.status === 200) {
        setIsDeleteTriggered(!isDeleteTriggered);
        handleClose();
      }
    } catch (error) {
      setIsLoading(!isLoading);
    }
  };

  return (
    <MainPadding p_bottom={20} p_top={20}>
      <Box m="20px" mb={0} mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header title="All Products" subtitle="View All Products" />
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={() => navigate(routesConfig?.addProduct)}
            >
              Add Product
            </ButtonWrapper>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <Table rows={productData} columns={columns} />
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle}>
          <Head24Tag>Conformation</Head24Tag>
          <Para20Tag>Are you sure you want to remove this item?</Para20Tag>

          <div className="flex items-center justify-center gap-4 pt-8">
            <ButtonWrapper
              border_color={"#DEE2E4"}
              color={"#5F6364"}
              bg_color={"#FFF"}
              weight={600}
              hover_color={"#2E3233"}
              hover_bg={"FFF"}
              onClick={handleClose}
            >
              Cancel
            </ButtonWrapper>
            <ButtonWrapper onClick={handleDeleteClick}>
              Yes, remove it
            </ButtonWrapper>
          </div>
        </Box>
      </Modal>
    </MainPadding>
  );
};

export default Products;
