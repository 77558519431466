import { AdminAPI } from "../../helper/API/API.base";
import { getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import { LIST_ALL_NEWS_LETTERS_REQUEST, LIST_ALL_NEWS_LETTERS_SUCCESS, LIST_ALL_NEWS_LETTERS_FAILURE } from "../constants/newsLetter";

export const listAllNewsLetter = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_NEWS_LETTERS_REQUEST });

      // header
      const headers = await getHeaders();

      // API
      const response = await AdminAPI.get("/newsletters", { headers });

      const newsLetterList = [];
      response?.data?.forEach((x) => {
        newsLetterList.push({
          id: x?._id,
          email: x?.email,
          created_at: x?.created_at,
        });
      });

      dispatch({
        type: LIST_ALL_NEWS_LETTERS_SUCCESS,
        payload: newsLetterList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_NEWS_LETTERS_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
