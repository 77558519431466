/**
 * The `LazyLoader` function is a higher-order component that wraps a component in a `Suspense`
 * component to enable lazy loading.
 * @param Component - The Component parameter is the component that you want to lazy load. It can be
 * any valid React component.
 */

// import { CircularProgress } from "@mui/material";
import { Suspense } from "react";

export const LazyLoader = (Component) => (props) =>
  (
    <Suspense fallback={""}>
      <Component {...props} />
    </Suspense>
  );
