import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "@mui/material";
import Loader from "../../components/Loader";
import {
  addBrand,
  deleteBrand,
  listBrands,
  updateBrand,    
} from "../../redux/actions/brandAction";
import { modelStyle } from "../../assets/css/muiStyled";
import Table from "../../components/Table";
import { Field, Form, Formik } from "formik";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import {
  ButtonWrapper,
  FormInputLabel,
  Head24Tag,
  MainPadding,
} from "../../assets/css/styled";
import FormErrorMessage from "../../components/FormErrorMessage";
import { brandSchema } from "../../helper/yup.schema";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GridActionsCellItem } from "@mui/x-data-grid";

const Brands = () => {
  const [formInitialValues, setFormInitialValues] = useState({
    brand_name: "",
    is_activated: true,
  });

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { loading, brandList } = useSelector((state) => state?.brand);

  useEffect(() => {
    dispatch(listBrands());
  }, []);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteBrand({ id }));
  };

  const handleEditClick = (id) => () => {
    setFormInitialValues(brandList.find((category) => category.id === id));
    setOpen(true);
    setEdit(true);
  };

  const handleClose = () => setOpen(false);

  const handleAddBrand = () => {
    setEdit(false);
    setOpen(true);
  };

  const columns = [
    {
      field: "brand_name",
      headerName: "Brand Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "is_activated",
      headerName: "Activated Brand",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleSubmit = (values, { resetForm }) => {
    try {
      if (edit) {
        dispatch(updateBrand(values));
        setOpen(false);
        setEdit(false);
        setFormInitialValues({ brand_name: "", is_activated: true });
        return;
      }
      dispatch(addBrand(values));
      setOpen(false);
      resetForm();
    } catch (error) {
      throw new Error(error)
    }
  };

  return (
    <>
      <MainPadding p_bottom={20} p_top={20}>
        <Box m="20px" mb={0} mt={0}>
          <div className="flex w-full justify-between items-center">
            <Header title="Brands" subtitle="View All The Brands" />
            <div className="flex justify-end">
              <ButtonWrapper variant="outlined" onClick={handleAddBrand}>
                Add Brand
              </ButtonWrapper>
            </div>
          </div>
          {loading ? <Loader /> : <Table rows={brandList} columns={columns} />}
        </Box>
      </MainPadding>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle}>
          <div className="category_modal_add_form_main_div">
            <div className="payment_model_inner_head_main">
              <Head24Tag color="#0E1213" weight="600">
                {`${edit ? "Update" : "Add"} Brand`}
              </Head24Tag>
            </div>
            <div
              className="network_insurance_modal_close"
              onClick={handleClose}
            >
              <ModalCloseButton />
            </div>
          </div>

          <div className="category_add_frame_form">
            <Formik
              initialValues={formInitialValues}
              validationSchema={brandSchema}
              onSubmit={handleSubmit}
            >
              {({ touched, errors }) => (
                <Form>
                  <div className="technical_information_text_filed">
                    <div className="form_inner_input_div_main">
                      <FormInputLabel>Brand Name</FormInputLabel>
                      <div className="form_input_main">
                        <Field
                          type="text"
                          placeholder="Enter Brand Name"
                          name="brand_name"
                          className="from_input_tag"
                        />
                      </div>
                      {touched.brand_name && errors.brand_name && (
                        <FormErrorMessage errors={errors.brand_name} />
                      )}
                    </div>
                  </div>
                  <div className="technical_information_text_filed">
                    <FormInputLabel>Brand is active?</FormInputLabel>
                    <Field
                      as="select"
                      placeholder="Enter description"
                      name="is_activated"
                    >
                      <option value="">Select a Brand is status</option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Field>
                    {touched.is_activated && errors.is_activated && (
                      <FormErrorMessage errors={errors.is_activated} />
                    )}
                  </div>
                  <div className="form_sign_in_btn_main_inner_update">
                    <ButtonWrapper auto={"auto"}>{`${
                      edit ? "Update" : "Create"
                    }`}</ButtonWrapper>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Brands;
