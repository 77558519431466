import { AdminAPI } from "../../helper/API/API.base";
import { getFormDataHeaders, getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  LOADING_TRUE,
  LIST_ALL_COUPONS_REQUEST,
  LIST_ALL_COUPONS_SUCCESS,
  LIST_ALL_COUPONS_FAILURE,
  UPDATE_COUPON_SUCCESS,
  DELETE_COUPON_SUCCESS,
  CREATE_COUPON_SUCCESS,
  LOADING_FALSE,
} from "../constants/coupon";

export const listAllCoupon = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_COUPONS_REQUEST });

      // header
      const headers = await getHeaders();

      // API
      const response = await AdminAPI.get("/discount", { headers });

      const couponList = [];
      response?.data?.forEach((x) => {
        couponList.push({
          id: x?._id,
          title: x?.title,
          image: x?.image,
          description: x?.description,
          PromoCode_id: x?.PromoCode_id,
          is_active: x?.is_active,
          created_at: x?.created_at,
          update_at: x?.update_at,
          is_deleted: x?.is_deleted,
        });
      });

      dispatch({
        type: LIST_ALL_COUPONS_SUCCESS,
        payload: couponList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_COUPONS_FAILURE,
        payload: error?.message,
      });
      
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateCoupon = (values, couponId, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/discount/${couponId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_COUPON_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Coupon updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      handleClose();
      resolve(response);
    } catch (error) {

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteCoupon = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const couponId = values.id;

      // API
      const response = await AdminAPI.delete(`/discount/${couponId}`, { headers });

      dispatch({
        type: DELETE_COUPON_SUCCESS,
        payload: couponId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Coupon deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createCoupon = (values, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/discount`, body, { headers });

      dispatch({
        type: CREATE_COUPON_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Coupon added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      handleClose();
      resolve(response);
    } catch (error) {

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
