import React from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../components/Table";
import { bannerSchema } from "../../helper/yup.schema";
import {
  ButtonWrapper,
  DashboardDetailsBorder,
  FormInputLabel,
  Head24Tag,
  MainPadding,
  Para14Tag,
  Para18Tag,
  Para20Tag,
} from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { createBanner, deleteBanner, listAllBanners, updateBanner } from "../../redux/actions/bannerAction";
import { DeleteIcons, UploadImagesIcon } from "../../components/typography/Icons";
import { ErrorToast } from "../../helper/Toast";
import uploadImages from "../../assets/images/uploadImages.png";

const Banner = () => {
  const [formInitialValues, setFormInitialValues] = React.useState({
    position: "",
    page: "",
  });
  const [selectedBannerId, setSelectedBannerId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [selectedImages, setSelectedImages] = React.useState([]);
  const [selectedImagesSet, setSelectedImagesSet] = React.useState([]);

  const dispatch = useDispatch();
  const { loading, bannerList } = useSelector((state) => state.banners);

  React.useEffect(() => {
    dispatch(listAllBanners());
  }, [dispatch]);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteBanner({ id }));
  };

  // let removeID;

  const handleEditClick = (id) => () => {
    setSelectedBannerId(id);
    bannerList.forEach((x) => {
      if (x.id === id) {
        setFormInitialValues({
          position: x?.position,
          page: x?.page,
        });
        setSelectedImages(x?.banner_image);
      }
    });
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "banner_image",
      headerName: "Banner Image",
      width: 120,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={params.value?.[0]}
          alt={`Banner ${params.row.id}`}
        />
      ),
    },
    {
      field: "total_images",
      headerName: "Total Images",
      width: 120,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "page",
      headerName: "Page",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "position",
      headerName: "Position",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddCategory = () => {
    setOpen(true);
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setFormInitialValues({
      position: "",
      page: "",
    });
    setSelectedImages([]);
    setSelectedImagesSet([]);
  };

  const handleSubmit = (values, { resetForm }) => {
    try {
      if (edit) {
        const updateFormData = new FormData();
        selectedImagesSet.length > 0 &&
          selectedImagesSet?.forEach((image) => {
            updateFormData.append("banner_image", image);
          });
        updateFormData.append("position", values?.position);
        updateFormData.append("page", values?.page);
        dispatch(updateBanner(updateFormData, selectedBannerId, handleClose));
        return;
      }

      if (selectedImagesSet.length < 1) {
        ErrorToast("Please select at least 1 image!");
        return;
      }
      const newFormData = new FormData();
      selectedImagesSet.length > 0 &&
        selectedImagesSet?.forEach((image) => {
          newFormData.append("banner_image", image);
        });
      newFormData.append("position", values?.position);
      newFormData.append("page", values?.page);

      dispatch(createBanner(newFormData, handleClose));
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleImageChange = (event) => {
    const files = event?.target?.files;
    const newImages = Array.from(files).map((file) => ({
      url: URL.createObjectURL(file),
      name: file?.name,
      size: file?.size,
    }));

    // Add new images to the selectedImages array
    setSelectedImages([...selectedImages, ...newImages]);
    setSelectedImagesSet([...selectedImagesSet, ...files]);

    event.target.value = null;
  };

  const handleRemoveImage = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  const handleRemoveImageSet = (index) => {
    const updatedImagesSet = selectedImagesSet.filter((_, i) => i !== index);
    setSelectedImagesSet(updatedImagesSet);
  };

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header
            title="Banners"
            subtitle="View All The Banners"
          />
          {/* <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddCategory}>
              Add New Banner
            </ButtonWrapper>
          </div> */}
        </div>
        <Table
          columns={columns}
          rows={bannerList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-position">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Banner`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="add_product_images_add_inner_div_main flex-col">
              <div className="add_product_images_add_inner_div_main flex-col">
                <FormInputLabel>Select Banner Images</FormInputLabel>
                <div className="add_product_images_add_inner_div_both_same">
                  <div className="frame_information_left_images_open_browse_main !mr-0">
                    <div className="frame_info_image_inner_open">
                      <input
                        style={{
                          display: "none",
                        }}
                        type="file"
                        name="upload_image"
                        id="upload_image"
                        accept="image/png, image/jpeg"
                        multiple
                        onChange={(e) => handleImageChange(e)}
                      />
                      <div className="frame_info_images_outer_inner_three_images_show">
                        <label htmlFor="upload_image">
                          <img
                            style={{
                              cursor: "pointer",
                            }}
                            src={uploadImages}
                            alt="img"
                            className="frame_info_image_inner_open_inner"
                          />
                          <div className="frame_info_text_files_name_browse">
                            <UploadImagesIcon />
                            <Para20Tag
                              weight={400}
                              color={"#000"}>
                              Drop your Files here Or.
                              <span
                                style={{
                                  color: "#219EBC",
                                }}>
                                {" "}
                                Browse
                              </span>
                            </Para20Tag>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedImages.length > 0 && (
                  <div className="frame_info_image_inner_open_bottom_div_delete_icon  mt-5">
                    <div className="frame_info_image_inner_open_bottom_div_inner">
                      {selectedImages?.map((image, index) => (
                        <DashboardDetailsBorder padding={"10px"}>
                          <div
                            className="frame_show_images_selected_file_inner"
                            key={index}>
                            <div className="frame_show_images_name_complete_done_div">
                              <img
                                src={image?.url || image}
                                alt="img"
                                className="frame_show_images_selected_file_inner_outer"
                              />
                              {image?.name && (
                                <div className="add_product_size_images">
                                  <Para18Tag>{image?.name}</Para18Tag>
                                  <Para14Tag weight={600}>{`${(image?.size / 1024).toFixed(0)} KB`} </Para14Tag>
                                </div>
                              )}
                            </div>

                            <div className="frame_info_images_inner_open_right_delete_icon_remove">
                              <button
                                className="frame_info_images_remove_icon_inner_delete"
                                type="button"
                                onClick={() => {
                                  handleRemoveImage(index);
                                  image?.url && handleRemoveImageSet(index);
                                  handleDeleteClick(image);
                                }}>
                                <DeleteIcons />
                              </button>
                            </div>
                          </div>
                        </DashboardDetailsBorder>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <Formik
                initialValues={formInitialValues}
                validationSchema={bannerSchema}
                onSubmit={handleSubmit}>
                {({ touched, errors }) => (
                  <Form>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Banner Position</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Banner Position"
                            name="position"
                            className="from_input_tag"
                            readOnly
                          />
                        </div>
                        {touched.position && errors.position && <FormErrorMessage errors={errors.position} />}
                      </div>
                    </div>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Banner Page</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Banner Position"
                            name="page"
                            className="from_input_tag"
                            readOnly
                          />
                        </div>
                        {touched.page && errors.page && <FormErrorMessage errors={errors.page} />}
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner_update">
                      <ButtonWrapper
                        auto={"auto"}
                        type="submit">{`${loading ? "Loading..." : edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default Banner;
