import { LIST_ALL_NEWS_LETTERS_REQUEST, LIST_ALL_NEWS_LETTERS_SUCCESS, LIST_ALL_NEWS_LETTERS_FAILURE } from "../constants/newsLetter";

const initialState = {
  newsLetterList: [],
  loading: false,
  error: null,
};

let newsLetterReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ALL_NEWS_LETTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_NEWS_LETTERS_SUCCESS:
      return {
        ...state,
        newsLetterList: action?.payload,
        loading: false,
      };

    case LIST_ALL_NEWS_LETTERS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default newsLetterReducer;
