import { Button, styled } from "@mui/material";
import React, { useState } from "react";
import Colors from "../colors/Colors";

const tabs = [
  { key: "frame_color", label: "Frame Color" },
  { key: "lense_color", label: "Lense Color" },
];

const ColorWrapper = () => {
  const [activeTab, setActiveTab] = useState("frame_color");

  const handleClick = (tabName) => {
    setActiveTab(tabName);
  };

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    // borderRadius: "14px",
    lineHeight: 1.5,
    backgroundColor: "#219ebc",
    color: "white",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      backgroundColor: "#178AA6",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#178AA6",
      borderColor: "none",
    },
    "&:focus": {
      borderColor: "none",
    },
  });

  return (
    <>
      <div className="flex m-5 justify-start gap-4">
        {tabs.map((tab) => (
          <BootstrapButton
            key={tab.key}
            variant={activeTab === tab.key ? "contained" : "text"}
            onClick={() => handleClick(tab.key)}>
            {tab.label}
          </BootstrapButton>
        ))}
      </div>
      {activeTab === "lense_color" ? <Colors activeTab={"lense_color"} /> : <Colors activeTab={"frame_color"} />}
    </>
  );
};

export default ColorWrapper;
