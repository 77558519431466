import { AdminAPI } from "../../helper/API/API.base";
import { getFormDataHeaders, getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  LOADING_TRUE,
  LIST_ALL_WEAR_THE_TREND_REQUEST,
  LIST_ALL_WEAR_THE_TREND_SUCCESS,
  LIST_ALL_WEAR_THE_TREND_FAILURE,
  UPDATE_WEAR_THE_TREND_SUCCESS,
  DELETE_WEAR_THE_TREND_SUCCESS,
  CREATE_WEAR_THE_TREND_SUCCESS,
  LOADING_FALSE,
} from "../constants/wearByTrend";

export const listAllWearTheTrend = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_WEAR_THE_TREND_REQUEST });

      // header
      const headers = await getHeaders();

      // API
      const response = await AdminAPI.get("/wear-the-trend", { headers });

      const wearTheTrendList = [];
      response?.data?.forEach((x) => {
        wearTheTrendList.push({
          id: x?._id,
          image: x?.image,
          title: x?.title,
          gender: x?.gender,
          is_active: x?.is_active,
          createdAt: x?.createdAt,
          updateAt: x?.updateAt,
        });
      });

      dispatch({
        type: LIST_ALL_WEAR_THE_TREND_SUCCESS,
        payload: wearTheTrendList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_WEAR_THE_TREND_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateWearTheTrend = (values, wearByTrendId, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/wear-the-trend/${wearByTrendId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_WEAR_THE_TREND_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("wear-the-trend updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      handleClose();
      resolve(response);
    } catch (error) {

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteWearTheTrend = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const wearByTrendId = values.id;

      // API
      const response = await AdminAPI.delete(`/wear-the-trend/${wearByTrendId}`, { headers });

      dispatch({
        type: DELETE_WEAR_THE_TREND_SUCCESS,
        payload: wearByTrendId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("wear-the-trend deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createWearTheTrend = (values, handleClose) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/wear-the-trend`, body, { headers });

      dispatch({
        type: CREATE_WEAR_THE_TREND_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("wear-the-trend added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      handleClose();
      resolve(response);
    } catch (error) {

      dispatch({
        type: LOADING_FALSE,
      });

      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message?.message);
      } else if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
