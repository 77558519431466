import React from "react";
import {
  ButtonWrapper,
  FormInputLabel,
  DashboardDetailsBorder,
  Para16Tag,
  Para20Tag,
  Para14Tag,
  Para18Tag,
} from "../../assets/css/styled";
import { CgCloseO } from "react-icons/cg";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { productFrameSizeSchema } from "../../helper/yup.schema";
import { CheckBoxCustom } from "../../components/CheckBox";
import { Box } from "@mui/material";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { listAllFrameSizes } from "../../redux/actions/frameSizeAction";
import FormErrorMessageWithoutIcon from "../../components/FormErrorMessageWithoutIcon";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import {
  DeleteIcons,
  UploadImagesIcon,
} from "../../components/typography/Icons";
import uploadImages from "../../assets/images/uploadImages.png";
import camera from "../../assets/images/camera.png";
import { Avatar } from "@mui/material";
import {
  ApiAddProductFrameDetails,
  ApiGetAllColor,
  ApiUpdateProductFrameDetails,
} from "../../helper/API/APICall";

const DrawerSidebar = ({ closeButton, complete, variant }) => {
  const [colorList, setColorList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedThumbnailImage, setSelectedThumbnailImage] =
    React.useState(null);
  const [selectedThumbnailImageSet, setSelectedThumbnailImageSet] =
    React.useState(null);
  const [selectedImages, setSelectedImages] = React.useState([]);
  const [selectedImagesSet, setSelectedImagesSet] = React.useState([]);
  const [selectedTryOnImage, setSelectedTryOnImage] = React.useState(null);
  const [selectedTryOnImageSet, setSelectedTryOnImageSet] =
    React.useState(null);
  const [selectedColor, setSelectedColor] = React.useState(null);
  const [sizeLength, setSizeLength] = React.useState(0);
  const [initFormikValues, setInitFormikValues] = React.useState({
    color_id: "",
    description: "",
    frameSizes: [
      {
        frame_size_id: "",
        frame_width: "",
        frame_height: "",
        frame_weight: "",
        frame_dimensions: "",
        lens_width: "",
        sku_no: "",
        quantity: "",
        in_stock: true,
        is_active: true,
      },
    ],
  });

  const { frameSizeList } = useSelector((state) => state?.frameSize);
  const { frameAdd } = useSelector((state) => state?.frameAdd);
  const dispatch = useDispatch();

  const handleThumbnailImageChange = (event) => {
    if (event.target.files?.[0]) {
      const newImages = {
        url: URL.createObjectURL(event.target.files?.[0]),
        name: event.target.files?.[0].name,
        size: event.target.files?.[0].size,
      };
      setSelectedThumbnailImage(newImages); // Replace previous images with newImages
      setSelectedThumbnailImageSet(event.target.files?.[0]);

      // Reset the input value to allow re-selecting the same file
      event.target.value = null;
    }
  };

  const handelTryOnImageChange = (event) => {
    if (event.target.files?.[0]) {
      const newImage = {
        url: URL.createObjectURL(event.target.files?.[0]),
        name: event.target.files?.[0].name,
        size: event.target.files?.[0].size, // Add the image size property
      };
      setSelectedTryOnImage(newImage); // Replace previous images with newImages
      setSelectedTryOnImageSet(event.target.files?.[0]);

      // Reset the input value to allow re-selecting the same file
      event.target.value = null;
    }
  };

  const handleImageChange = (event) => {
    const files = event?.target?.files;

    if (files && selectedImages?.length < 6) {
      const newImages = Array.from(files).map((file) => ({
        url: URL.createObjectURL(file),
        name: file?.name,
        size: file?.size,
      }));

      // Calculate the total number of images after adding new images
      const totalImages = selectedImages?.length + newImages?.length;

      if (totalImages > 6) {
        const remainingImages = 6 - selectedImages?.length;

        // Replace the selectedImages array with a combination of old and new images
        setSelectedImages([
          ...selectedImages?.slice(0, remainingImages),
          ...newImages?.slice(0, remainingImages),
        ]);
      } else {
        // Add new images to the selectedImages array
        setSelectedImages([...selectedImages, ...newImages]);
      }
      setSelectedImagesSet([...selectedImagesSet, ...files]);

      event.target.value = null;
    } else {
      ErrorToast(
        "You can only select up to six images remove after add images"
      );
    }
  };

  const handleThumbnailRemoveImage = () => {
    setSelectedThumbnailImage(null);
    setSelectedThumbnailImageSet(null);
  };

  const handleTryOnRemoveImage = () => {
    setSelectedTryOnImage(null);
    setSelectedTryOnImageSet(null);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };
  const handleRemoveImageSet = (index) => {
    const updatedImagesSet = selectedImagesSet.filter((_, i) => i !== index);
    setSelectedImagesSet(updatedImagesSet);
  };

  const handleColorChange = (e) => {
    colorList?.forEach((colors) => {
      if (e.target.value === colors?.id) {
        setSelectedColor(colors?.image);
      } else if (e.target.value === "") setSelectedColor(null);
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      if (variant) {
        const updatedFormData = new FormData();
        const frameSizes = values?.frameSizes?.map((size, index) => {
          if (index < sizeLength) {
            return {
              _id: variant?.frameSize?.[index]?._id,
              ...size,
            };
          } else {
            return size;
          }
        });
        if (selectedThumbnailImageSet) {
          updatedFormData.append(
            "thumbnail_picture",
            selectedThumbnailImageSet
          );
        }
        if (selectedTryOnImageSet) {
          updatedFormData.append("try_on_picture", selectedTryOnImageSet);
        }
        selectedImagesSet.length > 0 &&
          selectedImagesSet?.forEach((image) => {
            updatedFormData.append("images", image);
          });
        updatedFormData.append("frame_sizes", JSON.stringify(frameSizes));
        updatedFormData.append("color_id", values?.color_id);
        updatedFormData.append("description", values?.description);

        const response = await ApiUpdateProductFrameDetails(
          variant?.id,
          updatedFormData
        );

        /* The above code is checking if the error message received from the server response includes
        the string "E11000". If it does, it displays an error toast message saying "sku_no must be
        unique" and returns from the current function. */
        if (response?.data?.error?.includes("E11000")) {
          ErrorToast("SKU no. must be unique");
          return;
        }

        if (response?.data?.message?.status === 200) {
          SuccessToast("Product frame detail updated successfully");
          setSelectedTryOnImageSet(null);
          setSelectedThumbnailImageSet([]);
          setSelectedImagesSet(null);
          resetForm();
          closeButton();
          setIsLoading(false);
          // complete();
        } else setIsLoading(false);
        return;
      }
      const formData = new FormData();
      formData.append("product_frame_id", frameAdd);
      formData.append("color_id", values?.color_id);
      formData.append("description", values?.description);
      formData.append("frame_sizes", JSON.stringify(values?.frameSizes));
      selectedTryOnImageSet &&
        formData.append("try_on_picture", selectedTryOnImageSet);
      selectedThumbnailImageSet &&
        formData.append("thumbnail_picture", selectedThumbnailImageSet);
      selectedImagesSet.length > 0 &&
        selectedImagesSet?.forEach((image) => {
          formData.append("images", image);
        });

      const response = await ApiAddProductFrameDetails(formData);

      /* The above code is checking if the error message received from the server response includes the
      string "E11000". If it does, it displays an error toast message saying "sku_no must be unique"
      and returns from the current function. */
      if (response?.data?.error?.includes("E11000")) {
        ErrorToast("SKU no. must be unique");
        return;
      }

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product frame details added successfully");
        setSelectedTryOnImageSet(null);
        setSelectedThumbnailImageSet([]);
        setSelectedImagesSet(null);
        resetForm();
        closeButton();
        setIsLoading(false);
        // complete();
      } else setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllBaseColors = async () => {
    try {
      const response = await ApiGetAllColor(true);
      const data = [];
      response?.data?.map((x) => {
        return data.push({
          id: x?._id,
          color_name: x?.color_name,
          image: x?.image,
        });
      });
      setColorList(data);
    } catch (error) {
      throw new Error(error)
    }
  };

  React.useEffect(() => {
    dispatch(listAllFrameSizes());
    getAllBaseColors();
  }, []);

  React.useEffect(() => {
    if (variant) {
      setSelectedThumbnailImage(variant?.thumbnailImage);
      setSelectedTryOnImage(variant?.tryOnPicture);
      setSelectedImages(variant?.images);
      setInitFormikValues({
        color_id: variant?.colorId,
        description: variant?.description,
        frameSizes: variant?.frameSize?.map((x) => {
          return {
            frame_size_id: x?.frame_size_id?._id,
            frame_width: x?.frame_width,
            frame_height: x?.frame_height,
            frame_weight: x?.frame_weight,
            lens_width: x?.lens_width,
            frame_dimensions: x?.frame_dimensions,
            sku_no: x?.sku_no,
            quantity: x?.quantity,
            in_stock: x?.in_stock,
            is_active: x?.is_active,
          };
        }),
      });
      setSelectedColor(variant?.colorImage);
      setSizeLength(variant?.frameSize?.length);
    }
  }, []);

  return (
    <>
      <Box sx={{ width: "65rem" }}>
        <div className="flex items-center justify-between mb-2 shadow-lg p-6">
          <Para20Tag className="mr-6">Add variants</Para20Tag>
          <button onClick={closeButton}>
            <CgCloseO size={20} />
          </button>
        </div>

        <div className="p-6">
          <Formik
            initialValues={initFormikValues}
            validationSchema={productFrameSizeSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, handleChange }) => (
              <Form>
                <div className="add_product_images_add_inner_div_main flex-col">
                  <FormInputLabel>Thumbnail Image</FormInputLabel>
                  <div className="flex">
                    <div className="add_product_images_add_inner_div_both_same">
                      <div className="frame_information_left_images_open_browse_main">
                        <div className="frame_info_image_inner_open">
                          <input
                            style={{
                              display: "none",
                            }}
                            type="file"
                            name="thumbnail_upload_image"
                            id="thumbnail_upload_image"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) => handleThumbnailImageChange(e)}
                          />
                          <div className="frame_info_images_outer_inner_three_images_show">
                            <label htmlFor="thumbnail_upload_image">
                              <img
                                style={{
                                  cursor: "pointer",
                                }}
                                src={uploadImages}
                                alt="img"
                                className="frame_info_image_inner_open_inner"
                              />
                              <div className="frame_info_text_files_name_browse">
                                <UploadImagesIcon />
                                <Para20Tag weight={400} color={"#000"}>
                                  Drop your Files here Or.
                                  <span
                                    style={{
                                      color: "#219EBC",
                                    }}
                                  >
                                    Browse
                                  </span>
                                </Para20Tag>
                              </div>
                              <Para16Tag>
                                You can only select up to one images.
                              </Para16Tag>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame_info_image_inner_open_bottom_div_delete_icon">
                      {selectedThumbnailImage && (
                        <DashboardDetailsBorder padding={"10px"}>
                          <div className="frame_show_images_selected_file_inner">
                            <div className="frame_show_images_name_complete_done_div">
                              <img
                                src={
                                  selectedThumbnailImage?.url ||
                                  selectedThumbnailImage
                                }
                                alt="img"
                                className="frame_show_images_selected_file_inner_outer"
                              />
                              {selectedThumbnailImage?.name && (
                                <div className="add_product_size_images">
                                  <Para18Tag>
                                    {selectedThumbnailImage?.name}
                                  </Para18Tag>
                                  <Para14Tag weight={600}>
                                    {`${(
                                      selectedThumbnailImage?.size / 1024
                                    ).toFixed(0)} KB`}{" "}
                                  </Para14Tag>
                                </div>
                              )}
                            </div>
                            <div className="frame_info_images_inner_open_right_delete_icon_remove">
                              <button
                                className="frame_info_images_remove_icon_inner_delete"
                                onClick={() => handleThumbnailRemoveImage()}
                              >
                                <DeleteIcons />
                              </button>
                            </div>
                          </div>
                        </DashboardDetailsBorder>
                      )}
                    </div>
                  </div>
                </div>
                <div className="add_product_images_add_inner_div_main flex-col">
                  <FormInputLabel>Product Images</FormInputLabel>
                  <div className="flex">
                    <div className="add_product_images_add_inner_div_both_same">
                      <div className="frame_information_left_images_open_browse_main">
                        <div className="frame_info_image_inner_open">
                          <input
                            style={{
                              display: "none",
                            }}
                            type="file"
                            name="upload_image"
                            id="upload_image"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) => handleImageChange(e)}
                          />
                          <div className="frame_info_images_outer_inner_three_images_show">
                            <label htmlFor="upload_image">
                              <img
                                style={{
                                  cursor: "pointer",
                                }}
                                src={uploadImages}
                                alt="img"
                                className="frame_info_image_inner_open_inner"
                              />
                              <div className="frame_info_text_files_name_browse">
                                <UploadImagesIcon />
                                <Para20Tag weight={400} color={"#000"}>
                                  Drop your Files here Or.
                                  <span
                                    style={{
                                      color: "#219EBC",
                                    }}
                                  >
                                    {" "}
                                    Browse
                                  </span>
                                </Para20Tag>
                              </div>
                              <Para16Tag>
                                You can only select up to six images.
                              </Para16Tag>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame_info_image_inner_open_bottom_div_delete_icon">
                      <div className="frame_info_image_inner_open_bottom_div_inner">
                        {selectedImages?.map((image, index) => (
                          <DashboardDetailsBorder padding={"10px"}>
                            <div
                              className="frame_show_images_selected_file_inner"
                              key={index}
                            >
                              <div className="frame_show_images_name_complete_done_div">
                                <img
                                  src={image?.url || image}
                                  alt="img"
                                  className="frame_show_images_selected_file_inner_outer"
                                />
                                {image?.name && (
                                  <div className="add_product_size_images">
                                    <Para18Tag>{image?.name}</Para18Tag>
                                    <Para14Tag weight={600}>
                                      {`${(image?.size / 1024).toFixed(0)} KB`}{" "}
                                    </Para14Tag>
                                  </div>
                                )}
                              </div>

                              <div className="frame_info_images_inner_open_right_delete_icon_remove">
                                <button
                                  className="frame_info_images_remove_icon_inner_delete"
                                  type="button"
                                  onClick={() => {
                                    handleRemoveImage(index);
                                    image?.url && handleRemoveImageSet(index);
                                  }}
                                >
                                  <DeleteIcons />
                                </button>
                              </div>
                            </div>
                          </DashboardDetailsBorder>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add_product_images_add_inner_div_main flex-col">
                  <FormInputLabel>Virtual Try-on Image</FormInputLabel>
                  <div className="flex">
                    <div className="add_product_images_add_inner_div_both_same">
                      <div className="frame_information_left_images_open_browse_main">
                        <div className="frame_info_image_inner_open">
                          <input
                            style={{
                              display: "none",
                            }}
                            type="file"
                            name="take_upload_image"
                            id="take_upload_image"
                            accept="image/png, image/jpeg"
                            onChange={(e) => handelTryOnImageChange(e)}
                          />
                          <div className="frame_info_images_outer_inner_three_images_show">
                            <label htmlFor="take_upload_image">
                              <img
                                style={{
                                  cursor: "pointer",
                                }}
                                src={camera}
                                alt="img"
                                className="frame_info_image_inner_open_inner"
                              />
                              <div className="frame_info_text_files_name_browse_inner">
                                <Para20Tag weight={600} color={"#000"}>
                                  Take photo
                                </Para20Tag>
                                <Para16Tag color={"#868A8B"}>
                                  Image should be transparent
                                </Para16Tag>
                                <Para16Tag>
                                  You can only select up to one images.
                                </Para16Tag>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame_info_image_inner_open_bottom_div_delete_icon">
                      <div className="frame_info_image_inner_open_bottom_div_inner">
                        {selectedTryOnImage && (
                          <DashboardDetailsBorder padding={"10px"}>
                            <div className="frame_show_images_selected_file_inner">
                              <div className="frame_show_images_name_complete_done_div">
                                <img
                                  src={
                                    selectedTryOnImage?.url ||
                                    selectedTryOnImage
                                  }
                                  alt="img"
                                  className="frame_show_images_selected_file_inner_outer"
                                />
                                {selectedTryOnImage?.name && (
                                  <div className="add_product_size_images">
                                    <Para18Tag>
                                      {selectedTryOnImage?.name}
                                    </Para18Tag>
                                    <Para14Tag weight={600}>
                                      {`${(
                                        selectedTryOnImage?.size / 1024
                                      ).toFixed(0)} KB`}{" "}
                                    </Para14Tag>
                                  </div>
                                )}
                              </div>

                              <div className="frame_info_images_inner_open_right_delete_icon_remove">
                                <button
                                  className="frame_info_images_remove_icon_inner_delete"
                                  onClick={() => handleTryOnRemoveImage()}
                                >
                                  <DeleteIcons />
                                </button>
                              </div>
                            </div>
                          </DashboardDetailsBorder>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <div className="mb-5">
                    <FormInputLabel>Color</FormInputLabel>
                    <div className=" flex gap-4 items-center ">
                      <div className="flex w-[27.125rem] items-center ">
                        <Field
                          as="select"
                          name="color_id"
                          // disabled={variant}
                          onChange={(e) => {
                            handleColorChange(e);
                            setFieldValue("color_id", e.target.value);
                          }}
                        >
                          <option value="">Select a Color</option>
                          {!colorList.length
                            ? "Loading..."
                            : colorList?.map((value, index) => {
                                return (
                                  <option key={index} value={value?.id}>
                                    <Avatar size="sm" src={value?.image} />
                                    <img src={value?.image} alt="" />
                                    {value?.color_name}
                                  </option>
                                );
                              })}
                        </Field>
                      </div>
                      <span style={{ display: "inline-block" }}>
                        {selectedColor && (
                          <img
                            style={{
                              height: "32px",
                              width: "32px",
                              borderRadius: "50%",
                            }}
                            src={selectedColor}
                            alt={""}
                          />
                        )}
                      </span>
                      <FormErrorMessageWithoutIcon
                        errors={<ErrorMessage name="color_id" />}
                      />
                    </div>
                  </div>
                  <div className="mb-5">
                    <FormInputLabel>Description</FormInputLabel>
                    <div className="form_input_main">
                      <Field
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        className="from_input_tag"
                        // readOnly={variant && index < sizeLength}
                      />
                    </div>
                    <FormErrorMessageWithoutIcon
                      errors={<ErrorMessage name="description" />}
                    />
                  </div>
                </div>
                <FieldArray
                  name="frameSizes"
                  render={(arrayHelpers) => {
                    const frameSizes = values.frameSizes;
                    return (
                      <div>
                        {frameSizes && frameSizes.length > 0
                          ? frameSizes.map((frameSize, index) => (
                              <div key={index}>
                                {index === 0 ? null : (
                                  <button
                                    type="button"
                                    className="flex items-center justify-end w-fit ml-auto my-5"
                                    onClick={() => arrayHelpers.remove(index)}
                                    // disabled={variant && index < sizeLength}
                                  >
                                    <AiFillDelete size={20} color="red" />
                                  </button>
                                )}
                                <div className="grid grid-cols-2 gap-5">
                                  <div>
                                    <FormInputLabel>Frame Size</FormInputLabel>
                                    <Field
                                      name={`frameSizes.${index}.frame_size_id`}
                                      as="select"
                                      // disabled={variant && index < sizeLength}
                                    >
                                      <option value="">
                                        Select a Frame Size
                                      </option>
                                      {!frameSizeList.length
                                        ? "Loading..."
                                        : frameSizeList?.map((value, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={value?.id}
                                              >
                                                {value?.frame_size}
                                              </option>
                                            );
                                          })}
                                    </Field>
                                    <FormErrorMessageWithoutIcon
                                      errors={
                                        <ErrorMessage
                                          name={`frameSizes.${index}.frame_size_id`}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormInputLabel>Frame Width</FormInputLabel>
                                    <div className="form_input_main">
                                      <Field
                                        type="number"
                                        placeholder="Enter Frame Width"
                                        name={`frameSizes.${index}.frame_width`}
                                        className="from_input_tag"
                                        // readOnly={variant && index < sizeLength}
                                      />
                                    </div>
                                    <FormErrorMessageWithoutIcon
                                      errors={
                                        <ErrorMessage
                                          name={`frameSizes.${index}.frame_width`}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormInputLabel>
                                      Frame Bridge
                                    </FormInputLabel>
                                    <div className="form_input_main">
                                      <Field
                                        type="number"
                                        placeholder="Enter Frame Bridge"
                                        name={`frameSizes.${index}.frame_height`}
                                        className="from_input_tag"
                                        // readOnly={variant && index < sizeLength}
                                      />
                                    </div>
                                    <FormErrorMessageWithoutIcon
                                      errors={
                                        <ErrorMessage
                                          name={`frameSizes.${index}.frame_height`}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormInputLabel>
                                      Frame Temple Length in MM
                                    </FormInputLabel>
                                    <div className="form_input_main">
                                      <Field
                                        type="number"
                                        placeholder="Enter Frame Temple Length in MM"
                                        name={`frameSizes.${index}.frame_weight`}
                                        className="from_input_tag"
                                        // readOnly={variant && index < sizeLength}
                                      />
                                    </div>
                                    <FormErrorMessageWithoutIcon
                                      errors={
                                        <ErrorMessage
                                          name={`frameSizes.${index}.frame_weight`}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormInputLabel>
                                      Frame Dimensions
                                    </FormInputLabel>
                                    <div className="form_input_main">
                                      <Field
                                        type="text"
                                        placeholder="Enter Frame Dimensions"
                                        name={`frameSizes.${index}.frame_dimensions`}
                                        className="from_input_tag"
                                        // readOnly={variant && index < sizeLength}
                                      />
                                    </div>
                                    <FormErrorMessageWithoutIcon
                                      errors={
                                        <ErrorMessage
                                          name={`frameSizes.${index}.frame_dimensions`}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormInputLabel>Lens Width</FormInputLabel>
                                    <div className="form_input_main">
                                      <Field
                                        type="number"
                                        placeholder="Enter Lens Width"
                                        name={`frameSizes.${index}.lens_width`}
                                        className="from_input_tag"
                                        // readOnly={variant && index < sizeLength}
                                      />
                                    </div>
                                    <FormErrorMessageWithoutIcon
                                      errors={
                                        <ErrorMessage
                                          name={`frameSizes.${index}.lens_width`}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormInputLabel>SKU no.</FormInputLabel>
                                    <div className="form_input_main">
                                      <Field
                                        type="text"
                                        placeholder="Enter Frame SKU no."
                                        name={`frameSizes.${index}.sku_no`}
                                        className="from_input_tag"
                                        // readOnly={variant && index < sizeLength}
                                      />
                                    </div>
                                    <FormErrorMessageWithoutIcon
                                      errors={
                                        <ErrorMessage
                                          name={`frameSizes.${index}.sku_no`}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormInputLabel>Quantity</FormInputLabel>
                                    <div className="form_input_main">
                                      <Field
                                        type="number"
                                        placeholder="Enter Frame Quantity"
                                        name={`frameSizes.${index}.quantity`}
                                        className="from_input_tag"
                                      />
                                    </div>
                                    <FormErrorMessageWithoutIcon
                                      errors={
                                        <ErrorMessage
                                          name={`frameSizes.${index}.quantity`}
                                        />
                                      }
                                    />
                                  </div>
                                  <div className="mt-5 flex items-center w-full">
                                    <label className="add_new_add_default_address flex flex-col cursor-pointer items-start mr-5">
                                      <div className="flex cursor-pointer">
                                        <div
                                          className="flex cursor-pointer"
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        >
                                          <CheckBoxCustom
                                            id="in_stock"
                                            name={`frameSizes.${index}.in_stock`}
                                            checked={
                                              values?.frameSizes[index]
                                                ?.in_stock
                                            }
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <Para16Tag color={"#A7ABAC"}>
                                          Stock
                                        </Para16Tag>
                                      </div>
                                    </label>
                                    <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                                      <div className="flex cursor-pointer">
                                        <div
                                          className="flex cursor-pointer"
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        >
                                          <CheckBoxCustom
                                            id="is_active"
                                            name={`frameSizes.${index}.is_active`}
                                            checked={
                                              values?.frameSizes[index]
                                                ?.is_active
                                            }
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <Para16Tag color={"#A7ABAC"}>
                                          Active
                                        </Para16Tag>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))
                          : null}
                        <button
                          type="button"
                          className="bg-[#219EBC] hover:bg-[#178AA6] px-5 py-2 text-sm leading-5 rounded-[14px] font-semibold text-white mt-5 flex items-center justify-end w-fit ml-auto"
                          onClick={() =>
                            arrayHelpers.push({
                              frame_size_id: "",
                              frame_width: "",
                              frame_height: "",
                              frame_weight: "",
                              frame_dimensions: "",
                              sku_no: "",
                              quantity: "",
                              in_stock: true,
                              is_active: true,
                            })
                          } // insert an empty string at a position
                        >
                          + Add more variants
                        </button>
                        <div>
                          <ButtonWrapper className="mt-5" type="submit">
                            {isLoading ? "Loading..." : "Save"}
                          </ButtonWrapper>
                        </div>
                      </div>
                    );
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Box>
    </>
  );
};

export default DrawerSidebar;
