import { AdminAPI } from "../../helper/API/API.base";
import { getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  LIST_ALL_PROMO_CODES_REQUEST,
  LIST_ALL_PROMO_CODES_SUCCESS,
  LIST_ALL_PROMO_CODES_FAILURE,
  UPDATE_PROMO_CODE_SUCCESS,
  DELETE_PROMO_CODE_SUCCESS,
  CREATE_PROMO_CODE_SUCCESS,
} from "../constants/promoCode";

export const listAllPromoCodes = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: LIST_ALL_PROMO_CODES_REQUEST });

      // API
      const response = await AdminAPI.get("/promo-code", { headers });

      const promoCodeList = [];
      response?.data?.map((x) => {
        promoCodeList.push({
          id: x._id,
          is_public: x?.is_public,
          promo_code: x?.promo_code,
          discount_per: x?.discount_per,
          upto_amount: x?.upto_amount,
          limit: x?.limit,
          is_custom: x?.is_custom,
          is_active: x?.is_active,
          promocode_category: x?.promocode_category,
        });
        return 0;
      });

      dispatch({
        type: LIST_ALL_PROMO_CODES_SUCCESS,
        payload: promoCodeList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_PROMO_CODES_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updatePromoCode = (values, id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const promoCodeId = id;
      const body = values;

      // API
      const response = await AdminAPI.put(`/promo-code/${promoCodeId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_PROMO_CODE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Promo code updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deletePromoCode = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const promoCodeId = values.id;

      // API
      const response = await AdminAPI.delete(`/promo-code/${promoCodeId}`, {
        headers,
      });

      dispatch({
        type: DELETE_PROMO_CODE_SUCCESS,
        payload: promoCodeId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Promo code deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createPromoCode = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/promo-code`, body, { headers });

      if (response?.data?.error?.includes("E11000")) {
        ErrorToast("The promo code is already used");
      } else if (response?.data?.message?.status === 200) {
        dispatch({
          type: CREATE_PROMO_CODE_SUCCESS,
          payload: response?.data,
        });
        SuccessToast("Promo code added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error")
        ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
