import { DELETE_FRAME_SHAPE_SUCCESS } from "../constants/frameShapes.js";
import {
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  PUT_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
} from "../constants/product.js";

const initialState = {
  productList: [],
  loading: false,
  error: null,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return { ...state, loading: true };

    case ADD_PRODUCT_SUCCESS:
      return {
        loading: false,
        productList: action?.payload,
      };

    case PUT_PRODUCT_SUCCESS:
      return {
        ...state,
        productList: state.productList.map((val) => {
          if (val?.id === action.payload.id) {
            return action.payload;
          }
          return val;
        }),
      };

    case DELETE_PRODUCT_SUCCESS: {
      const productId = action.payload;
      return {
        ...state,
        categoryList: state.categoryList.filter((product) => product.id !== productId),
      };
    }

    case DELETE_FRAME_SHAPE_SUCCESS:
      return {
        ...state,
        productList: state.productList.filter((val) => val.id !== action.payload),
      };

    case ADD_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
