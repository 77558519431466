/* The above code is importing various components from different libraries in JavaScript. It is
importing components from the "@mui/material" library, such as Accordion, AccordionDetails,
AccordionSummary, Step, StepConnector, StepContent, StepLabel, Stepper, Table, TableCell,
TableContainer, TableHead, and TableRow. It is also importing the Link component from the
"react-router-dom" library and the createGlobalStyle and styled components from the
"styled-components" library. */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { keyframes, styled } from "styled-components";

// Dashboard styles
export const DashboardHead = styled.h2`
  color: #2e3233;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -0.8px;
`;

// Input styles
export const FormInputLabel = styled.label`
  color: #2e3233;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  margin-bottom: 8px;
  display: flex;
`;

// Switch Slider
export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ toggle, color }) => (toggle ? "#2DAAC8" : "#E9ECEF")};
  border-radius: 59px;
  transition: 0.4s;

  &:before {
    content: "";
    position: absolute;
    left: 2px;
    bottom: 2px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: ${({ toggle, color }) => (toggle ? "#FFFFFF" : color)};
    transition: 0.4s;
  }

  // &:hover:before {
  //   border-radius: 30px;
  //   width: 22px;
  //   height: 18px;
  // }
`;

// Switch Input
export const SwitchInput = styled.input`
  &: checked + ${SwitchSlider}:before {
    transform: translateX(23.4px);
  }
`;

// Switch label
export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  background-color: ${({ toggle, color }) => (toggle ? color : "#2DAAC8")};
  border-radius: 100%;
  transition: 0.4s;

  & ${SwitchInput} {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

// Para10Tag
export const Para10Tag = styled.p`
  color: #a7abac;
  font-size: 10px;
  font-weight: 400;
`;

// Para12Tag
export const Para12Tag = styled.p`
  font-size: 12px;
  font-weight: ${({ weight }) => (weight ? weight : "400")};
  line-height: 16px;
  letter-spacing: -0.24px;
  color: ${({ color }) => (color ? color : "#868a8b")};
`;

// Para14Tag
export const Para14Tag = styled.p`
  font-size: 14px;
  font-weight: ${({ weight }) => (weight ? weight : "400")};
  line-height: 20px;
  letter-spacing: -0.28px;
  color: ${({ color }) => (color ? color : "#868A8B")};
  margin-bottom: ${({ m_bottom }) => (m_bottom ? `${m_bottom}px` : "")};
`;

// Para16Tag
export const Para16Tag = styled.p`
  font-size: 16px;
  font-weight: ${({ weight }) => (weight ? weight : "400")};
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: ${({ center }) => center};
  color: ${({ color }) => (color ? color : "#2E3233")};
`;

// Para18Tag
export const Para18Tag = styled.p`
  font-size: 18px;
  color: ${({ color }) => (color ? color : "#2E3233")};
  font-weight: ${({ weight }) => (weight ? weight : "600")};
  line-height: 24px;
  letter-spacing: -0.36px;
`;

// Para20Tag
export const Para20Tag = styled.p`
  font-size: 20px;
  color: ${({ color }) => (color ? color : "#212529")};
  font-weight: ${({ weight }) => (weight ? weight : "500")};
  line-height: normal;
  letter-spacing: -0.4px;
`;

// Para22Tag
export const Para22Tag = styled.p`
  font-size: 22px;
  color: ${({ color }) => (color ? color : "#0E1213")};
  font-weight: ${({ weight }) => (weight ? weight : "600")};
  line-height: 28px;
  letter-spacing: -0.44px;
`;

// Head24Tag
export const Head24Tag = styled.h5`
  color: ${({ color }) => (color ? color : "#2e3233")};
  font-size: 24px;
  font-weight: ${({ weight }) => (weight ? weight : "600")};
  line-height: 32px;
  letter-spacing: -0.48px;
`;

// Head32Tag
export const Head28Tag = styled.h4`
  font-size: 28px;
  color: ${({ color }) => (color ? color : "#2E3233")};
  font-weight: ${({ weight }) => (weight ? weight : "600")};
  line-height: 40px;
  letter-spacing: -0.56px;
`;

// Head32Tag
export const Head32Tag = styled.h4`
  font-size: 32px;
  color: ${({ color }) => (color ? color : "#212529")};
  font-weight: ${({ weight }) => (weight ? weight : "500")};
  line-height: 40px;
  letter-spacing: -0.64px;
  margin-bottom: ${({ m_bottom }) => (m_bottom ? `${m_bottom}px` : "")};
`;

// Head40Tag
export const Head40Tag = styled.h4`
  font-size: 40px;
  color: ${({ color }) => (color ? color : "#2E3233")};
  font-weight: ${({ weight }) => (weight ? weight : "600")};
  line-height: normal;
  letter-spacing: -0.8px;
  margin-bottom: ${({ m_bottom }) => (m_bottom ? `${m_bottom}px` : "")};
`;

// Head48Tag
export const Head48Tag = styled.h2`
  color: ${({ color }) => (color ? color : "#2E3233")};
  font-size: 48px;
  font-weight: ${({ weight }) => (weight ? weight : "600")};
  line-height: 64px;
  letter-spacing: -0.96px;
`;

// Head40Tag
export const Head50Tag = styled.h4`
  font-size: 50px;
  color: ${({ color }) => (color ? color : "#FFF")};
  font-weight: ${({ weight }) => (weight ? weight : "700")};
  line-height: normal;
  letter-spacing: -1px;
  margin-bottom: ${({ m_bottom }) => (m_bottom ? `${m_bottom}px` : "")};
`;

// Head54Tag
export const Head54Tag = styled.h4`
  font-size: 54px;
  color: ${({ color }) => (color ? color : "#2E3233")};
  font-weight: ${({ weight }) => (weight ? weight : "600")};
  line-height: 72px; /* 133.333% */
  letter-spacing: -1.08px;
`;

// DashboardDetailsBorder
export const DashboardDetailsBorder = styled.div`
  border-radius: 18px;
  border: 1px solid #eaeef0;
  padding: ${({ padding }) => padding};
  width: 100%;
`;

// Icon span tag
export const IconSpan = styled.span`
  display: flex;
  margin-right: ${({ margin }) => (margin ? margin : "12px")};
  margin-left: ${({ m_left }) => (m_left ? m_left : "0")};
  align-items: center;
`;

// Checkbox
// Checkbox container
export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  display: flex;
  align-items: center;
`;

// Checkbox Icon
export const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  display: flex;
`;

export const ColorWrapper = styled.img`
  width: ${({ width }) => (width ? `${width}px` : "32px")};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  border-radius: 50%;
  ${"" /* background: url(${({ $color_img }) => $color_img}) no-repeat; */}
  cursor: pointer;
`;

// Hide checkbox visually but remain accessible to screen readers.
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

// Hide checkbox visually but remain accessible to screen readers.
export const HiddenCheckboxCustom = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

// Styled Checkbox
export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ checked }) => (checked ? "#219ebc" : "#FFF")};
  filter: drop-shadow(0px 0px 0px #e9ecef)
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border: 1px solid #e9ecef;
  border-radius: 3px;
  transition: all 150ms;

  &&:hover {
    border: 1px solid #ced4da;
  }

  ${HiddenCheckbox}:checked + & {
    background: #219ebc;
    box-shadow: 0 0 0 3px #219ebc4d;
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #ced4da;
  }

  ${CheckboxIcon} {
    // visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

// RadioButton
export const RadioButtonContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  display: flex;
  align-items: center;
  height: 20px;
`;

// Hide radio button visually but remain accessible to screen readers.
export const StyledRadioButton = styled.input.attrs({ type: "radio" })`
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 20px;
  height: 20px;
  white-space: nowrap;
  border: 1px solid #dee2e4;
`;

// Container wrapper
export const ContainerWrapper = styled.div`
  max-width: 100%;
  margin: 0 48px;
  width: -webkit-fill-available;
`;

// Breadcrumbs
export const BreadcrumbsWrapper = styled.div`
  background: #f2fafd;
  padding: 12px 0 10px;
`;

// Border Divider
export const BorderDivider = styled.div`
  margin-bottom: ${({ m_bottom }) => (m_bottom ? `${m_bottom}px` : "")};
  margin-top: ${({ mTop }) => (mTop ? `${mTop}px` : "")};
  display: flex;
  border-bottom: 1px solid
    ${({ borderColor }) => (borderColor ? borderColor : "#eaeef0")};
`;

// Order Status Div
export const OrderStatusDiv = styled.div`
  display: flex;
  padding: ${({ padding }) => (padding ? `${padding}px` : "6px 12px")};
  border-radius: 7px;
  background: ${({ bg_color }) => (bg_color ? bg_color : "")};
  width: ${({ width }) => (width ? width : "fit-content")};
  height: ${({ height }) => (height ? height : "")};
  justify-content: center;
  align-items: center;
`;

// DashboardDropdown
export const DashboardDropdown = styled.div`
  border-radius: 8px;
  background: rgba(33, 158, 188, 0.05);
  padding: 20px;
`;

// DashboardDropdownBtn
export const DashboardDropdownBtn = styled.button`
  display: flex;
  align-items: center;
  color: #219ebc;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
`;

// FormTextArea
export const FormTextArea = styled.textarea`
  padding: 16px;
  height: 165px;
  border-radius: 12px;
  width: 100%;
  border: 1px solid ${({ border }) => (border ? border : "#eaeef0")};
  background: ${({ bg_color }) => (bg_color ? bg_color : "#fff")};
`;

// ButtonWrapper
export const ButtonWrapper = styled.button`
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  font-weight: ${({ weight }) => (weight ? weight : 500)};
  line-height: 20px;
  letter-spacing: -0.32px;
  background-color: ${({ bg_color }) => (bg_color ? bg_color : "#219EBC")};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : "14px")};
  padding: 18px 32px;
  border: 1px solid
    ${({ border_color }) => (border_color ? border_color : "#219ebc")};
  color: ${({ color }) => (color ? color : "#FFF")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width : "")};
  margin: ${({ auto }) => (auto ? auto : "")};

  &:hover {
    background: ${({ hover_bg }) => (hover_bg ? hover_bg : "#178AA6")};
    color: ${({ hover_color }) => (hover_color ? hover_color : "#FFF")};
    border: 1px solid
      ${({ hover_border }) => (hover_border ? hover_border : "#DEE2E4")};
  }
`;

// LinkWrapper
export const LinkWrapper = styled(Link)`
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.32px;
  background-color: ${({ bg_color }) => (bg_color ? bg_color : "#219EBC")};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : "14px")};
  padding: 18px 32px;
  border: 1px solid ${({ bColor }) => (bColor ? bColor : "#219ebc")};
  color: ${({ color }) => (color ? color : "#FFF")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width : "")};

  &:hover {
    background: ${({ hover_bg }) => (hover_bg ? hover_bg : "#178AA6")};
    color: ${({ hover_color }) => (hover_color ? hover_color : "#FFF")};
    border: 1px solid
      ${({ hover_border }) => (hover_border ? hover_border : "#DEE2E4")};
  }
`;

// Main padding
export const MainPadding = styled.div`
  padding-top: ${({ p_top }) => (p_top ? `${p_top}px` : "")};
  padding-bottom: ${({ p_bottom }) => (p_bottom ? `${p_bottom}px` : "")};
`;

// span Dots
export const SpanDots = styled.span`
  background-color: ${({ bg_color }) => (bg_color ? bg_color : "")};
  width: ${({ width }) => (width ? width : "4px")};
  height: ${({ height }) => (height ? height : "4px")};
  border-radius: ${({ radius }) => (radius ? radius : "4px")};
  margin: 0 8px;
  display: flex;
`;

// Booking Flow Grid Layout
export const BookingGridLayout = styled.div`
  display: grid;
  gap: ${({ gap }) => (gap ? `${gap}rem` : "1.25rem")};
  grid-template-columns: ${({ grid_cols }) =>
    `repeat(${grid_cols}, minmax(0, 1fr))`};
`;

// Booking Flow Col Span Main Style
export const BookingColSpanGridLayoutMain = styled.div`
  grid-column: ${({ spanGrid }) => `span ${spanGrid} / span ${spanGrid}`};
`;

// Booking Flow Col Span Side Style
export const BookingColSpanGridLayoutSide = styled.div`
  grid-column: ${({ spanGrid }) => `span ${spanGrid} / span ${spanGrid}`};
`;

// StyledAccordion
export const StyledAccordion = styled(Accordion)`
  background: transparent !important;
  box-shadow: none !important;
  padding-bottom: ${({ p_bottom }) =>
    p_bottom ? p_bottom : "16px"} !important ;
  margin-bottom: ${({ m_bottom }) => (m_bottom ? m_bottom : "0px")} !important;
  border: 1px solid ${({ border }) => (border ? border : "0")} !important;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : "")} !important;
  overflow:  ${({ overflow }) =>
    overflow ? overflow : "none"} !important;auto;
  &:before {
    display: none !important;
  }
`;

// StyledAccordionSummary
export const StyledAccordionSummary = styled(AccordionSummary)`
  border: 1px solid #eaeef0 !important;
  padding: 20px !important;
  background: #f4f8f9 !important;
`;

// StyledAccordionDetails
export const StyledAccordionDetails = styled(AccordionDetails)`
  box-shadow: none !important;
  padding: 20px !important;
`;

// CardBorder
export const CardBorder = styled.div`
  border: 1px solid #dee2e4;
  padding: ${({ padding }) => (padding ? `${padding}px` : "20px")};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : "")};
`;

// Text Link
export const TextLink = styled(Link)`
  color: #219ebc;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  font-weight: ${({ weight }) => (weight ? weight : 600)};
  line-height: 20px;
  letter-spacing: -0.32px;
`;

// Table Container
export const StyledTableContainer = styled(TableContainer)`
  display: flex !importCant;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
`;

// Table
export const StyledTable = styled(Table)`
  margin: auto !important;
`;

// Table head
export const StyledTableHead = styled(TableHead)`
  background-color: ${({ bg_color }) => (bg_color ? bg_color : "")};
`;

// Table Row
export const StyledTableRow = styled(TableRow)`
  border: 1px solid #eaeef0 !important;
`;

// table Cell
export const StyledTableCell = styled(TableCell)`
  color: #2e3233 ! !important;
  font-size: 18px !important;
  width: 22.5rem !important;
  text-align: center !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.32px !important;
  border-right: 1px solid
    ${({ border_right }) => (border_right ? border_right : "#eaeef0")} !important;
  border-bottom: 1px solid
    ${({ border_bottom }) => (border_bottom ? border_bottom : "#eaeef0")} !important;
  border-top: 1px solid
    ${({ border_top }) => (border_top ? border_top : "#eaeef0")} !important;
  border-left: 1px solid
    ${({ border_left }) => (border_left ? border_left : "#eaeef0")} !important;
  padding: 20px !important;
`;

// modal close button

export const ModalCloseButtonWrapper = styled.button`
  border-radius: 100%;
  background: #f4f8f9;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// login-for-booking stepper

export const StyledStepper = styled(Stepper)`
  margin-bottom: 0;
`;

export const StyledStep = styled(Step)`
  // background: #fff;
  border-radius: 16px;
  // border: 1px solid #eaeef0 !important;
  margin-bottom: 12px;
  border: none;
`;

export const StyledStepLabel = styled(StepLabel)`
  // padding: 16px !important;

  &.custom-active {
    // background: #219ebc;
    // border-radius: 16px 16px 0 0;
  }
`;

export const StyledConnector = styled(StepConnector)`
  /* Set the connector line to none */
  display: none;
`;

export const StyledStepContent = styled(StepContent)`
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-left: none !important;
`;

// Loader
export const spinAnimation = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

export const LoaderMain = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
`;

export const Spinner = styled.div`
  width: 56px;
  height: 56px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  animation: ${spinAnimation} 1s infinite linear;

  &::before,
  &::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }

  &::before {
    border-color: #219ebc #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }

  &::after {
    margin: 8.9px;
  }
`;

// Styled Images Wrapper
export const StyledImagesWrapper = styled.img`
  margin-bottom: 14px;
  border-radius: ${({ border }) => (border ? border : "")};
  width: 100%;
`;

// Form Input Outer
export const FormInputOuter = styled.div`
  background-color: #f4f8f9;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #eaeef0;
  padding: 14px 16px;
`;

// DashBoard Grid
export const DashBoardGrid = styled.div`
  display: grid;
  gap: ${({ gap }) => (gap ? `${gap}rem` : "1.25rem")};
  grid-template-columns: ${({ grid_cols }) =>
    `repeat(${grid_cols}, minmax(0, 1fr))`};
`;

// DashBoard Grid Span
export const DashBoardGridSpan = styled.div`
  grid-column: ${({ spanGrid }) => `span ${spanGrid} / span ${spanGrid}`};
`;

// Form Grid
export const FormGrid = styled.div`
  display: grid;
  gap: ${({ gap }) => (gap ? `${gap}rem` : "1.25rem")};
  grid-template-columns: ${({ grid_cols }) =>
    `repeat(${grid_cols}, minmax(0, 1fr))`};
`;

export const ORText = styled.p`
  position: relative;
  text-align: center;
  color: #a7abac;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;

  &:before {
    position: absolute;
    content: "";
    border-radius: 61px;
    background: linear-gradient(
      90deg,
      rgb(70 206 239 / 20%) 0%,
      rgba(33, 158, 188, 0) 100%
    );
    width: 9.34rem;
    height: 2px;
    top: 50%;
    left: 2rem;
    rotate: 0deg;
  }

  &:after {
    position: absolute;
    content: "";
    border-radius: 61px;
    background: linear-gradient(
      90deg,
      rgb(70 206 239 / 20%) 0%,
      rgba(33, 158, 188, 0) 100%
    );
    width: 9.34rem;
    height: 2px;
    top: 50%;
    rotate: 180deg;
    transform: translateX(2rem);
    right: 0;
  }
`;

// Booking Flow Col Span Side Style
export const BookingColSpan_gridLayoutSide = styled.div`
  grid-column: ${({ span_grid }) => `span ${span_grid} / span ${span_grid}`};
`;

export const ColorBoxDiv = styled.div`
  display: flex;
  padding: ${({ padding }) => (padding ? `${padding}px` : "6px 12px")};
  border-radius: 7px;
  background: ${({ $bg_color }) => ($bg_color ? $bg_color : null)};
  width: ${({ width }) => (width ? width : "fit-content")};
  height: ${({ height }) => (height ? height : null)};
  justify-content: center;
  align-items: center;
`;

export const TableWrapper = styled.div`
  border-radius: 18px;
  border: 1px solid #eaeef0;

  tr > * {
    border-bottom: none !important;
  }

  th,
  td {
    color: #2e3233;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.32px;
  }

  table thead tr th:not(:last-child) {
    border-right: 1px solid #eaeef0 !important;
  }

  table thead tr th {
    background: #219ebc;
    color: #fff;
    padding: 10px;
  }

  table thead tr > th:first-child {
    border-radius: 18px 0 0;
  }

  table thead tr > th:last-child {
    border-radius: 0 18px 0 0;
  }

  table tbody tr th,
  td:not(:last-child) {
    border-right: 1px solid #eaeef0 !important;
    color: #2e3233;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.36px;
  }

  table thead tr th {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
`;
