/**
 * The Login component is a form that allows users to enter their email address and password to log in,
 * with options to remember their login information, reset their password, and sign in with Google or
 * Apple.
 * @returns The Login component is returning a JSX element.
 */
import React from "react";
import "./login.css";
import AuthSlider from "../authSlider/AuthSlider";
import { EmailIcon, PasswordIcon } from "../../typography/Icons";
import { ButtonWrapper, FormInputLabel, MainPadding, Head48Tag, Para16Tag, Para18Tag, Para14Tag } from "../../../assets/css/styled";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import CheckBox from "../../CheckBox";
import { routesConfig } from "../../../helper/config/routes.config";
import Back from "../../Back";
import { Field, Form, Formik } from "formik";
import { signInUserSchema } from "../../../helper/yup.schema";
import FormErrorMessage from "../../FormErrorMessage";
import { BsFacebook } from "react-icons/bs";
import { getLoginAction } from "../../../redux/actions/adminAuthAction";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  // modal open close
  const [checked, setChecked] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state?.loginAdmin);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleCheckboxChange = (event) => setChecked(event.target.checked);

  /**
   * The handleSubmit function is a JavaScript function that handles form submission, dispatches a
   * loginUserAction, resets the form, and resolves or rejects a promise based on the success or
   * failure of the login action.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It typically includes properties for each form field, with the field names as keys and the
   * user input as values.
   * @returns The handleSubmit function returns a Promise.
   */
  const handleSubmit = (values, { resetForm }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(getLoginAction(values, navigate));
        resetForm();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <>
      <section className="auth_slider_main login">
        <div className="grid grid-cols-2">
          <div className="login_left_main_page">
            <AuthSlider />
          </div>
          <div className="login_right_main_page">
            <div className="login_right_inner_outer_info">
              <div className="login_text_main_title_top_div_inner">
                <div className="login_text_main_title">
                  <Head48Tag style={{ textAlign: "center" }}>Login</Head48Tag>
                </div>
                <div className="login_text_main_title_para_inner_information">
                  <Para16Tag
                    weight={500}
                    color={"#868A8B"}
                    center={"center"}>
                    {"Enter your information here"}
                  </Para16Tag>
                </div>
              </div>
              <div className="auth_form_div">
                <Formik
                  initialValues={initialValues}
                  validationSchema={signInUserSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched }) => (
                    <Form className="auth_page_form_main">
                      <div className="auth_page_from_inner_div">
                        <div className="form_input_list_email_pass_other_data">
                          <div className="form_input_flex_div_mx_bottom">
                            <div className="form_inner_input_div_main">
                              <FormInputLabel>Email Address</FormInputLabel>
                              <div className="form_input_main">
                                <EmailIcon stroke={"#000000"} />
                                <Field
                                  type="email"
                                  placeholder="Enter your email address"
                                  name="email"
                                  className="from_input_tag"
                                />
                              </div>
                              {touched.email && errors.email && <FormErrorMessage errors={errors.email} />}
                            </div>
                          </div>
                          <div className="form_input_flex_div_mx_bottom">
                            <div className="form_inner_input_div_main">
                              <FormInputLabel>Password</FormInputLabel>
                              <div className="form_input_main">
                                <PasswordIcon stroke={"#000000"} />
                                <Field
                                  type="password"
                                  placeholder="Enter your password"
                                  name="password"
                                  className="from_input_tag"
                                />
                              </div>
                              {touched.password && errors.password && <FormErrorMessage errors={errors.password} />}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                            className="form_input_forget_text_main_pass_inner">
                            <div className="form_get_update_text_data_title">
                              <div
                                style={{ cursor: "pointer" }}
                                className="add_new_add_default_address">
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}>
                                  <div
                                    style={{
                                      marginRight: "10px",
                                      cursor: "pointer",
                                    }}
                                    className="main_check_box">
                                    <CheckBox
                                      id={"remember_me"}
                                      name={"remember_me"}
                                      checked={checked}
                                      onChange={handleCheckboxChange}
                                    />
                                  </div>
                                  <div className="label_text">
                                    <Para14Tag
                                      weight={500}
                                      color="#A7ABAC">
                                      Remember me
                                    </Para14Tag>
                                  </div>
                                </label>
                              </div>
                            </div>
                            <Link
                              className="form_input_forget_text_main_pass_inner_link_tag"
                              to={routesConfig?.forgot}>
                              {"Forgot Password?"}
                            </Link>
                          </div>
                        </div>
                        <div className="form_sign_in_btn_main">
                          <ButtonWrapper
                            width="100%"
                            type="submit">
                            {loading ? "loading..." : "Login"}
                          </ButtonWrapper>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                {/* <div className="form_data_or_text_div_line_main">
                  <p className="form_line_para_or_main_div_add">OR</p>
                </div>
                <div className="form_google_apple_ios_btn">
                  <button
                    // onClick={handleGoogleSignIn}
                    className="form_google_apple_ios_btn_inner">
                    <span className="form_google_icons_main">
                      <FcGoogle />
                    </span>
                    <div className="form_sign_google_para_main">
                      <Para18Tag weight={600}>{"Sign in with Google"}</Para18Tag>
                    </div>
                  </button>

                  <button className="form_google_apple_ios_btn_inner">
                    <span className="form_google_icons_main">
                      <BsFacebook style={{ fill: "#1877F2" }} />
                    </span>
                    <div className="form_sign_google_para_main">
                      <Para18Tag weight={600}>{"Sign-in With Facebook"}</Para18Tag>
                    </div>
                  </button>
                </div> */}

                {/* <div className="form_continue_text_title_main">
                  <div className="form_continue_text_title_main_inner_">
                    <Para16Tag color={"#A7ABAC"} center={"center"}>
                      {"Don’t Want to sign in?"}
                    </Para16Tag>
                    <span className="form_continue_text_title_guest_inner">
                      <Link
                        className="form_input_forget_text_main_pass_inner_link_tag"
                        to={routesConfig?.createAccount}
                      >
                        {"Create Account"}
                      </Link>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
