import React from "react";
import { LoaderMain, Spinner } from "../assets/css/styled";

const Loader = () => {
  return (
    <>
      <LoaderMain>
        <Spinner />
      </LoaderMain>
    </>
  );
};

export default Loader;
