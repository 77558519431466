import {
  ADD_FRAME_FAILURE,
  ADD_FRAME_REQUEST,
  ADD_FRAME_SUCCESS,
  ADD_PRODUCT_FRAME_FAILURE,
  ADD_PRODUCT_FRAME_REQUEST,
  ADD_PRODUCT_FRAME_SUCCESS,
  UPDATE_PRODUCT_FRAME_REQUEST,
  UPDATE_PRODUCT_FRAME_SUCCESS,
  UPDATE_PRODUCT_FRAME_FAILURE,
} from "../../helper/config/constantsRedux.config";

export const frameReducer = (state = { frameAdd: {} }, action) => {
  switch (action.type) {
    case ADD_FRAME_REQUEST:
      return { ...state, loading: true };

    case ADD_FRAME_SUCCESS:
      return {
        loading: false,
        frameAdd: action?.payload,
      };

    case ADD_FRAME_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    case ADD_PRODUCT_FRAME_REQUEST:
      return { ...state, loading: true };

    case ADD_PRODUCT_FRAME_SUCCESS:
      return {
        loading: false,
        frameAdd: action?.payload,
      };

    case ADD_PRODUCT_FRAME_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    case UPDATE_PRODUCT_FRAME_REQUEST:
      return { ...state, loading: true };

    case UPDATE_PRODUCT_FRAME_SUCCESS:
      return {
        loading: false,
        frameAdd: action?.payload,
      };

    case UPDATE_PRODUCT_FRAME_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
