// Global Css

import { createGlobalStyle } from "styled-components";

/* The above code is defining a global style using styled-components in JavaScript. It sets various
styles for different elements and selectors. */
export const GlobalStyled = createGlobalStyle`
  body {
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,h2,h3,h4,h5,h6,p,span,a,button,input {
        font-family: Poppins !important;
        font-style: normal;
    }

  button {
      cursor: pointer;
  }

  input::placeholder,textarea::placeholder {
      color: #868A8B;
      font-weight: 500;
  }

  input, textarea, select {
      outline: none;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.28px;
  }

  input.from_input_tag,select,textarea {
    // height: 100%;
    width: 100%;
    background-color: transparent;
  }

  .form_input_main,textarea {
    background-color: #f4f8f9;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #eaeef0;
    padding: 14px 16px;
  }

  select {
    background-color: #f4f8f9;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #eaeef0;
    padding: 14px 5px;
  }

  option {
      text-transform: capitalize;
  }

  ::-webkit-scrollbar-thumb {
    width: 2px;
    background: #219ebc;
    border-radius: 50px
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  img.frame_info_image_inner_open_inner {
    width: 11rem;
}

.border.border-blue-300.rounded-md.p-5.my-2 {
    width: fit-content;
    margin: auto;
}
`;
