import {
  ADD_LENSES_SUCCESS,
  CREATE_LENSES_SUCCESS,
  DELETE_LENSE_SUCCESS,
  LIST_ALL_LENSE_FAILURE,
  LIST_ALL_LENSE_REQUEST,
  LIST_ALL_LENSE_SUCCESS,
  UPDATE_LENSES_SUCCESS,
} from "../constants/lense";

const initialState = {
  lenseList: [],
  loading: false,
  error: null,
};

let lenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LENSES_SUCCESS:
      return {
        ...state,
        lenseList: [...state.lenseList, action.payload],
      };

    case LIST_ALL_LENSE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_LENSE_SUCCESS:
      return {
        ...state,
        lenseList: action?.payload,
        loading: false,
      };

    case LIST_ALL_LENSE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_LENSES_SUCCESS: {
      const updatedLense = action.payload.lens;
      return {
        ...state,
        lenseList: state.lenseList.map((lense) => {
          if (lense.id === updatedLense._id) {
            return {
              ...lense,
              prescription_type: updatedLense.prescription_type,
              description: updatedLense.description,
              image: updatedLense.image,
              price: updatedLense.price,
              colors: updatedLense.colors,
              is_active: updatedLense.is_active,
            };
          }
          return lense;
        }),
      };
    }

    case CREATE_LENSES_SUCCESS: {
      const { lens } = action.payload;
      const newlens = {
        id: lens._id,
        prescription_type: lens.prescription_type,
        description: lens.description,
        price: lens.price,
        image: lens.image,
        colors: lens.colors,
        is_active: lens.is_active,
      };
      return {
        ...state,
        lenseList: [...state.lenseList, newlens],
      };
    }

    case DELETE_LENSE_SUCCESS: {
      const lenseId = action.payload;
      return {
        ...state,
        lenseList: state.lenseList.filter((lense) => lense.id !== lenseId),
      };
    }

    default:
      return state;
  }
};

export default lenseReducer;
