import React from "react";
import { ButtonWrapper } from "../../assets/css/styled";
import { Drawer } from "@mui/material";
import DrawerSidebar from "./DrawerSidebar";
import { useSelector } from "react-redux";
import { ApiDeleteProductFrameDetails, GetProductFrameDetails } from "../../helper/API/APICall";
import Loader from "../../components/Loader";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ErrorToast, SuccessToast } from "../../helper/Toast";

const ProductImage = ({ complete }) => {
  const [drawer, setDrawer] = React.useState({ left: false });
  const { frameAdd } = useSelector((state) => state?.frameAdd);
  const [productData, setProductData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedVariant, setSelectedVariant] = React.useState(null);

  const GetAllProductFrameDetails = async (frameAdd) => {
    setIsLoading(true);
    try {
      const colorAndSize = [];
      const response = await GetProductFrameDetails(frameAdd);
      if (response?.data?.length) {
        const productData = response?.data?.[0]?.product_frame;
        response?.data?.[0]?.frames?.forEach((x) => {
          const colorName = x?.color_id?.color_name;
          if (colorName && !colorAndSize.some((color) => color.name === colorName)) {
            colorAndSize.push({
              id: x?._id,
              product_name: productData?.product_name,
              price: productData?.price,
              category: productData?.category_id?.type,
              colorId: x?.color_id?._id,
              description: x?.description,
              colorName: colorName,
              colorImage: x?.color_id?.image,
              thumbnailImage: x?.thumbnail_picture,
              tryOnPicture: x?.try_on_picture,
              images: x?.images,
              frameSize: x?.frame_sizes?.map((size) => {
                return size;
              }),
            });
          }
        });
        setProductData(colorAndSize);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleVariant = (id) => {
    setSelectedVariant(productData?.find((x) => x?.id === id));
    toggleDrawer(true);
  };

  const deleteVariant = async (id) => {
    try {
      const response = await ApiDeleteProductFrameDetails(id);
      if (response?.data?.message?.status === 200) {
        setProductData((prev) => prev?.filter((x) => x?.id !== id));
        SuccessToast("Product variant deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleDrawerClose = (value) => {
    toggleDrawer(value);
    setSelectedVariant(null);
  };

  const createData = (image, name, color, description, price, category, frameSize, id) => {
    return {
      image,
      name,
      color,
      description,
      price,
      category,
      Sizes: frameSize?.map((size) => {
        return {
          id: size?._id,
          size: size?.frame_size_id?.frame_size,
          sku: size?.sku_no,
          quantity: size?.quantity,
          frame_width: size?.frame_width,
          frame_height: size?.frame_height,
          frame_weight: size?.frame_weight,
          frame_dimensions: size?.frame_dimensions,
          is_active: size?.is_active.toString(),
        };
      }),
      id,
    };
  };

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row">
            <img
              className="w-28"
              src={row?.image}
              alt={`frame image`}
            />
          </TableCell>
          <TableCell align="left">{row?.name}</TableCell>
          <TableCell align="left">{row?.color}</TableCell>
          <TableCell align="left">{row?.description}</TableCell>
          <TableCell align="left">{row?.price}</TableCell>
          <TableCell align="left">{row?.category}</TableCell>
          <TableCell align="center">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              color="inherit"
              onClick={() => handleVariant(row?.id)}
            />
          </TableCell>
          <TableCell align="center">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="inherit"
              onClick={() => deleteVariant(row?.id)}
            />
          </TableCell>
        </TableRow>
        {/* Sizes details */}
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={9}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography
                  className="!font-semibold"
                  variant="h6"
                  gutterBottom
                  component="div">
                  Sizes
                </Typography>
                <Table
                  size="small"
                  aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className="!font-semibold">Size</TableCell>
                      <TableCell className="!font-semibold">SKU</TableCell>
                      <TableCell
                        className="!font-semibold"
                        align="right">
                        Quantity
                      </TableCell>
                      <TableCell
                        className="!font-semibold"
                        align="right">
                        Width
                      </TableCell>
                      <TableCell
                        className="!font-semibold"
                        align="right">
                        Height
                      </TableCell>
                      <TableCell
                        className="!font-semibold"
                        align="right">
                        Weight
                      </TableCell>
                      <TableCell
                        className="!font-semibold"
                        align="right">
                        Dimensions
                      </TableCell>
                      <TableCell
                        className="!font-semibold"
                        align="right">
                        Active
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.Sizes.map((size) => (
                      <TableRow key={size?.size}>
                        <TableCell
                          component="th"
                          scope="row">
                          {size?.size}
                        </TableCell>
                        <TableCell>{size.sku}</TableCell>
                        <TableCell align="right">{size?.quantity}</TableCell>
                        <TableCell align="right">{size?.frame_width}</TableCell>
                        <TableCell align="right">{size?.frame_height}</TableCell>
                        <TableCell align="right">{size?.frame_weight}</TableCell>
                        <TableCell align="right">{size?.frame_dimensions}</TableCell>
                        <TableCell align="right">{size?.is_active}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const rows = productData?.map((x) =>
    createData(x?.thumbnailImage, x?.product_name, x?.colorName, x?.description, x?.price, x?.category, x?.frameSize, x?.id)
  );

  React.useEffect(() => {
    if (!drawer?.left) {
      GetAllProductFrameDetails(frameAdd);
    }
  }, [drawer]);

  const toggleDrawer = (open) => setDrawer({ ...drawer, left: open });

  return (
    <>
      <div className="p-5 w-[30.125rem] border border-slate-400 rounded-xl flex items-center justify-between space-x-4">
        <div>
          <div className="text-xl font-medium text-black">Variants</div>
          <p className="text-slate-500">Different types of this product (e.g. color)</p>
        </div>
        <div className="shrink-0">
          <ButtonWrapper
            type="button"
            onClick={() => toggleDrawer(true)}>
            + Add Variants
          </ButtonWrapper>
        </div>
      </div>

      <div className="mt-5">
        {isLoading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell
                    sx={{ minWidth: 110 }}
                    className="!font-semibold">
                    Frame Image
                  </TableCell>
                  <TableCell
                    sx={{ minWidth: 110 }}
                    className="!font-semibold"
                    align="left">
                    Frame Name
                  </TableCell>
                  <TableCell
                    className="!font-semibold"
                    align="left">
                    Color
                  </TableCell>
                  <TableCell
                    className="!font-semibold"
                    align="left">
                    Description
                  </TableCell>
                  <TableCell
                    className="!font-semibold"
                    align="left">
                    Price
                  </TableCell>
                  <TableCell
                    className="!font-semibold"
                    align="left">
                    Category
                  </TableCell>
                  <TableCell
                    className="!font-semibold"
                    align="center">
                    Edit
                  </TableCell>
                  <TableCell
                    className="!font-semibold"
                    align="center">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      <Drawer
        anchor="right"
        open={drawer.left}
        onClose={() => handleDrawerClose(false)}>
        <DrawerSidebar
          closeButton={() => handleDrawerClose(false)}
          complete={complete}
          variant={selectedVariant}
        />
      </Drawer>
    </>
  );
};

export default ProductImage;
