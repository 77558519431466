import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { couponSchema } from "../../helper/yup.schema";
import {
  ButtonWrapper,
  DashboardDetailsBorder,
  FormInputLabel,
  Head24Tag,
  MainPadding,
  Para14Tag,
  Para16Tag,
  Para20Tag,
} from "../../assets/css/styled";
import { listAllPromoCodes } from "../../redux/actions/promoCodeAction";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxCustom } from "../../components/typography/CheckBox";
import { ErrorToast } from "../../helper/Toast";
import { UploadImagesIcon } from "../../components/typography/Icons";
import { createCoupon, deleteCoupon, listAllCoupon, updateCoupon } from "../../redux/actions/couponAction";

const Coupon = () => {
  const [formInitialValues, setFormInitialValues] = useState({
    title: "",
    description: "",
    PromoCode_id: "",
  });
  const [active, setActive] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [imageURL, setImageURL] = React.useState(null);
  const [imageFile, setImageFile] = React.useState(null);

  const dispatch = useDispatch();

  const { loading, couponList } = useSelector((state) => state.coupons);
  const { promoCodeList } = useSelector((state) => state.promoCodes);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteCoupon({ id }));
  };

  const handleEditClick = (id) => () => {
    const coupon = couponList.find((coupon) => coupon.id === id);
    setFormInitialValues({ ...coupon, PromoCode_id: coupon?.PromoCode_id?._id });
    setImageURL(coupon?.image);
    setActive(coupon?.is_active);
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 120,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`coupon ${params.row.id}`}
        />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "PromoCode_id",
      headerName: "Promo code",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params.value.promo_code,
    },
    {
      field: "is_active",
      headerName: "Active",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddFrameSize = () => {
    setOpen(true);
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setFormInitialValues({
      title: "",
      description: "",
      PromoCode_id: "",
    });
    setImageFile(null);
    setImageURL(null);
    setActive(false);
  };

  const handleSubmit = (values, { resetForm }) => {
    try {
      // Update coupon API
      if (edit) {
        const id = values?.id;

        const updateFormData = new FormData();
        imageFile && updateFormData.append("image", imageFile);
        updateFormData.append("title", values?.title);
        updateFormData.append("description", values?.description);
        updateFormData.append("PromoCode_id", values?.PromoCode_id);
        updateFormData.append("is_active", active);
        dispatch(updateCoupon(updateFormData, id, handleClose));

        return;
      }

      // Create coupon API
      if (!imageFile) {
        ErrorToast("Please select an image!");
        return;
      }
      const newFormData = new FormData();
      newFormData.append("image", imageFile);
      newFormData.append("title", values?.title);
      newFormData.append("description", values?.description);
      newFormData.append("PromoCode_id", values?.PromoCode_id);
      newFormData.append("is_active", active);

      dispatch(createCoupon(newFormData, handleClose));
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageURL(url);
    setImageFile(file);

    // Reset the input value to allow re-selecting the same file
    e.target.value = null;
  };

  const handleRemoveImage = () => {
    setImageURL(null);
    setImageFile(null);
  };

  useEffect(() => {
    if (!open) {
      dispatch(listAllCoupon());
    }
  }, [dispatch, open]);

  useEffect(() => {
    dispatch(listAllPromoCodes());
  }, []);

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header
            title="Coupons"
            subtitle="View All The Coupons"
          />
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddFrameSize}>
              Add New Coupon
            </ButtonWrapper>
          </div>
        </div>
        <Table
          columns={columns}
          rows={couponList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Coupon`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <div className="form_inner_input_div_main">
                <FormInputLabel>Select Image</FormInputLabel>
              </div>
              <div className="technical_information_text_filed">
                <div>
                  <div>
                    <div className="border border-blue-300 rounded-md p-5 my-2">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="thumbnail_upload_image"
                        id="thumbnail_upload_image"
                        accept="image/png, image/jpeg"
                        onChange={handleFile}
                      />
                      <div className="frame_info_images_outer_inner_three_images_show">
                        <label htmlFor="thumbnail_upload_image">
                          {imageURL && (
                            <img
                              style={{ cursor: "pointer" }}
                              src={imageURL}
                              alt="img"
                              className="frame_info_image_inner_open_inner"
                            />
                          )}
                          <div className="frame_info_text_files_name_browse">
                            <UploadImagesIcon />
                            <Para20Tag color={"#000"}>
                              Drop your Files here Or.
                              <span style={{ color: "#219EBC" }}> Browse</span>
                            </Para20Tag>
                          </div>
                          <Para16Tag>You can only select up to one images.</Para16Tag>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame_info_image_inner_open_bottom_div_inner mt-3">
                  {imageURL && (
                    <DashboardDetailsBorder padding={"10px"}>
                      <div
                        style={{ gap: "40px" }}
                        className="frame_show_images_selected_file_inner">
                        <div className="frame_show_images_name_complete_done_div">
                          <img
                            src={imageURL}
                            alt="img"
                            className="frame_show_images_selected_file_inner_outer"
                          />
                        </div>
                        <div className="frame_info_images_inner_open_right_delete_icon_remove">
                          <button
                            className="frame_info_images_remove_icon_inner_delete"
                            onClick={() => handleRemoveImage()}>
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    </DashboardDetailsBorder>
                  )}
                </div>
              </div>

              <Formik
                initialValues={formInitialValues}
                validationSchema={couponSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}>
                {({ touched, errors, setFieldValue }) => (
                  <Form>
                    <div className="grid gap-5">
                      <div>
                        <FormInputLabel>Title</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Title"
                            name="title"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.title && errors.title && <FormErrorMessage errors={errors.title} />}
                      </div>
                      <div>
                        <FormInputLabel>Description</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Description"
                            name="description"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.description && errors.description && <FormErrorMessage errors={errors.description} />}
                      </div>
                      <div>
                        <FormInputLabel>Promo code</FormInputLabel>
                        <div className="flex w-[27.125rem] items-center ">
                          <Field
                            as="select"
                            name="PromoCode_id"
                            // disabled={variant}

                            onChange={(e) => {
                              setFieldValue("PromoCode_id", e.target.value);
                            }}>
                            <option value="">Select a Promo code</option>
                            {!promoCodeList.length
                              ? "Loading..."
                              : promoCodeList?.map((item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={item?.id}>
                                      {item?.promo_code}
                                    </option>
                                  );
                                })}
                          </Field>
                        </div>
                        {touched.PromoCode_id && errors.PromoCode_id && <FormErrorMessage errors={errors.PromoCode_id} />}
                      </div>
                    </div>
                    {/* Active checkbox */}
                    <div className="flex items-center justify-between form_input_forget_text_main_pass_inner mt-4">
                      <div className="form_get_update_text_data_title">
                        <div
                          style={{ cursor: "pointer" }}
                          className="add_new_add_default_address">
                          <label className="flex items-center cursor-pointer">
                            <div className="main_check_box mr-3">
                              <CheckBoxCustom
                                id="is_featured"
                                name="is_featured"
                                checked={active}
                                onChange={() => setActive(!active)}
                              />
                            </div>
                            <div className="label_text">
                              <Para14Tag
                                weight={500}
                                color="#A7ABAC">
                                Active
                              </Para14Tag>
                            </div>
                            {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner mt-5">
                      <ButtonWrapper
                        type="submit"
                        auto={"auto"}>{`${loading ? "Loading..." : edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default Coupon;
