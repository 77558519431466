/**
 * The `FormErrorMessage` component is a React component that displays an error message with an icon.
 * @returns The `FormErrorMessage` component is returning a JSX element. It consists of a `div` element
 * with a class name of "show_error_message" and a `Para12Tag` component inside it. The `Para12Tag`
 * component displays an error message (`errors`) along with an error icon (`FormErrorIcons`).
 */
import React from "react";
import { Para12Tag } from "../assets/css/styled";
import { FormErrorIcons } from "./typography/Icons";

const FormErrorMessage = ({ errors }) => {
  return (
    <>
      <div
        style={{
          marginTop: 4,
        }}
        className="show_error_message"
      >
        <Para12Tag style={{ display: "flex" }} weight="500" color="#FA5252">
        <FormErrorIcons />
          {errors}
        </Para12Tag>
      </div>
    </>
  );
};

export default FormErrorMessage;
