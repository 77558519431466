/**
 * The code defines two React components, `PrivateRouter` and `UsedRouted`, that handle authentication
 * and redirect users to the login page if they are not authenticated.
 * @returns The PrivateRouter component returns the Component passed as a prop, while the UsedRouted
 * component also returns the Component passed as a prop.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "./Auth";
import { routesConfig } from "./config/routes.config";

/**
 * The PrivateRouter component is used to protect routes and redirect users to the login page if they
 * are not authenticated.
 * @returns The PrivateRouter component is returning the Component that is passed as a prop.
 */
export const PrivateRouter = ({ Component }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    // IF USER DON'T HAVE TOKEN THEN HE WILL REDIRECT TO LOGIN PAGE
    if (!Auth?.getAdminToken()) {
      return navigate(routesConfig?.login);
    }
  }, [navigate]);

  return (
    <>
      <Component />
    </>
  );
};

/**
 * The `LoggedRouter` component checks if a user is logged in and redirects them to the home page if
 * they are.
 * @returns The `LoggedRouter` component is returning the `Component` that is passed as a prop.
 */
export const LoggedRouter = ({ Component }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (Auth?.getAdminToken()) {
      return navigate(routesConfig?.homePath);
    }
  }, [navigate]);

  return <Component />;
};
