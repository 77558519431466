/* The code is defining a constant variable `STORAGE_KEYS` and exporting it. The purpose of this code
is not clear without additional context. It seems to be related to storing keys in some kind of
storage system, but without ore information, it is difficult to determine its exact purpose. */
export const STORAGE_KEYS = {
  adminService: "admin_service",
  adminToken: "admin_token",
};

export const SECURITY_KEYS = {
  adminInfo: "admin_info",
  adminToken: "admin_token",
};
