import {
  ADD_BANNER_SUCCESS,
  LIST_ALL_BANNERS_REQUEST,
  LIST_ALL_BANNERS_SUCCESS,
  LIST_ALL_BANNERS_FAILURE,
  UPDATE_BANNER_SUCCESS,
  CREATE_BANNER_SUCCESS,
  DELETE_BANNER_SUCCESS,
  LOADING_TRUE,
  LOADING_FALSE
} from "../constants/banner";

const initialState = {
  bannerList: [],
  loading: false,
  error: null,
};

let bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    case LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };

    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        bannerList: [...state.bannerList, action.payload],
      };

    case LIST_ALL_BANNERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_BANNERS_SUCCESS:
      return {
        ...state,
        bannerList: action?.payload,
        loading: false,
      };

    case LIST_ALL_BANNERS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_BANNER_SUCCESS: {
      const updatedHomeBanner = action.payload.homeBanner;
      return {
        ...state,
        bannerList: state.bannerList.map((homeBanner) => {
          if (homeBanner.id === updatedHomeBanner._id) {
            return {
              ...homeBanner,
              id: updatedHomeBanner?._id,
              banner_image: updatedHomeBanner?.banner_image,
              total_images: updatedHomeBanner?.banner_image?.length,
              position: updatedHomeBanner?.position,
              page: updatedHomeBanner?.page,
              is_active: updatedHomeBanner?.is_active,
              created_at: updatedHomeBanner?.created_at,
            };
          }
          return homeBanner;
        }),
        loading: false,
      };
    }

    case CREATE_BANNER_SUCCESS: {
      const { homeBanner } = action.payload;
      const newHomeBanner = {
        id: homeBanner?._id,
        banner_image: homeBanner?.banner_image,
        total_images: homeBanner?.banner_image?.length,
        position: homeBanner?.position,
        page: homeBanner?.page,
        is_active: homeBanner?.is_active,
        created_at: homeBanner?.created_at,
      };
      return {
        ...state,
        bannerList: [...state.bannerList, newHomeBanner],
        loading: false,
      };
    }

    case DELETE_BANNER_SUCCESS: {
      const bannerId = action.payload;
      return {
        ...state,
        bannerList: state.bannerList.filter((lense) => lense.id !== bannerId),
      };
    }

    default:
      return state;
  }
};

export default bannerReducer;
