/* The code is importing the `Yup` library and assigning it to the variable `Yup`. `Yup` is a
JavaScript schema validation library that allows you to define validation rules for objects. */
import * as Yup from "yup";

/* The code is defining a validation schema for validating user sign-in data. It uses the `Yup` library
to create a validation schema object. The schema specifies the validation rules for the `email`
field in the sign-in form. */
export const signInUserSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Required!"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(50, "Password can be at most 50 characters")
    .required("Password is required"),
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Required!"),
});

export const verifyEmailSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email(message?.YUP_EMAIL)
  //   .required(message?.EMAIL_REQUIRED),
  otp: Yup.string().required("Validation Code is required!"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(50, "Password can be at most 50 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password does not match")
    .required("Confirm password is required"),
});

export const addProductBrandSchema = Yup.object().shape({
  brand_name: Yup.string().required("Brand Name is Required!"),
});

export const addProductCategoriesSchema = Yup.object().shape({
  type: Yup.string().required("Product Categories is Required!"),
});

export const addFrameShapeSchema = Yup.object().shape({
  frame_shape: Yup.string().required("Frame Shape is Required!"),
});

export const addProductSchema = Yup.object().shape({
  product_name: Yup.string().required("Product Name is Required!"),
  description: Yup.string().required("Product Description is Required!"),
  price: Yup.number().min(100).required("Product Price is Required!"),
  condition: Yup.string().required("Product Condition is Required!"),
  brand_type: Yup.string().required("Please Select a Brand Type"),
  type: Yup.string().required("Please Select a Category Type"),
  quantity: Yup.number().required(" Quantity is Required!"),
  weight_group: Yup.string().required(" Weight Group is Required!"),
  material: Yup.string().required(" Material is Required!"),
  temple_material: Yup.string().required(" Temple Material is Required!"),
  collection_name: Yup.string().required(" Collection Name is Required!"),
  Product_Warranty: Yup.string().required("Product Warranty is Required!"),
});

export const addTechnicalInfoSchema = Yup.object().shape({
  modelNo: Yup.string().required("Model Number is Required!"),
  face_type: Yup.string().required("Please Select a Face Type"),
  frame_shape: Yup.string().required("Please Select a Face Shape"),
  frame_Size: Yup.string().required("Please Select a Frame Size"),
  frame_Material: Yup.string().required("Please Select a Frame Material"),
  frameWeight: Yup.number().required("Frame Weight is Required!"),
  frameHeight: Yup.number().required("Frame Height is Required!"),
  frameDimensions: Yup.string().required("Frame Dimensions is Required!"),
  gender: Yup.string().required(" Please Select Gender"),
  frameWidth: Yup.number().required("Frame Width is Required!"),
  frame_type: Yup.string().required("Please Select a Frame Type "),
  frameStyle: Yup.string().required("Please Select a Frame Style"),
  frame_style_secondary: Yup.string().required(
    "Please Select a Secondary Frame Style"
  ),
});

//* YUG => newProductForm
export const newProductForm = Yup.object().shape({
  product_name: Yup.string().required("Product Name is Required!"),
  description: Yup.string().required("Product Description is Required!"),
  price: Yup.number().min(100).required("Product Price is Required!"),
  condition: Yup.string().required("Product Condition is Required!"),
  brand_type: Yup.string().required("Please Select a Brand Type"),
  type: Yup.string().required("Please Select a Category Type"),
  quantity: Yup.number().required("Quantity is Required!"),
  weight_group: Yup.string().required("Weight Group is Required!"),
  material: Yup.string().required("Material is Required!"),
  temple_material: Yup.string().required("Temple Material is Required!"),
  collection_name: Yup.string().required("Collection Name is Required!"),
  Product_Warranty: Yup.string().required("Product Warranty is Required!"),
  modelNo: Yup.string().required("Model Number is Required!"),
  face_type: Yup.string().required("Please Select a Face Type"),
  frame_shape: Yup.string().required("Please Select a Face Shape"),
  frame_Size: Yup.string().required("Please Select a Frame Size"),
  frame_Material: Yup.string().required("Please Select a Frame Material"),
  frameWeight: Yup.number().required("Frame Weight is Required!"),
  frameHeight: Yup.number().required("Frame Height is Required!"),
  frameDimensions: Yup.string().required("Frame Dimensions is Required!"),
  gender: Yup.string().required("Please Select Gender"),
  frameWidth: Yup.number().required("Frame Width is Required!"),
  frame_type: Yup.string().required("Please Select a Frame Type "),
  // thumbnailImage: Yup.mixed().required("ThumbnailImage is required"),
});

export const brandSchema = Yup.object().shape({
  brand_name: Yup.string().required("Brand Name is Required!"),
  is_activated: Yup.boolean(),
});

export const categorySchema = Yup.object().shape({
  type: Yup.string().required("Category type is required!"),
  description: Yup.string().required("Category description is required!"),
});

export const lenseSchema = Yup.object().shape({
  prescription_type: Yup.string().required(
    "Lense prescription type is required!"
  ),
  description: Yup.string().required("Lense description is required!"),
  price: Yup.string().required("Lense price is required!"),
  colors: Yup.array().min(1, "Select at least one color"),
});

export const singleVisionTypeSchema = Yup.object().shape({
  single_vision_type: Yup.string().required("Single vision type is required!"),
  description: Yup.string().required("Description is required!"),
  price: Yup.string().required("Price is required!"),
});

export const lensesTypesSchema = Yup.object().shape({
  lenses_type: Yup.string().required("Lense type is required!"),
  description: Yup.string().required("Lense description is required!"),
  price: Yup.string().required("Lense price is required!"),
  colors: Yup.array(),
});

export const materialTypesSchema = Yup.object().shape({
  lens_material_type: Yup.string().required("Lense type is required!"),
  description: Yup.string().required("Lense description is required!"),
  price: Yup.string().required("Lense price is required!"),
});

export const trendingSearchSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
});

export const newsLetterSchema = Yup.object().shape({
  email: Yup.string().required("Email is required!"),
});

export const bannerSchema = Yup.object().shape({
  position: Yup.number()
    .typeError("Must be a number")
    .positive()
    .required("Banner position is required!"),
  page: Yup.string().required("Banner page is required!"),
});

export const wearByTrendSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  gender: Yup.string().required("Please select a gender!"),
});

export const faceTypeSchema = Yup.object().shape({
  face_type: Yup.string().required("Face type is required!"),
});

export const frameShapeSchema = Yup.object().shape({
  frame_shape: Yup.string().required("Frame Shape is required!"),
});

export const frameTypeSchema = Yup.object().shape({
  frame_type: Yup.string().required("Frame type is required!"),
});

export const frameMaterialSchema = Yup.object().shape({
  frame_material: Yup.string().required("Frame material is required!"),
});

export const frameSizeSchema = Yup.object().shape({
  frame_size: Yup.string().required("Frame size is required!"),
});

export const accessorySchema = Yup.object().shape({
  name: Yup.string().required("Accessory type is required!"),
  price: Yup.string().required("Accessory description is required!"),
  color_id: Yup.string().required("Accessory color is required!"),
});

export const blogSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  author: Yup.string().required("Author is required!"),
  meta_title: Yup.string().required("Meta title is required!"),
  meta_description: Yup.string().required("Meta description is required!"),
  meta_tag: Yup.string().required("Meta tag is required!"),
  // category_id: Yup.string().required("Category is required!"),
});

export const baseColorSchema = Yup.object().shape({
  color_name: Yup.string().required("Color name is required!"),
});

export const promoCodeSchema = Yup.object().shape({
  promo_code: Yup.string().required("Promo Code is required!"),
  discount_per: Yup.string().required("Discount is required!"),
  upto_amount: Yup.string().required("Amount is required!"),
  limit: Yup.number().required("Limit is required!"),
  promocode_category: Yup.string().required("Promocode category is required!"),
  is_public: Yup.boolean(),
  is_custom: Yup.boolean(),
  is_active: Yup.boolean(),
});

export const couponSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  description: Yup.string().required("Description is required!"),
  PromoCode_id: Yup.string().required("Please select a promo code!"),
});

export const walletBalanceSchema = Yup.object().shape({
  walletBalance: Yup.number().required("Wallet Balance Schema is required!"),
});

export const productFrameSchema = Yup.object().shape({
  product_name: Yup.string().required("Product Name is Required!"),
  // description: Yup.string().required("Product Description is Required!"),
  model_no: Yup.string().required("Model Number is Required!"),
  price: Yup.number()
    .min(100)
    .required("Product Price is Required!")
    .positive("Must be a positive number"),
  category_id: Yup.string().required("Please Select a Category!"),
  frame_type_id: Yup.string().required("Please Select a Frame Type"),
  frame_shape_id: Yup.string().required("Please Select a Frame Shape"),
  frame_material_id: Yup.string().required("Please Select a Frame Material"),
  condition: Yup.string().required("Product Condition is Required!"),
  collection_name: Yup.string().required("Collection Name is Required!"),
  age_range: Yup.string().required("Age range is Required!"),
  material: Yup.string().required("Material is Required!"),
  temple_material: Yup.string().required("Temple Material is Required!"),
  product_warranty: Yup.string().required("Product Warranty is Required!"),
  is_latest: Yup.boolean(),
  is_featured: Yup.boolean(),
  is_trending: Yup.boolean(),
  is_popular: Yup.boolean(),
  is_warranty: Yup.boolean(),
  is_active: Yup.boolean(),
  // is_deleted: Yup.boolean(),
  // TODO: Array
  face_id: Yup.array().min(1, "Select at least one face type"),
  // gender: Yup.array().of(Yup.string().required("Please Select Gender")),
  gender: Yup.array().min(1, "Select at least one gender"),
});

export const productImageSchema = Yup.object().shape({
  color_id: Yup.string().required("Please Select a Color!"),
});

export const productFrameSizeSchema = Yup.object({
  color_id: Yup.string().required("Please Select a color"),
  description: Yup.string().required("Description is Required!"),
  frameSizes: Yup.array().of(
    Yup.object().shape({
      frame_size_id: Yup.string().required("Please Select a Frame Size"),
      frame_width: Yup.number().required("Frame Width is Required!"),
      frame_height: Yup.number().required("Frame Height is Required!"),
      frame_weight: Yup.number().required("Frame Weight is Required!"),
      frame_dimensions: Yup.string().required("Frame Dimensions is Required!"),
      lens_width: Yup.number().required("Lens width is Required!"),
      sku_no: Yup.string().required("Frame SKU no. is Required!"),
      quantity: Yup.number().required("Quantity is Required!"),
      in_stock: Yup.boolean(),
      is_active: Yup.boolean(),
    })
  ),
});

export const orderSchema = Yup.object().shape({
  orderId: Yup.string(),
  status: Yup.string().required("Status is required!"),
});
