import React from "react";
import { Avatar, Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { createAccessory, deleteAccessory, listAllAccessories, updateAccessory } from "../../redux/actions/accessoryAction";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../components/Table";
import { accessorySchema } from "../../helper/yup.schema";
import {
  Para16Tag,
  Para20Tag,
  DashboardDetailsBorder,
  ButtonWrapper,
  FormInputLabel,
  Head24Tag,
  MainPadding,
  Para14Tag,
  ColorWrapper,
  Para18Tag,
} from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { DeleteIcons, UploadImagesIcon } from "../../components/typography/Icons";
import { ErrorToast } from "../../helper/Toast";
import { CheckBoxCustom } from "../../components/typography/CheckBox";
import { ApiGetAllColor } from "../../helper/API/APICall";
import uploadImages from "../../assets/images/uploadImages.png";

const Accessory = () => {
  const [formInitialValues, setFormInitialValues] = React.useState({
    name: "",
    price: "",
    colors: "",
  });
  const [isLoadingAPI, setIsLoadingAPI] = React.useState(false);
  const [selectedAccessoryId, setSelectedAccessoryId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [selectedImages, setSelectedImages] = React.useState([]);
  const [selectedImagesSet, setSelectedImagesSet] = React.useState([]);
  // const [imageURL, setImageURL] = React.useState(null);
  // const [imageFile, setImageFile] = React.useState(null);
  // const [colors, setColors] = React.useState([]);
  const [active, setActive] = React.useState(false);

  const dispatch = useDispatch();
  const [colorList, setColorList] = React.useState([]);
  const [selectedColor, setSelectedColor] = React.useState(null);
  const { accessoriesList } = useSelector((state) => state.accessories);

  const getAllBaseColors = async () => {
    try {
      const response = await ApiGetAllColor(true);
      const data = [];
      response?.data?.map((x) => {
        return data.push({
          id: x?._id,
          color_name: x?.color_name,
          image: x?.image,
        });
      });
      setColorList(data);
    } catch (error) {
      throw new Error(error)
    }
  };

  React.useEffect(() => {
    getAllBaseColors();
  }, []);

  React.useEffect(() => {
    dispatch(listAllAccessories());
  }, [dispatch, open]);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteAccessory({ id }));
  };

  const handleColorChange = (e) => {
    colorList?.forEach((colors) => {
      if (e.target.value === colors?.id) {
        setSelectedColor(colors?.image);
      } else if (e.target.value === "") setSelectedColor(null);
    });
  };

  const handleEditClick = (id) => () => {
    setFormInitialValues(accessoriesList.find((category) => category.id === id));
    setSelectedAccessoryId(id);
    accessoriesList.map((x) => {
      if (x.id === id) {
        setFormInitialValues({
          name: x?.name,
          price: x?.price,
          color_id: x?.colors?.[0]?.color_id?._id,
        });
        // setImageURL(x?.image);
        setSelectedImages(x?.image);
        setSelectedColor(x?.colors?.[0]?.color_id?.image);
      }
      setActive(x?.is_active);
    });
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 120,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={params.value?.[0]}
          alt={`Accessory ${params.row.id}`}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
    },
    {
      field: "colors",
      headerName: "Color",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
      renderCell: (params) => {
        const { value: colors } = params;
        return (
          <div>
            {colors.length ? (
              <img
                src={colors?.[0]?.color_id?.image}
                alt={`Accessory ${params.row.id}`}
                style={{ width: "35px", height: "35px", borderRadius: "50%" }}
              />
            ) : (
              <p>No color</p>
            )}
          </div>
        );
      },
    },
    {
      field: "is_active",
      headerName: "Active",
      headerAlign: "center",
      flex: 1,
      align: "center",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddAccessory = () => {
    setOpen(true);
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setActive(false);
    // setImageFile(null);
    // setImageURL(null);
    setSelectedImages([]);
    setSelectedImagesSet([]);
    setFormInitialValues({
      name: "",
      price: "",
      color_id: "",
    });
    setSelectedColor(null);
    setSelectedAccessoryId(null);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoadingAPI(true);
    try {
      if (edit) {
        const updateFormData = new FormData();
        updateFormData.append("name", values?.name);
        updateFormData.append("price", values?.price);
        updateFormData.append("colors", JSON.stringify([{ color_id: values?.color_id }]));
        updateFormData.append("is_active", active);
        // imageFile && updateFormData.append("image", imageFile);
        selectedImagesSet.length > 0 &&
          selectedImagesSet?.forEach((image) => {
            updateFormData.append("image", image);
          });
        for (const key of updateFormData.entries()) {
        }
        const response = await dispatch(updateAccessory(updateFormData, selectedAccessoryId));
        if (response?.data?.message?.status === 200) {
          handleClose();
        }
        setIsLoadingAPI(false);
        return;
      }
      if (selectedImagesSet.length < 1) {
        ErrorToast("Please select at least 1 image!");
        setIsLoadingAPI(false);
        return;
      }
      const newFormData = new FormData();
      newFormData.append("name", values?.name);
      newFormData.append("price", values?.price);
      newFormData.append("colors", JSON.stringify([{ color_id: values?.color_id }]));
      newFormData.append("is_active", active);
      // newFormData.append("image", imageFile);
      selectedImagesSet.length > 0 &&
        selectedImagesSet?.forEach((image) => {
          newFormData.append("image", image);
        });
      for (const key of newFormData.entries()) {
      }
      const response = await dispatch(createAccessory(newFormData));
      if (response?.data?.message?.status === 200) {
        handleClose();
      }
      setIsLoadingAPI(false);
      return;
    } catch (error) {
      setIsLoadingAPI(false);
    }
  };

  // const handleFile = (e) => {
  //   const file = e.target.files[0];
  //   const url = URL.createObjectURL(file);
  //   setImageURL(url);
  //   setImageFile(file);

  //   // Reset the input value to allow re-selecting the same file
  //   e.target.value = null;
  // };

  const handleImageChange = (event) => {
    const files = event?.target?.files;

    const newImages = Array.from(files).map((file) => ({
      url: URL.createObjectURL(file),
      name: file?.name,
      size: file?.size,
    }));

    // Add new images to the selectedImages array
    setSelectedImages([...selectedImages, ...newImages]);
    setSelectedImagesSet([...selectedImagesSet, ...files]);

    event.target.value = null;
  };

  const handleRemoveImage = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  const handleRemoveImageSet = (index) => {
    const updatedImagesSet = selectedImagesSet.filter((_, i) => i !== index);
    setSelectedImagesSet(updatedImagesSet);
  };

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header
            title="Accessories"
            subtitle="View All The Accessories"
          />
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddAccessory}>
              Add Accessory
            </ButtonWrapper>
          </div>
        </div>
        <Table
          columns={columns}
          rows={accessoriesList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Accessory`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <div className="form_inner_input_div_main">
                <FormInputLabel>Select Accessory Image</FormInputLabel>
              </div>

              <div>
                <div className="mb-4">
                  <div>
                    <div className="border border-blue-300 rounded-md p-5 my-2">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="upload_image"
                        id="upload_image"
                        accept="image/png, image/jpeg"
                        multiple
                        onChange={(e) => handleImageChange(e)}
                      />
                      <div className="frame_info_images_outer_inner_three_images_show">
                        <label htmlFor="upload_image">
                          <img
                            style={{
                              cursor: "pointer",
                            }}
                            src={uploadImages}
                            alt="img"
                            className="frame_info_image_inner_open_inner"
                          />
                          <div className="frame_info_text_files_name_browse">
                            <UploadImagesIcon />
                            <Para20Tag color={"#000"}>
                              Drop your Files here Or.
                              <span style={{ color: "#219EBC" }}> Browse</span>
                            </Para20Tag>
                          </div>
                          {/* <Para16Tag>You can only select up to one images.</Para16Tag> */}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedImages.length > 0 && (
                  <div className="frame_info_image_inner_open_bottom_div_delete_icon  mt-5">
                    <div className="frame_info_image_inner_open_bottom_div_inner">
                      {selectedImages?.map((image, index) => (
                        <DashboardDetailsBorder padding={"10px"}>
                          <div
                            className="frame_show_images_selected_file_inner"
                            key={index}>
                            <div className="frame_show_images_name_complete_done_div">
                              <img
                                src={image?.url || image}
                                alt="img"
                                className="frame_show_images_selected_file_inner_outer"
                              />
                              {image?.name && (
                                <div className="add_product_size_images">
                                  <Para18Tag>{image?.name}</Para18Tag>
                                  <Para14Tag weight={600}>{`${(image?.size / 1024).toFixed(0)} KB`} </Para14Tag>
                                </div>
                              )}
                            </div>

                            <div className="frame_info_images_inner_open_right_delete_icon_remove">
                              <button
                                className="frame_info_images_remove_icon_inner_delete"
                                type="button"
                                onClick={() => {
                                  handleRemoveImage(index);
                                  image?.url && handleRemoveImageSet(index);
                                  handleDeleteClick(image);
                                }}>
                                <DeleteIcons />
                              </button>
                            </div>
                          </div>
                        </DashboardDetailsBorder>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <Formik
                initialValues={formInitialValues}
                validationSchema={accessorySchema}
                onSubmit={handleSubmit}>
                {({ touched, errors, setFieldValue }) => (
                  <Form>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Accessory Name</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Accessory Name"
                            name="name"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.name && errors.name && <FormErrorMessage errors={errors.name} />}
                      </div>
                      <div className="form_inner_input_div_main mt-4">
                        <FormInputLabel>Color</FormInputLabel>
                        <div className=" flex gap-4 items-center ">
                          <div className="flex w-[27.125rem] items-center ">
                            <Field
                              as="select"
                              name="color_id"
                              onChange={(e) => {
                                handleColorChange(e);
                                setFieldValue("color_id", e.target.value);
                              }}>
                              <option value="">Select a Color</option>
                              {!colorList.length
                                ? "Loading..."
                                : colorList?.map((value, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={value?.id}>
                                        <Avatar
                                          size="sm"
                                          src={value?.image}
                                        />
                                        <img
                                          src={value?.image}
                                          alt=""
                                        />
                                        {value?.color_name}
                                      </option>
                                    );
                                  })}
                            </Field>
                          </div>
                          {/* <span style={{ display: "inline-block" }}>
                          {selectedColor && (
                            <img
                              style={{ height: "32px", width: "32px", borderRadius: "50%" }}
                              src={selectedColor}
                              alt={""}
                            />
                          )}
                        </span> */}
                          {/* <span style={{ display: "inline-block" }}> */}
                          {selectedColor && (
                            <ColorWrapper
                              src={selectedColor && selectedColor}
                              style={{ display: "inline-block" }}
                              // $color_img={selectedColor}
                              // role="button"
                            />
                          )}
                          {/* </span> */}
                        </div>
                        {touched.color_id && errors.color_id && <FormErrorMessage errors={errors.color_id} />}
                      </div>
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Accessory price</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Accessory price"
                            name="price"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.price && errors.price && <FormErrorMessage errors={errors.price} />}
                      </div>
                      {/* Active checkbox */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="form_input_forget_text_main_pass_inner">
                        <div className="form_get_update_text_data_title">
                          <div
                            style={{ cursor: "pointer" }}
                            className="add_new_add_default_address">
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}>
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                className="main_check_box">
                                <CheckBoxCustom
                                  id="is_active"
                                  name="is_active"
                                  checked={active}
                                  onChange={() => setActive(!active)}
                                />
                              </div>
                              <div className="label_text">
                                <Para14Tag
                                  weight={500}
                                  color="#A7ABAC">
                                  Active
                                </Para14Tag>
                              </div>
                              {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner_update mt-4">
                      <ButtonWrapper auto={"auto"}>{isLoadingAPI ? "Loading..." : `${edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default Accessory;
