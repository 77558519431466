import {
  ADD_COLOR_REQUEST,
  ADD_COLOR_SUCCESS,
  ADD_COLOR_FAILURE,
  GET_ALL_COLOR_REQUEST,
  GET_ALL_COLOR_SUCCESS,
  GET_ALL_COLOR_FAILURE,
} from "../../helper/config/constantsRedux.config";

export const addColorReducer = (state = { colorAdd: [] }, action) => {
  switch (action.type) {
    case ADD_COLOR_REQUEST:
      return { ...state, loading: true };
    case ADD_COLOR_SUCCESS:
      return {
        loading: false,
        colorAdd: action?.payload,
      };
    case ADD_COLOR_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};

export const getAllColorReducer = (state = { colorAllGet: [] }, action) => {
  switch (action?.type) {
    case GET_ALL_COLOR_REQUEST:
      return {
        loading: true,
        colorAllGet: [],
      };
    case GET_ALL_COLOR_SUCCESS:
      return {
        loading: false,
        colorAllGet: action?.payload,
      };
    case GET_ALL_COLOR_FAILURE:
      return {
        loading: false,
        error: action?.payload,
      };

    default:
      return state;
  }
};
