/**
 * This is a React component that renders a checkbox with a custom style.
 * @returns The CheckBox component is being returned.
 */
import React from 'react';
import { CheckboxContainer, CheckboxIcon, HiddenCheckbox, HiddenCheckboxCustom, StyledCheckbox } from '../../assets/css/styled';

export const CheckBox = ({ id, name, className }) => {
  return (
    <>
      <CheckboxContainer className={className}>
        <HiddenCheckbox
          type="checkbox"
          id={id}
          name={name}
        />
        <StyledCheckbox>
          <CheckboxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </CheckboxIcon>
        </StyledCheckbox>
      </CheckboxContainer>
    </>
  );
};

export const CheckBoxCustom = ({ id, name, className, checked, ...props }) => {
  return (
    <>
      <CheckboxContainer className={className}>
        <HiddenCheckboxCustom
          id={id}
          name={name}
          checked={checked}
          {...props}
        />
        <StyledCheckbox checked={checked}>
          <CheckboxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </CheckboxIcon>
        </StyledCheckbox>
      </CheckboxContainer>
    </>
  );
};
