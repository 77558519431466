import {
  ADD_ACCESSORY_SUCCESS,
  CREATE_ACCESSORY_SUCCESS,
  DELETE_ACCESSORY_SUCCESS,
  LIST_ALL_ACCESSORIES_REQUEST,
  LIST_ALL_ACCESSORIES_SUCCESS,
  LIST_ALL_ACCESSORIES_FAILURE,
  UPDATE_ACCESSORY_SUCCESS,
} from "../constants/accessory";

const initialState = {
  accessoriesList: [],
  loading: false,
  error: null,
};

let accessoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACCESSORY_SUCCESS:
      return {
        ...state,
        accessoriesList: [...state.accessoriesList, action.payload],
      };

    case LIST_ALL_ACCESSORIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_ACCESSORIES_SUCCESS:
      return {
        ...state,
        accessoriesList: action?.payload,
        loading: false,
      };

    case LIST_ALL_ACCESSORIES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_ACCESSORY_SUCCESS: {
      const updatedAccessory = action.payload.accessory;
      return {
        ...state,
        accessoriesList: state.accessoriesList.map((accessory) => {
          if (accessory.id === updatedAccessory._id) {
            return {
              ...accessory,
              image: updatedAccessory.image,
              name: updatedAccessory.name,
              description: updatedAccessory.description,
              price: updatedAccessory.price,
              colors: updatedAccessory.colors,
              is_active: updatedAccessory.is_active,
            };
          }
          return accessory;
        }),
      };
    }

    case CREATE_ACCESSORY_SUCCESS: {
      const { accessory } = action.payload;
      const newAccessory = {
        id: accessory._id,
        image: accessory.image,
        name: accessory.name,
        description: accessory.description,
        price: accessory.price,
        colors: accessory.colors,
        is_active: accessory.is_active,
      };
      return {
        ...state,
        accessoriesList: [...state.accessoriesList, newAccessory],
      };
    }

    case DELETE_ACCESSORY_SUCCESS: {
      const accessoryId = action.payload;
      return {
        ...state,
        accessoriesList: state.accessoriesList.filter((accessory) => accessory.id !== accessoryId),
      };
    }
    default:
      return state;
  }
};

export default accessoryReducer;
