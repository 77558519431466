import { AdminAPI } from "../../helper/API/API.base";
import { getFormDataHeaders, getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  CREATE_LENSES_SUCCESS,
  DELETE_LENSE_SUCCESS,
  LIST_ALL_LENSE_FAILURE,
  LIST_ALL_LENSE_REQUEST,
  LIST_ALL_LENSE_SUCCESS,
  UPDATE_LENSES_SUCCESS,
} from "../constants/lense";

export const listAllLenses = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_LENSE_REQUEST });

      // header
      const headers = await getHeaders();

      // API
      const response = await AdminAPI.get("/lenses", { headers });

      const lensesList = [];
      response?.data?.forEach((x) => {
        lensesList.push({
          id: x._id,
          prescription_type: x.prescription_type,
          description: x.description,
          price: x.price,
          colors: x.colors,
          image: x.image,
          is_active: x.is_active,
        });
      });

      dispatch({
        type: LIST_ALL_LENSE_SUCCESS,
        payload: lensesList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_LENSE_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateLense = (values, lenseId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/lenses/${lenseId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_LENSES_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Lense updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteLense = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const lenseId = values.id;

      // API
      const response = await AdminAPI.delete(`/lenses/${lenseId}`, { headers });

      dispatch({
        type: DELETE_LENSE_SUCCESS,
        payload: lenseId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Lense deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createLense = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getFormDataHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/lenses`, body, { headers });

      dispatch({
        type: CREATE_LENSES_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Lense added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
