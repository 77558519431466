import Cookies from "js-cookie";

const COOKIE_OPTIONS = { sameSite: "strict", secure: true };

export const setCookie = (key, value, expires = 1) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expires);

  Cookies.set(key, value, {
    expires: expirationDate,
    ...COOKIE_OPTIONS,
  });
};
