import { AdminAPI, AdminCommonAPI } from "../../helper/API/API.base";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import {
  LIST_ALL_FACE_TYPES_SUCCESS,
  UPDATE_FACE_TYPE_SUCCESS,
  LIST_ALL_FACE_TYPES_REQUEST,
  LIST_ALL_FACE_TYPES_FAILURE,
  DELETE_FACE_TYPE_SUCCESS,
  CREATE_FACE_TYPE_SUCCESS,
} from "../constants/faceTypes";
import { toastMessage } from "../../helper/config/toastMessage.config";
import { getHeaders } from "../../helper/API/API.header";

export const listAllFaceTypes = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: LIST_ALL_FACE_TYPES_REQUEST });

      // API
      const response = await AdminAPI.get("/faces", { headers });

      const faceTypesList = [];
      response?.data?.map((x) => {
        faceTypesList.push({
          id: x._id,
          face_type: x.face_type,
          is_active: x.is_active,
        });
      });

      dispatch({
        type: LIST_ALL_FACE_TYPES_SUCCESS,
        payload: faceTypesList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_FACE_TYPES_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateFaceType = (values, faceTypeId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/faces/${faceTypeId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_FACE_TYPE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Face type updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteFaceType = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const faceTypeId = values.id;

      // API
      const response = await AdminAPI.delete(`/faces/${faceTypeId}`, {
        headers,
      });

      dispatch({
        type: DELETE_FACE_TYPE_SUCCESS,
        payload: faceTypeId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Face type deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createFaceType = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = {
        face_type: values?.face_type,
      };

      // API
      const response = await AdminAPI.post(`/faces`, body, { headers });

      dispatch({
        type: CREATE_FACE_TYPE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Face type added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
