import React, { useEffect, useState } from "react";
import "./orderDetails.css";
import * as styled from "../../assets/css/styled";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { ApiGetById } from "../../helper/API/APICall";
import moment from "moment";
import * as material from "@mui/material";
// import { Viewer, Worker } from "@react-pdf-viewer/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InVoicePdf from "../../components/InVoicePdf";

const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState({});
  console.log("orderDetails", orderDetails)
  const [base64String, setBase64String] = React.useState("");
  const [decodedPDF, setDecodedPDF] = React.useState("");

  const { id } = useParams();

  useEffect(() => {
    const handleFetchOrderDetails = async () => {
      const data = await ApiGetById("/order", id);

      const items = data?.data?.items?.reverse();
      setOrderDetails({ ...data?.data, items: items });
      setBase64String(data?.data?.shipping_lable || "");
      const decodePdf = data?.data?.shipping_lable
        ? "data:application/pdf;base64," + data?.data?.shipping_lable
        : "";
      setDecodedPDF(decodePdf);
    };

    handleFetchOrderDetails();
  }, [id]);

  const downloadFileObject = (base64String) => {
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = `order_${orderDetails?._id}_${orderDetails?.shipping_address_data?.firstname}_${orderDetails?.shipping_address_data?.lastname}_shipping_label.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadAsPDF = () => {
    if (base64String.startsWith("JVB")) {
      const formattedBase64String =
        "data:application/pdf;base64," + base64String;
      downloadFileObject(formattedBase64String);
    } else if (base64String.startsWith("data:application/pdf;base64")) {
      downloadFileObject(base64String);
    } else {
      alert("Not a valid Base64 PDF string. Please check");
    }
  };

  return (
    <>
      <styled.MainPadding p_bottom={20} p_top={20}>
        <Box m="20px" mb={0} mt={0}>
          <div className="flex items-center justify-between">
            <Header title="Orders Details" />
            <PDFDownloadLink
              document={<InVoicePdf item={orderDetails} />}
              fileName="optics_haven_invoice.pdf"
            >
              {({ blob, url, loading, error }) => (
                <styled.ButtonWrapper>
                  {loading ? "Loading document..." : "Download Invoice"}
                </styled.ButtonWrapper>
              )}
            </PDFDownloadLink>
          </div>
          <styled.DashboardDetailsBorder className="mt-4" padding={"20px"}>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <styled.Para16Tag>
                  <span className="text-[#2E3233] font-medium">Order ID :</span>{" "}
                  <span className="font-bold">{orderDetails?._id}</span>
                </styled.Para16Tag>
                <styled.Para16Tag>
                  <span className="text-[#2E3233] font-medium">
                    Order Date :
                  </span>
                  <span className="font-bold">
                    {moment(orderDetails.created_at).format("ll")}
                  </span>
                </styled.Para16Tag>
              </div>
              <styled.ColorBoxDiv $bg_color="#fb85000f">
                <styled.Para16Tag $weight="500" color="#FB8500">
                  {orderDetails?.order_status?.charAt(0)?.toUpperCase() +
                    orderDetails?.order_status?.slice(1)}
                </styled.Para16Tag>
              </styled.ColorBoxDiv>
            </div>
            <styled.BorderDivider m_bottom={20} mTop={20} />

            {orderDetails?.items?.map((x, index) => {
              let product = {
                name: "",
                lenseName: "",
                image: "",
                useMyFrameImages: [],
                color: "",
                size: "",
                model: "",
                sku: "",
                price: x?.product_price || x?.accessory_price || 0,
              };
              if (Object.hasOwnProperty.call(x, "product_image")) {
                product.image = x?.product_image;
                product.name = x?.product_name;
                product.size = x?.product_size_name;
                product.model = x?.product_model_no;
                product.color = x?.product_color;
                product.sku = x?.product_sku_no;
                if (x?.lenses?.lense_id) {
                  product.lenseName =
                    product.lenseName + x?.lenses?.lense_id?.prescription_type;
                  product.price = product.price + x?.lenses?.lens_price;
                }
                if (x?.lenses?.singlevision_id) {
                  product.lenseName =
                    product.lenseName +
                    " + " +
                    x?.lenses?.singlevision_id?.single_vision_type;
                  product.price = product.price + x?.lenses?.singlevision_price;
                }
                if (x?.lenses?.lense_type_id) {
                  product.lenseName =
                    product.lenseName +
                    " + " +
                    x?.lenses?.lense_type_id?.lenses_type;
                  product.price = product.price + x?.lenses?.lens_type_price;
                }
                if (x?.lenses?.lens_type_color_id) {
                  product.lenseName =
                    product.lenseName +
                    " + " +
                    x?.lenses?.lens_type_color_id?.color_name;
                }
                if (x?.lenses?.material_type_id) {
                  product.lenseName =
                    product.lenseName +
                    " + " +
                    x?.lenses?.material_type_id?.lens_material_type;
                  product.price =
                    product.price + x?.lenses?.material_type_price;
                }
              } else if (Object.hasOwnProperty.call(x, "accessory_image")) {
                product.image = x.accessory_image;
                product.name = x?.accessory_name;
              } else if (x?.lenses?.is_use_my_frame) {
                product.name = x?.lenses?.use_my_frame_id?.frame_name;
                x?.lenses?.use_my_frame_id?.frame_image?.forEach((x, index) =>
                  product.useMyFrameImages.push(x)
                );
                if (x?.lenses?.lense_id) {
                  product.lenseName =
                    product.lenseName + x?.lenses?.lense_id?.prescription_type;
                  product.price = product.price + x?.lenses?.lense_id?.price;
                }
                if (x?.lenses?.singlevision_id) {
                  product.lenseName =
                    product.lenseName +
                    " + " +
                    x?.lenses?.singlevision_id?.single_vision_type;
                  product.price =
                    product.price + x?.lenses?.singlevision_id?.price;
                }
                if (x?.lenses?.lense_type_id) {
                  product.lenseName =
                    product.lenseName +
                    " + " +
                    x?.lenses?.lense_type_id?.lenses_type;
                  product.price =
                    product.price + x?.lenses?.lense_type_id?.price;
                }
                if (x?.lenses?.lens_type_color_id) {
                  product.lenseName =
                    product.lenseName +
                    " + " +
                    x?.lenses?.lens_type_color_id?.color_name;
                }
                if (x?.lenses?.material_type_id) {
                  product.lenseName =
                    product.lenseName +
                    " + " +
                    x?.lenses?.material_type_id?.lens_material_type;
                  product.price =
                    product.price + x?.lenses?.material_type_id?.price;
                }
              }
              return (
                <div className="flex items-center justify-between gap-4 mb-4">
                  <div
                    className={`my_order_main_details_status_order_details ${
                      product?.useMyFrameImages?.length
                        ? "flex-col !items-start"
                        : ""
                    }`}
                    key={index}
                  >
                    <div className="flex flex-wrap gap-2">
                      {product?.useMyFrameImages?.length ? (
                        product?.useMyFrameImages.map((image, index) => {
                          return (
                            <img
                              key={index}
                              loading="lazy"
                              src={image}
                              className="my_order_main_details_status_order_image w-[200px] h-[200px] object-contain"
                              alt=""
                            />
                          );
                        })
                      ) : (
                        <img
                          loading="lazy"
                          src={product.image}
                          className="my_order_main_details_status_order_image w-[98px] h-[82px] object-contain"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="order_details_main_details_status_order_details_data">
                      <styled.Para20Tag color="#2E3233" weight="600">
                        {product.name ? product.name : product.lenseName}
                      </styled.Para20Tag>
                      {product.lenseName && (
                        <styled.Para16Tag color="#2E3233" weight="500">
                          {`Lens: ${product.lenseName}`}
                        </styled.Para16Tag>
                      )}
                      {product.color && (
                        <styled.Para16Tag color="#2E3233" weight="500">
                          {`Color: ${product.color}`}
                        </styled.Para16Tag>
                      )}
                      {product.size && (
                        <styled.Para16Tag color="#2E3233" weight="500">
                          {`Size: ${product.size}`}
                        </styled.Para16Tag>
                      )}
                      {product.model && (
                        <styled.Para16Tag color="#2E3233" weight="500">
                          {`Model: ${product.model}`}
                        </styled.Para16Tag>
                      )}
                      {product.sku && (
                        <styled.Para16Tag color="#2E3233" weight="500">
                          {`Sku: ${product.sku}`}
                        </styled.Para16Tag>
                      )}
                      <styled.Para16Tag color="#2E3233" weight="500">
                        {`Price: $${product.price}`}
                      </styled.Para16Tag>
                      <styled.Para16Tag color="#219EBC" weight="400">
                        Sold by Optics Haven
                      </styled.Para16Tag>
                    </div>
                  </div>
                  {x?.prescription_data?.is_image_prescription ? (
                    <div>
                      <styled.Para18Tag
                        className="mb-2"
                        color="#2E3233"
                        weight="500"
                      >
                        Prescription Image :
                      </styled.Para18Tag>
                      <img
                        loading="lazy"
                        src={x?.prescription_data?.image_prescription}
                        className="my_order_main_details_status_order_image object-contain max-w-[20rem]"
                        alt=""
                      />
                    </div>
                  ) : x?.prescription_data ? (
                    <div>
                      <styled.Para18Tag
                        className="mb-2"
                        color="#2E3233"
                        weight="500"
                      >
                        Prescription :
                      </styled.Para18Tag>
                      <material.Paper
                        sx={{
                          maxWidth: "100%",
                          overflow: "hidden",
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        {/* <styled.TableWrapper>
                          <material.Table aria-label="simple table">
                            <styled.StyledTableHead>
                              <material.TableRow>
                                <material.TableCell></material.TableCell>
                                <material.TableCell>Axis</material.TableCell>
                                <material.TableCell>Cyl</material.TableCell>
                                <material.TableCell>Sph</material.TableCell>
                                <material.TableCell>PD</material.TableCell>
                                <material.TableCell>Power</material.TableCell>
                              </material.TableRow>
                            </styled.StyledTableHead>
                            <material.TableBody>
                              {["right", "left"].map((attribute, index) => (
                                <material.TableRow
                                  key={index}
                                  sx={{ border_bottom: "none" }}>
                                  <material.TableCell
                                    style={{ maxWidth: "30%" }}
                                    component="th"
                                    scope="row">
                                    {attribute === "right" ? "Right" : attribute === "left" ? "Left" : ""}
                                  </material.TableCell>
                                  <material.TableCell style={{ maxWidth: "10%" }}>
                                    {attribute === "right"
                                      ? x?.prescription_data?.right_eye_attributes?.axis || "-"
                                      : attribute === "left"
                                      ? x?.prescription_data?.left_eye_attributes?.axis || "-"
                                      : ""}
                                  </material.TableCell>
                                  <material.TableCell style={{ maxWidth: "10%" }}>
                                    {attribute === "right"
                                      ? x?.prescription_data?.right_eye_attributes?.cyl || "-"
                                      : attribute === "left"
                                      ? x?.prescription_data?.left_eye_attributes?.cyl || "-"
                                      : ""}
                                  </material.TableCell>
                                  <material.TableCell style={{ maxWidth: "10%" }}>
                                    {attribute === "right"
                                      ? x?.prescription_data?.right_eye_attributes?.sph || "-"
                                      : attribute === "left"
                                      ? x?.prescription_data?.left_eye_attributes?.sph || "-"
                                      : ""}
                                  </material.TableCell>
                                  <material.TableCell style={{ maxWidth: "10%" }}>
                                    {attribute === "right"
                                      ? x?.prescription_data?.right_eye_attributes?.pd || "-"
                                      : attribute === "left"
                                      ? x?.prescription_data?.left_eye_attributes?.pd || "-"
                                      : ""}
                                  </material.TableCell>
                                  <material.TableCell style={{ maxWidth: "10%" }}>
                                    {attribute === "right"
                                      ? x?.prescription_data?.right_eye_attributes?.power || "-"
                                      : attribute === "left"
                                      ? x?.prescription_data?.left_eye_attributes?.power || "-"
                                      : ""}
                                  </material.TableCell>
                                </material.TableRow>
                              ))}
                            </material.TableBody>
                          </material.Table>
                        </styled.TableWrapper> */}
                        <styled.TableWrapper>
                          <material.Table aria-label="simple table">
                            <styled.StyledTableHead>
                              <material.TableRow>
                                <material.TableCell align="center">
                                  Eye
                                </material.TableCell>
                                <material.TableCell align="center">
                                  Sph
                                </material.TableCell>
                                <material.TableCell align="center">
                                  Cyl
                                </material.TableCell>
                                <material.TableCell align="center">
                                  Axis
                                </material.TableCell>
                                <material.TableCell align="center">
                                  Power
                                </material.TableCell>
                              </material.TableRow>
                            </styled.StyledTableHead>
                            <material.TableBody>
                              {["right", "left", "pd"].map(
                                (attribute, index) => (
                                  <material.TableRow
                                    key={index}
                                    sx={{ border_bottom: "none" }}
                                  >
                                    <material.TableCell
                                      style={{
                                        maxWidth: "30%",
                                        padding: "8px 16px",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      {attribute === "right"
                                        ? "OD (Right)"
                                        : attribute === "left"
                                        ? "OS (Left)"
                                        : attribute === "pd"
                                        ? "PD"
                                        : ""}
                                    </material.TableCell>
                                    <material.TableCell
                                      style={{
                                        maxWidth: "10%",
                                        padding: "8px 16px",
                                      }}
                                      align="center"
                                    >
                                      {attribute === "right"
                                        ? x?.prescription_data
                                            ?.right_eye_attributes?.sph || "-"
                                        : attribute === "left"
                                        ? x?.prescription_data
                                            ?.left_eye_attributes?.sph || "-"
                                        : attribute === "pd"
                                        ? x?.prescription_data
                                            ?.right_eye_attributes?.pd || "-"
                                        : ""}
                                    </material.TableCell>
                                    <material.TableCell
                                      style={{
                                        maxWidth: "10%",
                                        padding: "8px 16px",
                                      }}
                                      align="center"
                                    >
                                      {attribute === "right"
                                        ? x?.prescription_data
                                            ?.right_eye_attributes?.cyl || "-"
                                        : attribute === "left"
                                        ? x?.prescription_data
                                            ?.left_eye_attributes?.cyl || "-"
                                        : attribute === "pd"
                                        ? x?.prescription_data
                                            ?.left_eye_attributes?.pd || "-"
                                        : ""}
                                    </material.TableCell>
                                    <material.TableCell
                                      style={{
                                        maxWidth: "10%",
                                        padding: "8px 16px",
                                      }}
                                      align="center"
                                    >
                                      {attribute === "right"
                                        ? x?.prescription_data
                                            ?.right_eye_attributes?.axis || "-"
                                        : attribute === "left"
                                        ? x?.prescription_data
                                            ?.left_eye_attributes?.axis || "-"
                                        : ""}
                                    </material.TableCell>
                                    <material.TableCell
                                      style={{
                                        maxWidth: "10%",
                                        padding: "8px 16px",
                                      }}
                                      align="center"
                                    >
                                      {attribute === "right"
                                        ? x?.prescription_data
                                            ?.right_eye_attributes?.power || "-"
                                        : attribute === "left"
                                        ? x?.prescription_data
                                            ?.left_eye_attributes?.power || "-"
                                        : ""}
                                    </material.TableCell>
                                  </material.TableRow>
                                )
                              )}
                            </material.TableBody>
                          </material.Table>
                        </styled.TableWrapper>
                        {/* <styled.TableWrapper>
                          <material.Table aria-label="simple table">
                            <styled.StyledTableHead>
                              <material.TableRow>
                                <material.TableCell>Power</material.TableCell>
                                <material.TableCell>Right</material.TableCell>
                                <material.TableCell>Left</material.TableCell>
                              </material.TableRow>
                            </styled.StyledTableHead>
                            <material.TableBody>
                              {["axis", "cyl", "pd", "sph", "power"].map((attribute, index) => (
                                <material.TableRow
                                  key={index}
                                  sx={{ border_bottom: "none" }}>
                                  <material.TableCell
                                    style={{ maxWidth: "30%" }}
                                    component="th"
                                    scope="row">
                                    {attribute === "axis"
                                      ? "Axis"
                                      : attribute === "cyl"
                                      ? "Cylindrical Power (cyl)"
                                      : attribute === "pd"
                                      ? "PD"
                                      : attribute === "sph"
                                      ? "Spherical Power (sph)"
                                      : attribute === "power"
                                      ? "Power"
                                      : ""}
                                  </material.TableCell>
                                  <material.TableCell style={{ maxWidth: "10%" }} align="center">
                                    {attribute === "axis"
                                      ? x?.prescription_data?.right_eye_attributes?.axis || "-"
                                      : attribute === "cyl"
                                      ? x?.prescription_data?.right_eye_attributes?.cyl || "-"
                                      : attribute === "pd"
                                      ? x?.prescription_data?.right_eye_attributes?.pd || "-"
                                      : attribute === "sph"
                                      ? x?.prescription_data?.right_eye_attributes?.sph || "-"
                                      : attribute === "power"
                                      ? x?.prescription_data?.right_eye_attributes?.power || "-"
                                      : ""}
                                  </material.TableCell>
                                  <material.TableCell style={{ maxWidth: "10%" }}>
                                    {attribute === "axis"
                                      ? x?.prescription_data?.left_eye_attributes?.axis || "-"
                                      : attribute === "cyl"
                                      ? x?.prescription_data?.left_eye_attributes?.cyl || "-"
                                      : attribute === "pd"
                                      ? x?.prescription_data?.left_eye_attributes?.pd || "-"
                                      : attribute === "sph"
                                      ? x?.prescription_data?.left_eye_attributes?.sph || "-"
                                      : attribute === "power"
                                      ? x?.prescription_data?.right_eye_attributes?.power || "-"
                                      : ""}
                                  </material.TableCell>
                                </material.TableRow>
                              ))}
                            </material.TableBody>
                          </material.Table>
                        </styled.TableWrapper> */}
                      </material.Paper>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}

            <div className="mb-4" />
            <styled.BorderDivider m_bottom={20} mTop={20} />
            <div className="flex items-center justify-between">
              <div>
                <styled.Para18Tag className="mb-2">
                  Delivery Address :
                </styled.Para18Tag>
                <styled.Para16Tag>{`${orderDetails?.shipping_address_data?.address}, ${orderDetails?.shipping_address_data?.city}, ${orderDetails?.shipping_address_data?.state}, USA, ${orderDetails?.shipping_address_data?.zipcode}`}</styled.Para16Tag>
                <styled.Para16Tag>
                  Address type :{" "}
                  {orderDetails?.shipping_address_data?.address_type}
                </styled.Para16Tag>
              </div>
              <div>
                <styled.Para18Tag className="mb-2">
                  Contact Information :
                </styled.Para18Tag>
                <styled.Para16Tag>
                  {orderDetails?.shipping_address_data?.firstname +
                    " " +
                    orderDetails?.shipping_address_data?.lastname}
                </styled.Para16Tag>
                <styled.Para16Tag>
                  {orderDetails?.shipping_address_data?.email}
                </styled.Para16Tag>
                <styled.Para16Tag>{`+1 ${orderDetails?.shipping_address_data?.mobile_no}`}</styled.Para16Tag>
              </div>
            </div>

            {orderDetails?.shipping_lable && (
              <>
                <div className="mb-4" />
                <styled.BorderDivider m_bottom={20} mTop={20} />
                {/* <div className=""> */}
                  {/* <styled.Para18Tag className="mb-2">
                    Shipping Label :
                  </styled.Para18Tag> */}
                  {/* <div className="py-3">
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <div style={{ height: 500 }}>
                        <Viewer
                          fileUrl={decodedPDF}
                          defaultScale={1.1}
                          theme="auto"
                        />
                      </div>
                    </Worker>
                  </div> */}
                  <styled.ButtonWrapper
                    type="button"
                    onClick={() => downloadAsPDF()}
                    // className="mx-auto"
                  >
                    Download shipping label
                  </styled.ButtonWrapper>
                {/* </div> */}
              </>
            )}
          </styled.DashboardDetailsBorder>

          <div className="mb-4" />
          <styled.DashboardDetailsBorder padding={"20px"}>
            <div className="flex items-center justify-between">
              <styled.Para16Tag $weight="500" color="#2E3233">
                Subtotal
              </styled.Para16Tag>
              <styled.Para16Tag $weight="600">{`$${orderDetails?.price}`}</styled.Para16Tag>
            </div>
            <div className="flex items-center justify-between">
              <styled.Para16Tag $weight="500" color="#2E3233">
                Offer Discount
              </styled.Para16Tag>
              <styled.Para16Tag $weight="600" color="#2E3233">
                {orderDetails?.is_promo_code_applied === true
                  ? ` - $${orderDetails?.promocode_price}`
                  : `- $0`}
              </styled.Para16Tag>
            </div>
            {/* <div className="flex items-center justify-between">
              <styled.Para16Tag $weight="600">Net Amount</styled.Para16Tag>
              <styled.Para16Tag $weight="600">{`$${orderDetails?.price}`}</styled.Para16Tag>
            </div> */}
            <styled.BorderDivider m_bottom={20} mTop={20} />
            <div className="flex items-center justify-between">
              <styled.Para18Tag>Total Payable</styled.Para18Tag>
              <styled.Para18Tag>{`$${orderDetails?.total_price}`}</styled.Para18Tag>
            </div>
          </styled.DashboardDetailsBorder>
        </Box>
      </styled.MainPadding>
    </>
  );
};

export default OrderDetails;
