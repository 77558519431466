import { ErrorToast, SuccessToast } from "../Toast";
import { toastMessage } from "../config/toastMessage.config";
import {
  AdminColorAPI,
  AdminBlogAPI,
  AdminCommonAPI,
  AdminProductAPI,
  AdminProductFrameDetailsAPI,
  AdminProductFrameAPI,
  AdminAuthAPI,
  AdminOrderAPI,
  AdminOrderStatusAPI,
  AdminOrderItemAPI,
  AdminAPI,
  BASEURL,
  TransportServiceAPI,
} from "./API.base";
import axios from "axios";
import { APIHeaderCors, getFormDataHeaders, getHeaders } from "./API.header";

// Get All Product
export const ApiGetAllProducts = async () => {
  try {
    const response = await AdminCommonAPI.post("/products", APIHeaderCors);
    return response;
  } catch (error) {
    return error;
  }
};

// Get Single Product By Id
export const ApiGetSingleProductById = async (productId) => {
  try {
    const response = await AdminCommonAPI.get(`/products/${productId}`, APIHeaderCors);
    return response;
  } catch (error) {
    return error;
  }
};

// Update Single Product By Id
export const ApiUpdateSingleProductById = async (productId, body) => {
  try {
    const headers = await getHeaders();

    const response = await AdminCommonAPI.put(`/products/${productId}`, body, {
      headers,
    });
    if (response?.data?.message?.status === 200) {
      SuccessToast("Product updated successfully");
    } else if (response?.data?.res?.status === 500) {
      ErrorToast(response?.data?.res?.message);
    }
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Delete Single Product By Id
export const ApiDeleteSingleProductById = async (productId) => {
  try {
    const headers = await getHeaders();

    const response = await AdminProductFrameAPI.delete(`/${productId}`, {
      headers,
    });
    if (response?.data?.message?.status === 200) {
      SuccessToast("Product deleted successfully");
    } else if (response?.data?.res?.status === 500) {
      ErrorToast(response?.data?.res?.message);
    }
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Add Blog
export const ApiAddBlog = async (body) => {
  try {
    const headers = await getFormDataHeaders();

    const response = await AdminBlogAPI.post(`/add-blog`, body, { headers });
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Get All Blog
export const ApiGetAllBlog = async () => {
  try {
    const headers = await getHeaders();

    const response = await AdminBlogAPI.post(`/`, {}, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

// Get Single Blog
export const ApiGetSingleBlog = async (id) => {
  try {
    const headers = await getHeaders();

    const response = await AdminBlogAPI.get(`/${id}`, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

// Get Single Blog
export const ApiUpdateBlog = async (id, body) => {
  try {
    const headers = await getFormDataHeaders();

    const response = await AdminBlogAPI.put(`/${id}`, body, { headers });
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Delete Blog By Id
export const ApiDeleteBlog = async (id) => {
  try {
    const headers = await getHeaders();

    const response = await AdminBlogAPI.delete(`/${id}`, { headers });
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Add Base color
export const ApiAddColor = async (body) => {
  try {
    const headers = await getFormDataHeaders();

    const response = await AdminColorAPI.post(`/`, body, { headers });
    return response;
  } catch (error) {
    if (error) {
      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message);
      } else {
        ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      }
    } else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Get All Base Colors
export const ApiGetAllColor = async (is_frame_color) => {
  try {
    const headers = await getHeaders();

    const response = await AdminColorAPI.get(`/?is_frame_color=${is_frame_color}`, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

// Update Single Base Color
export const ApiUpdateColor = async (id, body) => {
  try {
    const headers = await getFormDataHeaders();

    const response = await AdminColorAPI.put(`/${id}`, body, { headers });
    return response;
  } catch (error) {
    if (error) {
      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message);
      } else {
        ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      }
    } else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Delete Base Color By Id
export const ApiDeleteColor = async (id) => {
  try {
    const headers = await getHeaders();

    const response = await AdminColorAPI.delete(`/${id}`, { headers });
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Add Product Frame
export const ApiAddProductFrame = async (body) => {
  try {
    const headers = await getHeaders();

    const response = await AdminProductFrameAPI.post(`/add-product-frame`, body, { headers });
    return response;
  } catch (error) {
    if (error) {
      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message);
      } else {
        ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      }
    } else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Get Single Product Frame by id
export const ApiGetSingleProductFrame = async (id) => {
  try {
    const headers = await getHeaders();

    const response = await AdminProductFrameAPI.get(`/${id}`, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

// Add Product Frame Details
export const ApiAddProductFrameDetails = async (body) => {
  try {
    const headers = await getFormDataHeaders();

    const response = await AdminProductFrameDetailsAPI.post(`/add`, body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) {
      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message);
      } else {
        ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      }
    } else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Get Product Frame Details by frame id
export const GetProductFrameDetails = async (productFrameId) => {
  try {
    const headers = await getHeaders();

    const response = await AdminProductFrameDetailsAPI.get(`/${productFrameId}`, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

// Get All Product Frame Detail
export const ApiGetAllProductFrameDetail = async () => {
  const body = {};

  try {
    const headers = await getHeaders();
    const response = await AdminProductFrameDetailsAPI.post("/admin", body, {
      headers,
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Update Product Frame Details
export const ApiUpdateProductFrameDetails = async (id, body) => {
  try {
    const headers = await getFormDataHeaders();

    const response = await AdminProductFrameDetailsAPI.put(`/${id}`, body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) {
      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message);
      } else {
        ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      }
    } else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Delete Product Frame Details
export const ApiDeleteProductFrameDetails = async (id) => {
  try {
    const headers = await getHeaders();

    const response = await AdminProductFrameDetailsAPI.delete(`/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) {
      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message);
      } else {
        ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      }
    } else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// API Forgot Password
export const ApiForgotPassword = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await AdminAuthAPI.post("/forgot-password", body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else throw error;
  }
};

// API Verify Reset Otp
export const ApiVerifyEmailCode = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await AdminAuthAPI.post("/verify-reset-otp", body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else throw error;
  }
};

// API Verify Reset Otp
export const ApiResetPassword = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await AdminAuthAPI.post("/reset-password", body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else throw error;
  }
};

// Get All Orders
export const ApiGetAllOrders = async () => {
  try {
    const headers = await getHeaders();

    const response = await AdminOrderAPI.get(`/`, { headers });
    return response;
  } catch (error) {
    return error;
  }
};

// Update Order Status
export const ApiUpdateOrderStatus = async (body) => {
  try {
    const headers = await getHeaders();

    const response = await AdminOrderAPI.put(`/update-order-status`, body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) {
      if (error?.response?.status === 400) {
        ErrorToast(error?.response?.data?.message);
      } else if (error?.response?.status === 500) {
        ErrorToast(error?.response?.data?.message);
      } else {
        ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      }
    } else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else return error;
  }
};

// Get All Dashboard Data
export const ApiGetDashboardData = async () => {
  try {
    const headers = await getHeaders();

    const response = await AdminAPI.post("/dashboard/total-count/", headers);
    return response;
  } catch (error) {
    return error;
  }
};

// POST /api/
export const ApiPost = async (type, body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await AdminAPI.get(type, body, headers);
    return response;
  } catch (error) {
    throw error;
  }
};

// GET /api/
export const ApiGet = async (type) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await AdminAPI.get(type, { headers });
    return response;
  } catch (error) {
    throw error;
  }
};

// API get by id
export const ApiGetById = async (type, id) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.get(BASEURL + type + "/" + id, {
      headers,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// API Create Shipment
export const ApiCreateShipment = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await TransportServiceAPI.post("/create-shipment-label", body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
    else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
    else throw error;
  }
};


export const ApiFetchUsers = async () => {
  try {
    const headers = await getHeaders();

    const response = await AdminAPI.get('/users/', {
      headers
    });
    const res = await response.data
    return res
  } catch (error) {
    throw new Error()
  }
};

// const body = {
//   shipping_address_data: {
//     firstname: "Sonia",
//     lastname: "Barlow",
//     email: "soniabarlow@gmail.com",
//     mobile_no: "8975458565",
//     address: "Obcaecati assumenda ",
//     address_type: "work",
//     zipcode: "41069",
//     state: "CT",
//     city: "Stafford",
//   },
//   _id: "65e94cf551b774c2f3d19562",
//   user_id: "6597a9c3ec7ff25c8d3ae8d0",
//   email: "dhruvin@delemontstudio.com",
//   is_guest_order: false,
//   shipping_address_id: {
//     _id: "65c73ddcf011e9d193b4a76c",
//     user_id: "6597a9c3ec7ff25c8d3ae8d0",
//     is_guest: false,
//     firstname: "Sonia",
//     lastname: "Barlow",
//     mobile_no: "8975458565",
//     email: "soniabarlow@gmail.com",
//     address: "Obcaecati assumenda ",
//     address_type: "work",
//     is_default_address: false,
//     zipcode: "41069",
//     state: "CT",
//     city: "Stafford",
//     is_default: false,
//     is_deleted: false,
//     created_at: "2024-02-10T09:11:56.332Z",
//   },
//   items: [
//     {
//       prescription_data: {
//         right_eye_attributes: {
//           axis: 54,
//           cyl: -1,
//           sph: -6,
//           pd: 26,
//           power: null,
//         },
//         left_eye_attributes: {
//           axis: 45,
//           cyl: -3.75,
//           sph: -4,
//           pd: 34,
//           power: null,
//         },
//       },
//       lenses: {
//         lense_id: {
//           _id: "6517a54125cee394654f6dcf",
//           prescription_type: "Single Vision Lenses",
//           description:
//             "This lens is generally used to correct one focal distance (Far or Near) it is primary used to correct Myopia (Short sightedness) or Hyperopia (Long Sightedness).",
//           image: "https://optical-haven.s3.amazonaws.com/product_images/1706670597296_653_image.jpg",
//           colors: [
//             {
//               color_id: "6530ab65660a0b84862db289",
//               _id: "6561971a181a76f4b251e2d5",
//             },
//             {
//               color_id: "6530ac2b660a0b84862db2af",
//               _id: "6561971a181a76f4b251e2d6",
//             },
//           ],
//           price: 5,
//           created_at: "2023-09-30T04:34:09.529Z",
//           is_active: true,
//         },
//         lens_name: "Single Vision Lenses",
//         lens_price: 5,
//         singlevision_id: {
//           _id: "64fedc3a52bfe3937bd8512d",
//           single_vision_type: "Standard",
//           description:
//             "This lens is generally used to correct one focal distance ( Far or Near) it is primary used to correct Myopia (Short sightedness) or Hyperopia (Long Sightedness).",
//           price: 10,
//           created_at: "2023-09-11T09:22:02.186Z",
//           is_active: true,
//         },
//         singlevision_type: "Standard",
//         singlevision_price: 10,
//         lense_type_id: {
//           _id: "64feed20396e789bf568fee3",
//           lenses_type: "Classic",
//           description: "Optics Haven high quality anti-reflective lens with scratch resistant.",
//           price: 0,
//           created_at: "2023-09-11T10:34:08.742Z",
//           colors: [],
//           is_active: true,
//           lenses: [],
//         },
//         lenses_type_name: "Classic",
//         lens_type_price: 0,
//         material_type_id: {
//           _id: "64fedfefc1e2bb286307932b",
//           lens_material_type: "Polycarbonate",
//           description: "Strong lightweight and impact-resistant lens",
//           price: 0,
//           created_at: "2023-09-11T09:37:51.041Z",
//           is_active: true,
//         },
//         material_type_name: "Polycarbonate",
//         material_type_price: 0,
//         is_use_my_frame: false,
//       },
//       product_frame_detail_id: "65d166a7aa4038be8ff2a9a2",
//       product_image: "https://optical-haven.s3.amazonaws.com/thumbnail_picture/1708222118983_209_thumbnail_picture.jpg",
//       product_color: "Dark Green",
//       product_sku_no: "OH-46A",
//       product_model_no: "OH-46",
//       product_name: "Brielle",
//       product_price: 40,
//       product_size_name: "Extra Wide",
//       product_size_id: "65d166a7aa4038be8ff2a9a3",
//       quantity: 1,
//       product_status: "shipped",
//       _id: "65e94cf551b774c2f3d19563",
//     },
//     {
//       prescription_data: {
//         right_eye_attributes: {
//           axis: 54,
//           cyl: -0.5,
//           sph: -11.5,
//           pd: 24,
//           power: null,
//         },
//         left_eye_attributes: {
//           axis: 45,
//           cyl: -1.5,
//           sph: 8,
//           pd: 36,
//           power: null,
//         },
//       },
//       lenses: {
//         lense_id: {
//           _id: "6517a54125cee394654f6dcf",
//           prescription_type: "Single Vision Lenses",
//           description:
//             "This lens is generally used to correct one focal distance (Far or Near) it is primary used to correct Myopia (Short sightedness) or Hyperopia (Long Sightedness).",
//           image: "https://optical-haven.s3.amazonaws.com/product_images/1706670597296_653_image.jpg",
//           colors: [
//             {
//               color_id: "6530ab65660a0b84862db289",
//               _id: "6561971a181a76f4b251e2d5",
//             },
//             {
//               color_id: "6530ac2b660a0b84862db2af",
//               _id: "6561971a181a76f4b251e2d6",
//             },
//           ],
//           price: 5,
//           created_at: "2023-09-30T04:34:09.529Z",
//           is_active: true,
//         },
//         lens_name: "Single Vision Lenses",
//         lens_price: 5,
//         singlevision_id: {
//           _id: "6517acfc74a72b8a264f5ccd",
//           single_vision_type: "Anti-fatigue",
//           description:
//             "This Lenses limits eye strains caused by digital devices like computers and mobile phones. The bottom of the lens has a small boost of power that makes it comfortable for you to use your digital device for a long time.",
//           price: 50,
//           created_at: "2023-09-30T05:07:08.295Z",
//           is_active: true,
//         },
//         singlevision_type: "Anti-fatigue",
//         singlevision_price: 50,
//         lense_type_id: {
//           _id: "64feed20396e789bf568fee3",
//           lenses_type: "Classic",
//           description: "Optics Haven high quality anti-reflective lens with scratch resistant.",
//           price: 0,
//           created_at: "2023-09-11T10:34:08.742Z",
//           colors: [],
//           is_active: true,
//           lenses: [],
//         },
//         lenses_type_name: "Classic",
//         lens_type_price: 0,
//         material_type_id: {
//           _id: "6517aec474a72b8a264f5cee",
//           lens_material_type: "1.67 high-index",
//           description: "20% thinner than polycarbonate with the same prescription. Suitable for lenses with high power (+/- 4.0 or higher).",
//           price: 50,
//           created_at: "2023-09-30T05:14:44.925Z",
//           is_active: true,
//         },
//         material_type_name: "1.67 high-index",
//         material_type_price: 50,
//         is_use_my_frame: false,
//       },
//       product_frame_detail_id: "65d27318aa4038be8ff404a6",
//       product_image: "https://optical-haven.s3.amazonaws.com/thumbnail_picture/1708290839990_941_thumbnail_picture.jpg",
//       product_color: "Ivory Tortoise",
//       product_sku_no: "OH-50A",
//       product_model_no: "OH-50",
//       product_name: "Othnell",
//       product_price: 30,
//       product_size_name: "Medium",
//       product_size_id: "65d27318aa4038be8ff404a7",
//       quantity: 1,
//       product_status: "shipped",
//       _id: "65e94cf551b774c2f3d19564",
//     },
//   ],
//   use_wallet_balance: false,
//   wallet_balance_used: 0,
//   total_count: 2,
//   price: 190,
//   order_status: "shipped",
//   promocode_id: "65d34c8250a15c22ee74887a",
//   is_promo_code_applied: true,
//   is_referral_code_applied: false,
//   promocode_price: 19,
//   total_price: 171,
//   created_at: "2024-03-07T05:13:25.100Z",
// };
