/**
 * The code exports two functions, `SuccessToast` and `ErrorToast`, that display success and error
 * messages using the `react-hot-toast` library.
 * @param message - The `message` parameter is the text that you want to display in the toast message.
 * It can be any string or variable that contains the message you want to show.
 * @returns The functions `SuccessToast` and `ErrorToast` are returning a toast message using the
 * `toast` function from the `react-hot-toast` library. The `toast.success` function is used to display
 * a success message, while the `toast.error` function is used to display an error message. Both
 * functions have a position of "bottom-right" and a duration of 8000 milliseconds.
 */
import { toast } from "react-hot-toast";

/**
 * The `SuccessToast` function displays a success toast message with the provided message.
 * @param message - The message parameter is the text that you want to display in the success toast.
 * @returns The `SuccessToast` function is returning a toast notification with a success message.
 */
export const SuccessToast = (message) => {
  return toast.success(message, {
    position: "bottom-right",
    duration: 8000,
  });
};

/**
 * The `ErrorToast` function displays an error message as a toast notification at the bottom-right
 * position for a duration of 8 seconds.
 * @param message - The `message` parameter is a string that represents the error message that you want
 * to display in the toast notification.
 * @returns The `ErrorToast` function is returning a toast notification with an error message.
 */
export const ErrorToast = (message) => {
  return toast.error(message, {
    position: "bottom-right",
    duration: 8000,
  });
};
