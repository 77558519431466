import {
  ADD_FACE_TYPE_SUCCESS,
  LIST_ALL_FACE_TYPES_SUCCESS,
  UPDATE_FACE_TYPE_SUCCESS,
  LIST_ALL_FACE_TYPES_REQUEST,
  LIST_ALL_FACE_TYPES_FAILURE,
  DELETE_FACE_TYPE_SUCCESS,
  CREATE_FACE_TYPE_SUCCESS,
} from "../constants/faceTypes";

const initialState = {
  faceTypesList: [],
  loading: false,
  error: null,
};

// let getFaceTypeReducer = (state = { faceTypeGet: [] }, action) => {
//   switch (action.type) {
//     case GET_FACE__TYPE_REQUEST:
//       return {
//         loading: true,
//         faceTypeGet: [],
//       };
//     case GET_FACE__TYPE_SUCCESS:
//       return {
//         loading: false,
//         faceTypeGet: action?.payload,
//       };
//     case GET_FACE__TYPE_FAILURE:
//       return {
//         loading: false,
//         error: action?.payload,
//       };
//     default:
//       return state;
//   }
// };

let faceTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FACE_TYPE_SUCCESS:
      return {
        ...state,
        faceTypesList: [...state.faceTypesList, action.payload],
      };

    case LIST_ALL_FACE_TYPES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_FACE_TYPES_SUCCESS:
      return {
        ...state,
        faceTypesList: action?.payload,
        loading: false,
      };

    case LIST_ALL_FACE_TYPES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_FACE_TYPE_SUCCESS: {
      const updatedFaceType = action.payload.face;
      return {
        ...state,
        faceTypesList: state.faceTypesList.map((face) => {
          if (face.id === updatedFaceType._id) {
            return {
              ...face,
              face_type: updatedFaceType.face_type,
              is_active: updatedFaceType.is_active
            };
          }
          return face;
        }),
      };
    }

    case CREATE_FACE_TYPE_SUCCESS: {
      const { face } = action.payload;
      const newfaceType = {
        id: face._id,
        face_type: face.face_type,
        is_active: face.is_active,
      };
      return {
        ...state,
        faceTypesList: [...state.faceTypesList, newfaceType],
      };
    }

    case DELETE_FACE_TYPE_SUCCESS: {
      const faceTypeId = action.payload;
      return {
        ...state,
        faceTypesList: state.faceTypesList.filter(
          (face) => face.id !== faceTypeId
        ),
      };
    }
    default:
      return state;
  }
};

export default faceTypeReducer;
