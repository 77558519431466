import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../components/Table";
import { trendingSearchSchema } from "../../helper/yup.schema";
import { ButtonWrapper, FormInputLabel, Head24Tag, MainPadding } from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import {
  createTrendingSearch,
  deleteTrendingSearch,
  listAllTrendingSearches,
  updateTrendingSearch,
} from "../../redux/actions/trendingSearchAction";

const TrendingSearch = () => {
  const [formInitialValues, setFormInitialValues] = useState({
    title: "",
  });
  const [selectedTrendingSearchId, setSelectedTrendingSearchId] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();
  const { trendingSearchList } = useSelector((state) => state.trendingSearch);

  useEffect(() => {
    dispatch(listAllTrendingSearches());
  }, [dispatch]);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteTrendingSearch({ id }));
  };

  const handleEditClick = (id) => () => {
    setSelectedTrendingSearchId(id);
    trendingSearchList.forEach((x) => {
      if (x.id === id) {
        setFormInitialValues({
          title: x?.title,
        });
      }
    });
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddCategory = () => {
    setOpen(true);
    setEdit(false);
  };
  const handleClose = () => {
    setOpen(false);
    setFormInitialValues({
      title: "",
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    try {
      const body = {
        title: values?.title,
      };
      if (edit) {
        dispatch(updateTrendingSearch(body, selectedTrendingSearchId));
        setOpen(false);
        setEdit(false);
        setFormInitialValues({
          title: "",
        });
        return;
      }

      // create material type API
      dispatch(createTrendingSearch(body));
      resetForm();
      setOpen(false);
    } catch (error) {
      throw new Error(error)
    }
  };

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header
            title="Trending Searches"
            subtitle="View All The Trending Search"
          />
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddCategory}>
              Add New Trending Search
            </ButtonWrapper>
          </div>
        </div>
        <Table
          columns={columns}
          rows={trendingSearchList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Trending Search`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <Formik
                initialValues={formInitialValues}
                validationSchema={trendingSearchSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}>
                {({ touched, errors }) => (
                  <Form>
                    <div className="technical_information_text_filed mt-5">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Title</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Material Type"
                            name="title"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.title && errors.title && <FormErrorMessage errors={errors.title} />}
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner_update">
                      <ButtonWrapper
                        auto={"auto"}
                        type="submit">{`${edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default TrendingSearch;
