/* This code is importing the `axios` library and creating an instance of it called `userAPI`. */
import axios from "axios";
import { API_CONFIG } from "./API.config";

// const baseUrl = process.env.REACT_API_BASEURL;

/* The line `const BASEURL = API_CONFIG;` is assigning the value of `API_CONFIG` to the constant
variable `BASEURL`. `API_CONFIG` is likely a configuration object or variable that contains the base
URL for the API. By assigning `API_CONFIG` to `BASEURL`, the code can easily reference the base URL
throughout the file without hardcoding it multiple times. */
export const BASEURL = 'https://opticshaven.online' // 'http://192.168.1.17:3000'

/* The code is creating an instance of the `axios` library called `userAPI`. This instance is
configured with a base URL of `/auth`, where `baseUrl` is set to
`"http://44.210.88.142:3000"`. This instance can be used to make HTTP requests to the specified base
URL. */
export const AdminAuthAPI = axios.create({
  baseURL: `${BASEURL}/auth`,
});

export const AdminAPI = axios.create({
  baseURL: BASEURL,
});

export const AdminCommonAPI = axios.create({
  baseURL: `${BASEURL}/common`,
});

export const AdminProductAPI = axios.create({
  baseURL: `${BASEURL}/products`,
});

export const AdminBlogAPI = axios.create({
  baseURL: `${BASEURL}/blogs`,
});

export const AdminColorAPI = axios.create({
  baseURL: `${BASEURL}/colors`,
});

export const AdminProductFrameAPI = axios.create({
  baseURL: `${BASEURL}/product-frame`,
});

export const AdminProductFrameDetailsAPI = axios.create({
  baseURL: `${BASEURL}/product-frames-detail`,
});

export const AdminOrderAPI = axios.create({
  baseURL: `${BASEURL}/order`,
});

export const AdminOrderItemAPI = axios.create({
  baseURL: `${BASEURL}/user-order-items`,
});

export const TransportServiceAPI = axios.create({
  baseURL: `${BASEURL}/transport-service`,
});
