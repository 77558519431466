import { AdminAPI } from "../../helper/API/API.base";
import { getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  LIST_ALL_MATERIAL_TYPES_REQUEST,
  LIST_ALL_MATERIAL_TYPES_SUCCESS,
  LIST_ALL_MATERIAL_TYPES_FAILURE,
  UPDATE_MATERIAL_TYPE_SUCCESS,
  DELETE_MATERIAL_TYPE_SUCCESS,
  CREATE_MATERIAL_TYPE_SUCCESS,
} from "../constants/materialType";

export const listAllMaterialTypes = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_MATERIAL_TYPES_REQUEST });

      // header
      const headers = await getHeaders();

      // API
      const response = await AdminAPI.get("/material-type", { headers });

      const materialTypesList = [];
      response?.data?.forEach((x) => {
        materialTypesList.push({
          id: x._id,
          lens_material_type: x.lens_material_type,
          description: x.description,
          price: x.price,
          is_active: x.is_active,
        });
      });

      dispatch({
        type: LIST_ALL_MATERIAL_TYPES_SUCCESS,
        payload: materialTypesList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_MATERIAL_TYPES_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateMaterialType = (values, materialTypeId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/material-type/${materialTypeId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_MATERIAL_TYPE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Material type updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteMaterialType = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const materialTypeId = values.id;

      // API
      const response = await AdminAPI.delete(`/material-type/${materialTypeId}`, { headers });

      dispatch({
        type: DELETE_MATERIAL_TYPE_SUCCESS,
        payload: materialTypeId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Material type deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createMaterialType = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/material-type`, body, { headers });

      dispatch({
        type: CREATE_MATERIAL_TYPE_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Material type added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
