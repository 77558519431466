/**
 * The `Forgot` component is a React functional component that renders a form for users to reset their
 * password by entering their email.
 * @returns The `Forgot` component is returning a JSX element.
 */
import React from "react";
import "./verifyEmail.css";
import Timer from "otp-timer";
import * as styles from "../../../assets/css/styled";
import AuthSlider from "../authSlider/AuthSlider";
import { EmailIcon } from "../../typography/Icons";
import { ButtonWrapper, FormInputLabel, FormInputOuter, Head48Tag, MainPadding, Para16Tag } from "../../../assets/css/styled";
import { Field, Form, Formik } from "formik";
import Back from "../../Back";
import { verifyEmailSchema } from "../../../helper/yup.schema";
import { useNavigate } from "react-router-dom";
import { ApiForgotPassword, ApiVerifyEmailCode } from "../../../helper/API/APICall";
import { ErrorToast, SuccessToast } from "../../../helper/Toast";
import { routesConfig } from "../../../helper/config/routes.config";
import { Auth } from "../../../helper/Auth";
import FormErrorMessage from "../../../components/FormErrorMessage";

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const adminEmail = Auth?.getAdminEmail() || null;

  const initialValues = {
    // email:"",
    otp: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const body = { email: adminEmail, otp: values?.otp };

      // API
      const response = await ApiVerifyEmailCode(body);

      if (response?.data?.status === 200) {
        SuccessToast("Email verified successfully");
        navigate(routesConfig?.resetPassword);
        resetForm();
      } else if (response?.data?.status === 400) ErrorToast(response?.data?.message);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * The function `resendOtp` is an asynchronous function that sends a validation code to the user's
   * email address for password reset.
   */
  const resendOtp = async () => {
    try {
      // API
      const response = await ApiForgotPassword({ email: adminEmail });

      if (response?.data?.status === 200) {
        initialValues.otp = "";
        SuccessToast("Validation code sent successfully.. expired in 2 minutes..");
      } else if (response?.data?.status === 400) ErrorToast(response?.data?.message);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="auth_slider_main forgot">
        <div className="grid grid-cols-2">
          <div className="login_left_main_page">
            <AuthSlider />
          </div>

          <div className="login_right_main_page">
            <div className="login_right_back_page_main_div_inner">
              <MainPadding p_bottom={20}>
                <Back text={"Back"} />
              </MainPadding>
            </div>
            <div className="login_right_inner_outer_info">
              <div className="login_text_main_title_top_div_inner">
                <div className="login_text_main_title">
                  <Head48Tag style={{ textAlign: "center" }}>Verify Email</Head48Tag>
                </div>
                <div className="login_text_main_title_para_inner_information">
                  <Para16Tag
                    weight={500}
                    color={"#868A8B"}
                    center={"center"}>
                    {"No worries,we’ll send you reset instruction"}
                  </Para16Tag>
                </div>
              </div>

              <div className="auth_form_div">
                <Formik
                  initialValues={initialValues}
                  validationSchema={verifyEmailSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched }) => (
                    <Form className="auth_page_form_main">
                      <div className="auth_page_from_inner_div">
                        <div className="form_input_list_email_pass_other_data">
                          <div className="form_input_flex_div_mx_bottom">
                            <div className="form_inner_input_div_main">
                              <FormInputLabel>Email Address</FormInputLabel>
                              <FormInputOuter>
                                <EmailIcon stroke={"#000000"} />
                                <Field
                                  className="from_input_tag"
                                  type="text"
                                  placeholder="Enter your email address"
                                  // name="email"
                                  value={adminEmail}
                                  disabled={true}
                                />
                              </FormInputOuter>
                            </div>
                          </div>
                        </div>
                        <div className="form_input_list_email_pass_other_data">
                          <div className="form_input_flex_div_mx_bottom">
                            <div className="form_inner_input_div_main">
                              <FormInputLabel>Validation Code</FormInputLabel>
                              <FormInputOuter>
                                <EmailIcon stroke={"#000000"} />
                                <Field
                                  className="from_input_tag"
                                  type="text"
                                  placeholder="Enter your code"
                                  name="otp"
                                />
                              </FormInputOuter>
                              {touched.otp && errors.otp && <FormErrorMessage errors={errors.otp} />}
                            </div>
                          </div>
                        </div>
                        <div className="timer_btn_value">
                          <Timer
                            seconds={0}
                            minutes={2}
                            resend={resendOtp}
                            text={<span className="otp_verify_code_num_inner_second">Resend code in</span>}
                            ButtonText={
                              <span className="resend_otp bg-transparent">
                                <styles.Para16Tag
                                  weight={500}
                                  color="#219EBC">
                                  Resend OTP
                                </styles.Para16Tag>
                              </span>
                            }
                            className="oyp_verification_add_code_num"
                          />
                        </div>
                        <div className="form_sign_in_btn_main">
                          <ButtonWrapper
                            width="100%"
                            type="submit">
                            {isLoading ? "Loading..." : "Verify"}
                          </ButtonWrapper>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyEmail;
