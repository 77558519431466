import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../components/Table";
import { lenseSchema } from "../../helper/yup.schema";
import {
  Para16Tag,
  Para20Tag,
  DashboardDetailsBorder,
  ButtonWrapper,
  FormInputLabel,
  Head24Tag,
  MainPadding,
  Para14Tag,
} from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import { CheckBoxCustom } from "../../components/typography/CheckBox";
import { createLense, deleteLense, listAllLenses, updateLense } from "../../redux/actions/lenseAction";
import { ErrorToast } from "../../helper/Toast";
import { UploadImagesIcon } from "../../components/typography/Icons";

const Lense = () => {
  const [formInitialValues, setFormInitialValues] = useState({
    prescription_type: "",
    description: "",
    price: "",
    // colors: [],
  });
  const [selectedLenseId, setSelectedLenseId] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [active, setActive] = useState(false);

  const dispatch = useDispatch();
  const { lenseList } = useSelector((state) => state.lenses);

  useEffect(() => {
    dispatch(listAllLenses());
  }, [dispatch]);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteLense({ id }));
  };

  const handleEditClick = (id) => () => {
    setSelectedLenseId(id);
    lenseList.forEach((x) => {
      if (x.id === id) {
        // const colors = x?.colors.map((color) => color?.color_id?._id);
        setFormInitialValues({
          prescription_type: x?.prescription_type,
          description: x?.description,
          price: x?.price,
          // colors: colors,
        });
        setImageURL(x?.image);
        setActive(x?.is_active);
      }
    });
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "image",
      headerName: "Lense Image",
      width: 120,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`Category ${params.row.id}`}
        />
      ),
    },
    {
      field: "prescription_type",
      headerName: "Prescription Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
    },
    {
      field: "is_active",
      headerName: "Active",
      headerAlign: "center",
      flex: 1,
      align: "center",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddCategory = () => {
    setOpen(true);
    setEdit(false);
  };
  
  const handleClose = () => {
    setOpen(false);
    setActive(false);
    setImageFile(null);
    setImageURL(null);
    setFormInitialValues({
      prescription_type: "",
      description: "",
      price: "",
      //  colors: []
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!imageURL) {
      ErrorToast("Please select an image!");
      return;
    }
    try {
      // const colors = JSON.stringify(
      //   values?.colors?.map((color) => {
      //     return { color_id: color };
      //   })
      // );
      if (edit) {
        const updateFormData = new FormData();
        updateFormData.append("prescription_type", values?.prescription_type);
        updateFormData.append("description", values?.description);
        updateFormData.append("price", values?.price);
        updateFormData.append("is_active", active);
        imageFile && updateFormData.append("image", imageFile);
        // updateFormData.append("colors", colors);
        dispatch(updateLense(updateFormData, selectedLenseId));
        setOpen(false);
        setEdit(false);
        setActive(false);
        setFormInitialValues({
          prescription_type: "",
          description: "",
          price: "",
          //  colors: []
        });
        setImageFile(null);
        setImageURL(null);
        return;
      }
      const newFormData = new FormData();
      newFormData.append("prescription_type", values?.prescription_type);
      newFormData.append("description", values?.description);
      newFormData.append("price", values?.price);
      newFormData.append("is_active", active);
      newFormData.append("image", imageFile);
      // newFormData.append("colors", colors);

   

      // Create lense API
      dispatch(createLense(newFormData));
      resetForm();
      setOpen(false);
      setActive(false);
      setImageFile(null);
      setImageURL(null);
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageURL(url);
    setImageFile(file);

    // Reset the input value to allow re-selecting the same file
    e.target.value = null;
  };

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <div>

          <Header
            title="Lenses"
            subtitle="View All The Lenses"
          />
          <Para16Tag className="font-black mt-2 max-w-xl">Note: Do not change any words because our logic and conditions are based on this text. If you change any word casing, then our flow will not work</Para16Tag>
          </div>
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddCategory}>
              Add New Lense
            </ButtonWrapper>
          </div>
        </div>
        <Table
          columns={columns}
          rows={lenseList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Lense`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <div className="form_inner_input_div_main">
                <FormInputLabel>Select Lense Type</FormInputLabel>
              </div>

              <div>
                <div>
                  <div>
                    <div className="border border-blue-300 rounded-md p-5 my-2">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="thumbnail_upload_image"
                        id="thumbnail_upload_image"
                        accept="image/png, image/jpeg"
                        onChange={handleFile}
                      />
                      <div className="frame_info_images_outer_inner_three_images_show">
                        <label htmlFor="thumbnail_upload_image">
                          {imageURL && (
                            <img
                              style={{ cursor: "pointer" }}
                              src={imageURL}
                              alt="img"
                              className="frame_info_image_inner_open_inner"
                            />
                          )}
                          <div className="frame_info_text_files_name_browse">
                            <UploadImagesIcon />
                            <Para20Tag color={"#000"}>
                              Drop your Files here Or.
                              <span style={{ color: "#219EBC" }}> Browse</span>
                            </Para20Tag>
                          </div>
                          <Para16Tag>You can only select up to one images.</Para16Tag>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame_info_image_inner_open_bottom_div_inner">
                  {imageURL && (
                    <DashboardDetailsBorder padding={"10px"}>
                      <div
                        style={{ gap: "40px" }}
                        className="frame_show_images_selected_file_inner">
                        <div className="frame_show_images_name_complete_done_div">
                          <img
                            src={imageURL}
                            alt="img"
                            className="frame_show_images_selected_file_inner_outer"
                          />
                        </div>
                        <div className="frame_info_images_inner_open_right_delete_icon_remove">
                          <button
                            className="frame_info_images_remove_icon_inner_delete"
                            onClick={() => {
                              setImageURL(null);
                              setImageFile(null);
                            }}>
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    </DashboardDetailsBorder>
                  )}
                </div>
              </div>

              <Formik
                initialValues={formInitialValues}
                validationSchema={lenseSchema}
                onSubmit={handleSubmit}>
                {({ touched, errors }) => (
                  <Form>
                    <div className="technical_information_text_filed mt-5">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Lense Prescription Type</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Prescription Type"
                            name="prescription_type"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.prescription_type && errors.prescription_type && <FormErrorMessage errors={errors.prescription_type} />}
                      </div>
                    </div>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Lense Description</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Lense Description"
                            name="description"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.description && errors.description && <FormErrorMessage errors={errors.description} />}
                      </div>
                    </div>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Lense Price</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Lense Description"
                            name="price"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.price && errors.price && <FormErrorMessage errors={errors.price} />}
                      </div>
                    </div>
                    {/* Active checkbox */}
                    <div className="technical_information_text_filed">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="form_input_forget_text_main_pass_inner mt-4">
                        <div className="form_get_update_text_data_title">
                          <div
                            style={{ cursor: "pointer" }}
                            className="add_new_add_default_address">
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}>
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                className="main_check_box">
                                <CheckBoxCustom
                                  id="is_active"
                                  name="is_active"
                                  checked={active}
                                  onChange={() => setActive(!active)}
                                />
                              </div>
                              <div className="label_text">
                                <Para14Tag
                                  weight={500}
                                  color="#A7ABAC">
                                  Active
                                </Para14Tag>
                              </div>
                              {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="technical_information_text_filed">
                      <div className="grid grid-cols-2 gap-5 mt-5">
                        <div>
                          <FormInputLabel>Color</FormInputLabel>
                          <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                            <div className="flex cursor-pointer">
                              <div
                                className="flex cursor-pointer"
                                style={{
                                  marginRight: "10px",
                                }}>
                                <CheckBoxCustom
                                  id="gender_male"
                                  name="colors"
                                  value="6530ab65660a0b84862db289"
                                  checked={values.colors.includes("6530ab65660a0b84862db289")}
                                  onChange={handleChange}
                                />
                              </div>
                              <Para16Tag color={"#A7ABAC"}>Gray</Para16Tag>
                            </div>
                          </label>
                          <label className="add_new_add_default_address flex flex-col cursor-pointer items-start">
                            <div className="flex cursor-pointer">
                              <div
                                className="flex cursor-pointer"
                                style={{
                                  marginRight: "10px",
                                }}>
                                <CheckBoxCustom
                                  id="gender_female"
                                  name="colors"
                                  value="6530ac2b660a0b84862db2af"
                                  checked={values.colors.includes("6530ac2b660a0b84862db2af")}
                                  onChange={handleChange}
                                />
                              </div>
                              <Para16Tag color={"#A7ABAC"}>Yellow</Para16Tag>
                            </div>
                          </label>
                          {touched.colors && errors.colors && <FormErrorMessage errors={errors.colors} />}
                        </div>
                      </div>
                    </div> */}
                    <div className="form_sign_in_btn_main_inner_update">
                      <ButtonWrapper
                        auto={"auto"}
                        type="submit">{`${edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default Lense;
