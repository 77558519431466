import {
  ADD_LENSES_TYPE_SUCCESS,
  LIST_ALL_LENSES_TYPES_REQUEST,
  LIST_ALL_LENSES_TYPES_SUCCESS,
  LIST_ALL_LENSES_TYPES_FAILURE,
  UPDATE_LENSES_TYPE_SUCCESS,
  CREATE_LENSES_TYPE_SUCCESS,
  DELETE_LENSES_TYPE_SUCCESS,
} from "../constants/lensesType";

const initialState = {
  lensesTypesList: [],
  loading: false,
  error: null,
};

let lenseTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LENSES_TYPE_SUCCESS:
      return {
        ...state,
        lensesTypesList: [...state.lensesTypesList, action.payload],
      };

    case LIST_ALL_LENSES_TYPES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_LENSES_TYPES_SUCCESS:
      return {
        ...state,
        lensesTypesList: action?.payload,
        loading: false,
      };

    case LIST_ALL_LENSES_TYPES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_LENSES_TYPE_SUCCESS: {
      const updatedLenseType = action.payload.lensType;
      return {
        ...state,
        lensesTypesList: state.lensesTypesList.map((lenseType) => {
          if (lenseType.id === updatedLenseType._id) {
            return {
              ...lenseType,
              lenses_type: updatedLenseType.lenses_type,
              description: updatedLenseType.description,
              price: updatedLenseType.price,
              colors: updatedLenseType.colors,
              is_active: updatedLenseType.is_active,
            };
          }
          return lenseType;
        }),
      };
    }

    case CREATE_LENSES_TYPE_SUCCESS: {
      const { lensType } = action.payload;
      const newLenseType = {
        id: lensType._id,
        lenses_type: lensType.lenses_type,
        description: lensType.description,
        price: lensType.price,
        colors: lensType.colors,
        is_active: lensType.is_active,
      };
      return {
        ...state,
        lensesTypesList: [...state.lensesTypesList, newLenseType],
      };
    }

    case DELETE_LENSES_TYPE_SUCCESS: {
      const lenseTypeId = action.payload;
      return {
        ...state,
        lensesTypesList: state.lensesTypesList.filter((lenseType) => lenseType.id !== lenseTypeId),
      };
    }

    default:
      return state;
  }
};

export default lenseTypeReducer;
