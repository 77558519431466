/* The Auth class is used to authorize a user and save a token string in cookies. */
// For get item use @returns {type} || no need to returns then use @param {type}
import Cookies from "js-cookie";
import { SECURITY_KEYS, STORAGE_KEYS } from "./app.keys";
import CryptoJS from "crypto-js";
import { setCookie } from "../utils/cookieUtils";
/* The Auth class provides methods for setting the authentication email in local storage and returning
a specific role ID. */

const COOKIE_OPTIONS = { sameSite: "strict", secure: true };

export class Auth {
  /**
   * The function sets the authentication email in the local storage.
   * @param email - The email parameter is the email address that will be stored in the local storage.
   */
  static setAdminEmail(email) {
    let data = CryptoJS.AES.encrypt(email, SECURITY_KEYS?.adminInfo).toString();
    Cookies.set(STORAGE_KEYS.adminService, data, { expires: 1, secure: true });
  }
  /**
   * The function `getUserEmail()` retrieves and decrypts a cookie containing an email address using
   * AES encryption.
   * @returns the decrypted email value obtained from the cookie.
   */
  static getAdminEmail() {
    let getCookies = Cookies.get(STORAGE_KEYS?.adminService);
    let bytes = CryptoJS.AES.decrypt(getCookies, SECURITY_KEYS?.adminInfo);
    let email = bytes.toString(CryptoJS.enc.Utf8);
    return email;
  }

  // static setAdminToken(token) {
  //   // const TEN_HOURS_IN_MS = 10 * 60 * 60 * 1000; // 10 hours in milliseconds

  //   // const expireTimeInDays = TEN_HOURS_IN_MS / (24 * 60 * 60 * 1000); // Convert to days

  //   Cookies.set(STORAGE_KEYS.adminToken, token, {
  //     secure: true,
  //   });

  //   const expirationDate = new Date();
  //   expirationDate.setDate(expirationDate.getDate() + 1);

  //   Cookies.set(STORAGE_KEYS.adminToken, token, {
  //     expires: expirationDate,
  //     ...COOKIE_OPTIONS,
  //   });
  // }

  static setAdminToken(token) {
    setCookie(STORAGE_KEYS.adminToken, token);
  }

  static getAdminToken() {
    let token = Cookies.get(STORAGE_KEYS?.adminToken);
    return token;
  }

  static userLogout() {
    // Get all existing cookies
    const cookies = Cookies.get();

    // Loop through cookies and remove each one
    Object.keys(cookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });
    return;
  }

  static roleAdmin() {
    const roleId = "64b0e5af6ca540f09b916c4f";
    return roleId;
  }

  /**
   * The function `userClear()` clears all existing cookies after a timeout of 1 day.
   * @returns Nothing is being returned. The return statement is used to exit the function early, but
   * since there is no value being returned, it is not necessary in this case.
   */
  static userClear() {
    // Get all existing cookies
    const cookies = Cookies.get();

    // Loop through cookies and remove each one
    Object.keys(cookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });

    return;
  }
}
