import { AdminAPI } from "../../helper/API/API.base";
import { getHeaders } from "../../helper/API/API.header";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  CREATE_TRENDING_SEARCH_SUCCESS,
  LIST_ALL_TRENDING_SEARCHES_REQUEST,
  LIST_ALL_TRENDING_SEARCHES_SUCCESS,
  LIST_ALL_TRENDING_SEARCHES_FAILURE,
  UPDATE_TRENDING_SEARCH_SUCCESS,
  DELETE_TRENDING_SEARCH_SUCCESS,
} from "../constants/trendingSearch";

export const listAllTrendingSearches = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LIST_ALL_TRENDING_SEARCHES_REQUEST });

      // header
      const headers = await getHeaders();

      // API
      const response = await AdminAPI.get("/trending-search", { headers });

      const trendingSearchList = [];
      response?.data?.forEach((x) => {
        trendingSearchList.push({
          id: x._id,
          title: x.title,
        });
      });

      dispatch({
        type: LIST_ALL_TRENDING_SEARCHES_SUCCESS,
        payload: trendingSearchList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_TRENDING_SEARCHES_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateTrendingSearch = (values, trendingSearchId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/trending-search/${trendingSearchId}`, body, {
        headers,
      });

      dispatch({
        type: UPDATE_TRENDING_SEARCH_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Trending search updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteTrendingSearch = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const trendingSearchId = values.id;

      // API
      const response = await AdminAPI.delete(`/trending-search/${trendingSearchId}`, { headers });

      dispatch({
        type: DELETE_TRENDING_SEARCH_SUCCESS,
        payload: trendingSearchId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Trending search deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createTrendingSearch = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.post(`/trending-search`, body, { headers });

      dispatch({
        type: CREATE_TRENDING_SEARCH_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Trending search added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
