import {
  ADD_PRODUCT_CATEGORIES_SUCCESS,
  CREATE_PRODUCT_CATEGORIES_SUCCESS,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  LIST_ALL_CATEGORIES_FAILURE,
  LIST_ALL_CATEGORIES_REQUEST,
  LIST_ALL_CATEGORIES_SUCCESS,
  UPDATE_PRODUCT_CATEGORIES_SUCCESS,
} from "../constants/category";

const initialState = {
  categoryList: [],
  loading: false,
  error: null,
};

let categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoryList: [...state.categoryList, action.payload],
      };

    case LIST_ALL_CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoryList: action?.payload,
        loading: false,
      };

    case LIST_ALL_CATEGORIES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_PRODUCT_CATEGORIES_SUCCESS: {
      const updatedCategory = action.payload.category;
      return {
        ...state,
        categoryList: state.categoryList.map((category) => {
          if (category.id === updatedCategory._id) {
            return {
              ...category,
              type: updatedCategory.type,
              description: updatedCategory.description,
              image: updatedCategory.image,
              is_active: updatedCategory.is_active,
            };
          }
          return category;
        }),
      };
    }

    case CREATE_PRODUCT_CATEGORIES_SUCCESS: {
      const { category } = action.payload;
      const newCategory = {
        id: category._id,
        type: category.type,
        description: category.description,
        image: category.image,
        is_active: category.is_active,

      };
      return {
        ...state,
        categoryList: [...state.categoryList, newCategory],
      };
    }

    case DELETE_PRODUCT_CATEGORY_SUCCESS: {
      const categoryId = action.payload;
      return {
        ...state,
        categoryList: state.categoryList.filter(
          (category) => category.id !== categoryId
        ),
      };
    }
    default:
      return state;
  }
};

export default categoryReducer;
