import {
  ADD_FRAME_FAILURE,
  ADD_FRAME_REQUEST,
  ADD_FRAME_SUCCESS,
  ADD_PRODUCT_FRAME_FAILURE,
  ADD_PRODUCT_FRAME_REQUEST,
  ADD_PRODUCT_FRAME_SUCCESS,
  UPDATE_PRODUCT_FRAME_REQUEST,
  UPDATE_PRODUCT_FRAME_SUCCESS,
  UPDATE_PRODUCT_FRAME_FAILURE,
} from "../../helper/config/constantsRedux.config";
import { AdminAPI, AdminProductFrameAPI } from "../../helper/API/API.base";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import { getHeaders } from "../../helper/API/API.header";

export const addFrameAction = (frameData, updatedColorData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: ADD_FRAME_REQUEST });
      // AXIOS BODY
      const body = {
        face_id: frameData?.face_type,
        model_no: frameData?.modelNo,
        frame_type_id: frameData?.frame_type,
        frame_shape_id: frameData?.frame_shape,
        colors: updatedColorData,
        frame_size_id: frameData?.frame_Size,
        frame_style: frameData?.frameStyle,
        frame_style_secondary: frameData?.frame_style_secondary,
        frame_material_id: frameData?.frame_Material,
        frame_width: frameData?.frameWidth,
        frame_height: frameData?.frameHeight,
        frame_weight: frameData?.frameWeight,
        frame_dimensions: frameData?.frameDimensions,
        gender: frameData?.gender,
      };

      // API
      const response = await AdminAPI.post("/frames", body, { headers });

      dispatch({
        type: ADD_FRAME_SUCCESS,
        payload: response?.data?.frame?._id,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      dispatch({
        type: ADD_FRAME_FAILURE,
        payload: error?.message,
      });

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const addProductFrameAction = (productFrameData, handleComplete, resetForm) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: ADD_PRODUCT_FRAME_REQUEST });
      // AXIOS BODY
      const body = productFrameData;
      const headers = await getHeaders();

      // API
      const response = await AdminProductFrameAPI.post("/add-product-frame", body, { headers });

      dispatch({
        type: ADD_PRODUCT_FRAME_SUCCESS,
        payload: response?.data?.productFrame?._id,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product frame added successfully!");
        handleComplete();
        resetForm();
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      dispatch({
        type: ADD_PRODUCT_FRAME_FAILURE,
        payload: error?.message,
      });

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateProductFrameAction = (id, productFrameData, handleComplete, resetForm) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: UPDATE_PRODUCT_FRAME_REQUEST });
      // AXIOS BODY
      const body = productFrameData;
      const headers = await getHeaders();

      // API
      const response = await AdminProductFrameAPI.put(`/${id}`, body, { headers });

      dispatch({
        type: UPDATE_PRODUCT_FRAME_SUCCESS,
        payload: response?.data?.productFrame?._id,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Product frame updated successfully!");
        handleComplete();
        resetForm();
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_FRAME_FAILURE,
        payload: error?.message,
      });

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
