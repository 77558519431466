/* The above code is defining constants for various actions related to user management, user addresses,
and user reviews in a JavaScript application. Each constant represents a specific action and is used
to dispatch actions and handle the corresponding state changes in the application. */

//------------------------------------------------------------------//
// USER CONSTANTS //
//------------------------------------------------------------------//
// LOGIN USER
export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILURE = "LOGIN_ADMIN_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// SIGN IN GOOGLE
export const GOOGLE_SIGN_IN_REQUEST = "GOOGLE_SIGN_IN_REQUEST";
export const GOOGLE_SIGN_IN_SUCCESS = "GOOGLE_SIGN_IN_SUCCESS";
export const GOOGLE_SIGN_IN_FAILURE = "GOOGLE_SIGN_IN_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// OTP VERIFY USER
export const OTP_VERIFY_ADMIN_REQUEST = "OTP_VERIFY_ADMIN_REQUEST";
export const OTP_VERIFY_ADMIN_SUCCESS = "OTP_VERIFY_ADMIN_SUCCESS";
export const OTP_VERIFY_ADMIN_FAILURE = "OTP_VERIFY_ADMIN_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// RESET USER PASSWORD
export const RESET_USER_PASS_REQUEST = "RESET_USER_PASS_REQUEST";
export const RESET_USER_PASS_SUCCESS = "RESET_USER_PASS_SUCCESS";
export const RESET_USER_PASS_FAILURE = "RESET_USER_PASS_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET SINGLE USER
export const GET_SINGLE_USER_REQUEST = "GET_SINGLE_USER_REQUEST";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAILURE = "GET_SINGLE_USER_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// UPDATE USER
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// USER ADDRESS CONSTANTS //
//------------------------------------------------------------------//
// ADD USER ADDRESS
export const ADD_USER_ADDRESS_REQUEST = "ADD_USER_ADDRESS_REQUEST";
export const ADD_USER_ADDRESS_SUCCESS = "ADD_USER_ADDRESS_SUCCESS";
export const ADD_USER_ADDRESS_FAILURE = "ADD_USER_ADDRESS_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET SINGLE USER ADDRESS
export const GET_SINGLE_USER_ADDRESS_REQUEST =
  "GET_SINGLE_USER_ADDRESS_REQUEST";
export const GET_SINGLE_USER_ADDRESS_SUCCESS =
  "GET_SINGLE_USER_ADDRESS_SUCCESS";
export const GET_SINGLE_USER_ADDRESS_FAILURE =
  "GET_SINGLE_USER_ADDRESS_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET ALL USER ADDRESS
export const GET_ALL_USER_ADDRESS_REQUEST = "GET_ALL_USER_ADDRESS_REQUEST";
export const GET_ALL_USER_ADDRESS_SUCCESS = "GET_ALL_USER_ADDRESS_SUCCESS";
export const GET_ALL_USER_ADDRESS_FAILURE = "GET_ALL_USER_ADDRESS_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// UPDATE USER ADDRESS
export const UPDATE_USER_ADDRESS_REQUEST = "UPDATE_USER_ADDRESS_REQUEST";
export const UPDATE_USER_ADDRESS_SUCCESS = "UPDATE_USER_ADDRESS_SUCCESS";
export const UPDATE_USER_ADDRESS_FAILURE = "UPDATE_USER_ADDRESS_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// DELETE USER ADDRESS
export const DELETE_USER_ADDRESS_REQUEST = "DELETE_USER_ADDRESS_REQUEST";
export const DELETE_USER_ADDRESS_SUCCESS = "DELETE_USER_ADDRESS_SUCCESS";
export const DELETE_USER_ADDRESS_FAILURE = "DELETE_USER_ADDRESS_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
//------------------------------------------------------------------//
// FACE Type AN ADMIN
export const GET_FACE_TYPE_REQUEST = "GET_FACE_TYPE_REQUEST";
export const GET_FACE_TYPE_SUCCESS = "GET_FACE_TYPE_SUCCESS";
export const GET_FACE_TYPE_FAILURE = "GET_FACE_TYPE_FAILURE";
//------------------------------------------------------------------//
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// COLOR CONSTANTS //
//------------------------------------------------------------------//
// ADD COLOR CONSTANTS
export const ADD_COLOR_REQUEST = "ADD_COLOR_REQUEST";
export const ADD_COLOR_SUCCESS = "ADD_COLOR_SUCCESS";
export const ADD_COLOR_FAILURE = "ADD_COLOR_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET ALL COLORS CONSTRUCT
export const GET_ALL_COLOR_REQUEST = "GET_ALL_COLOR_REQUEST";
export const GET_ALL_COLOR_SUCCESS = "GET_ALL_COLOR_SUCCESS";
export const GET_ALL_COLOR_FAILURE = "GET_ALL_COLOR_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// ADD FRAME CONTEXT
export const ADD_FRAME_REQUEST = "ADD_FRAME_REQUEST";
export const ADD_FRAME_SUCCESS = "ADD_FRAME_SUCCESS";
export const ADD_FRAME_FAILURE = "ADD_FRAME_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// ADD FRAME CONTEXT
export const ADD_PRODUCT_FRAME_REQUEST = "ADD_PRODUCT_FRAME_REQUEST";
export const ADD_PRODUCT_FRAME_SUCCESS = "ADD_PRODUCT_FRAME_SUCCESS";
export const ADD_PRODUCT_FRAME_FAILURE = "ADD_PRODUCT_FRAME_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// UPDATE FRAME CONTEXT
export const UPDATE_PRODUCT_FRAME_REQUEST = "UPDATE_PRODUCT_FRAME_REQUEST";
export const UPDATE_PRODUCT_FRAME_SUCCESS = "UPDATE_PRODUCT_FRAME_SUCCESS";
export const UPDATE_PRODUCT_FRAME_FAILURE = "UPDATE_PRODUCT_FRAME_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// ADD FRAME SHAPE CONTEXT
export const ADD_FRAME_SHAPE_REQUEST = "ADD_FRAME_SHAPE_REQUEST";
export const ADD_FRAME_SHAPE_SUCCESS = "ADD_FRAME_SHAPE_SUCCESS";
export const ADD_FRAME_SHAPE_FAILURE = "ADD_FRAME_SHAPE_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET ALL FRAME SHAPE CONTEXT
export const GET_ALL_FRAME_SHAPE_REQUEST = "GET_ALL_FRAME_SHAPE_REQUEST";
export const GET_ALL_FRAME_SHAPE_SUCCESS = "GET_ALL_FRAME_SHAPE_SUCCESS";
export const GET_ALL_FRAME_SHAPE_FAILURE = "GET_ALL_FRAME_SHAPE_FAILURE";
//------------------------------------------------------------------//


//------------------------------------------------------------------//

//------------------------------------------------------------------//
// BRAND CONSTANTS //
//------------------------------------------------------------------//
// ADD PRODUCT BRAND CONSTANTS
export const ADD_PRODUCT_BRAND_REQUEST = "ADD_PRODUCT_BRAND_REQUEST";
export const ADD_PRODUCT_BRAND_SUCCESS = "ADD_PRODUCT_BRAND_SUCCESS";
export const ADD_PRODUCT_BRAND_FAILURE = "ADD_PRODUCT_BRAND_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET ALL PRODUCT BRAND CONSTANTS
export const GET_ALL_PRODUCT_BRAND_REQUEST = "GET_ALL_PRODUCT_BRAND_REQUEST";
export const GET_ALL_PRODUCT_BRAND_SUCCESS = "GET_ALL_PRODUCT_BRAND_SUCCESS";
export const GET_ALL_PRODUCT_BRAND_FAILURE = "GET_ALL_PRODUCT_BRAND_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// CATEGORIES CONSTANTS //
//------------------------------------------------------------------//
// ADD PRODUCT CATEGORIES CONSTANTS
export const ADD_PRODUCT_CATEGORIES_REQUEST = "ADD_PRODUCT_CATEGORIES_REQUEST";
export const ADD_PRODUCT_CATEGORIES_SUCCESS = "ADD_PRODUCT_CATEGORIES_SUCCESS";
export const ADD_PRODUCT_CATEGORIES_FAILURE = "ADD_PRODUCT_CATEGORIES_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET ALL PRODUCT CATEGORIES CONSTANTS
export const GET_ALL_PRODUCT_CATEGORIES_REQUEST =
  "GET_ALL_PRODUCT_CATEGORIES_REQUEST";
export const GET_ALL_PRODUCT_CATEGORIES_SUCCESS =
  "GET_ALL_PRODUCT_CATEGORIES_SUCCESS";
export const GET_ALL_PRODUCT_CATEGORIES_FAILURE =
  "GET_ALL_PRODUCT_CATEGORIES_FAILURE";
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// UPDATE PRODUCT CATEGORY CONSTANTS
export const UPDATE_PRODUCT_CATEGORIES_REQUEST =
  "UPDATE_PRODUCT_CATEGORIES_REQUEST";
export const UPDATE_PRODUCT_CATEGORIES_SUCCESS =
  "UPDATE_PRODUCT_CATEGORIES_SUCCESS";
export const UPDATE_PRODUCT_CATEGORIES_FAILURE =
  "UPDATE_PRODUCT_CATEGORIES_FAILURE";
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//
