import { AdminAPI } from "../../helper/API/API.base";
import { getHeaders } from "../../helper/API/API.header";
import { Auth } from "../../helper/Auth";
import { ErrorToast, SuccessToast } from "../../helper/Toast";
import { toastMessage } from "../../helper/config/toastMessage.config";
import {
  CREATE_FRAME_MATERIAL_SUCCESS,
  LIST_ALL_FRAME_MATERIALS_SUCCESS,
  UPDATE_FRAME_MATERIAL_SUCCESS,
  LIST_ALL_FRAME_MATERIALS_REQUEST,
  LIST_ALL_FRAME_MATERIALS_FAILURE,
  DELETE_FRAME_MATERIAL_SUCCESS,
} from "../constants/frameMaterial";

export const listAllFrameMaterial = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      dispatch({ type: LIST_ALL_FRAME_MATERIALS_REQUEST });

      // API
      const response = await AdminAPI.get("/frame-material", { headers });

      const frameMaterialList = [];
      response?.data?.map((x) => {
        frameMaterialList.push({
          id: x._id,
          frame_material: x.frame_material,
          is_active: x.is_active,
        });
      });

      dispatch({
        type: LIST_ALL_FRAME_MATERIALS_SUCCESS,
        payload: frameMaterialList,
      });

      resolve(response);
    } catch (error) {
      dispatch({
        type: LIST_ALL_FRAME_MATERIALS_FAILURE,
        payload: error?.message,
      });

      // if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      // else
      if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const updateFrameMaterial = (values, frameMaterialId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = values;

      // API
      const response = await AdminAPI.put(`/frame-material/${frameMaterialId}`, body, { headers });

      dispatch({
        type: UPDATE_FRAME_MATERIAL_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame material updated successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {
      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const deleteFrameMaterial = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const frameMaterialId = values.id;

      // API
      const response = await AdminAPI.delete(`/frame-material/${frameMaterialId}`, { headers });

      dispatch({
        type: DELETE_FRAME_MATERIAL_SUCCESS,
        payload: frameMaterialId,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame material deleted successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};

export const createFrameMaterial = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = await getHeaders();

      // AXIOS BODY
      const body = {
        frame_material: values?.frame_material,
      };

      // API
      const response = await AdminAPI.post(`/frame-material`, body, {
        headers,
      });

      dispatch({
        type: CREATE_FRAME_MATERIAL_SUCCESS,
        payload: response?.data,
      });

      if (response?.data?.message?.status === 200) {
        SuccessToast("Frame material added successfully!");
      } else if (response?.data?.res?.status === 500) {
        ErrorToast(response?.data?.res?.message);
      }
      resolve(response);
    } catch (error) {

      if (error) ErrorToast(toastMessage?.ERROR_TRY_AGAIN);
      else if (error?.message === "Network Error") ErrorToast(toastMessage?.NETWORK_ERROR);
      else return;

      reject(error);
    }
  }).catch((catchError) => {
    throw new Error(catchError)
  });
};
