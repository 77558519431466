import React, { useEffect } from "react";
import { Box } from "@mui/material";
// import Modal from "@mui/material/Modal";
// import { GridActionsCellItem } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../components/Table";
// import { newsLetterSchema } from "../../helper/yup.schema";
import { MainPadding } from "../../assets/css/styled";
// import { modelStyle } from "../../assets/css/muiStyled";
// import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
// import { Field, Form, Formik } from "formik";
// import FormErrorMessage from "../../components/FormErrorMessage";
import { listAllNewsLetter } from "../../redux/actions/newsLetterAction";

const NewsLetter = () => {
  // const [formInitialValues, setFormInitialValues] = useState({
  //   email: "",
  // });
  // const [selectedNewsLetterId, setSelectedNewsLetterId] = useState(null);
  // const [open, setOpen] = useState(false);
  // const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();
  const { newsLetterList } = useSelector((state) => state.newsLetter);

  useEffect(() => {
    dispatch(listAllNewsLetter());
  }, [dispatch]);

  // const handleDeleteClick = (id) => () => {
  //   dispatch(deleteTrendingSearch({ id }));
  // };

  // const handleEditClick = (id) => () => {
  //   setSelectedNewsLetterId(id);
  //   newsLetterList.forEach((x) => {
  //     if (x.id === id) {
  //       setFormInitialValues({
  //         email: x?.email,
  //       });
  //     }
  //   });
  //   setOpen(true);
  //   setEdit(true);
  // };

  const columns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Actions",
    //   flex: 1,
    //   cellClassName: "actions",
    //   getActions: ({ id }) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<EditIcon />}
    //         label="Edit"
    //         onClick={handleEditClick(id)}
    //         color="inherit"
    //       />,
    //       <GridActionsCellItem
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         onClick={handleDeleteClick(id)}
    //         color="inherit"
    //       />,
    //     ];
    //   },
    // },
  ];

  // const handleAddCategory = () => {
  //   setOpen(true);
  //   setEdit(false);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   setFormInitialValues({
  //     email: "",
  //   });
  // };

  

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <Header
            title="News Letter"
            subtitle="View All The News Letter"
          />
          {/* <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddCategory}>
              Add New NewsLetter
            </ButtonWrapper>
          </div> */}
        </div>
        <Table
          columns={columns}
          rows={newsLetterList}></Table>

        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Trending Search`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <Formik
                initialValues={formInitialValues}
                validationSchema={newsLetterSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}>
                {({ touched, errors }) => (
                  <Form>
                    <div className="technical_information_text_filed mt-5">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Title</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Material Type"
                            name="email"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.email && errors.email && <FormErrorMessage errors={errors.email} />}
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner_update">
                      <ButtonWrapper
                        auto={"auto"}
                        type="submit">{`${edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal> */}
      </Box>
    </MainPadding>
  );
};

export default NewsLetter;
