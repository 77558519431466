// framesGender Data
export const GenderData = [
  {
    gender: "Male",
    value: "male",
  },
  {
    gender: "Female",
    value: "female",
  },
  {
    gender: "Boys",
    value: "boys",
  },
  {
    gender: "Girls",
    value: "girls",
  },
  {
    gender: "Other",
    value: "other",
  },
];
