import {
  ADD_FRAME_SIZE_SUCCESS,
  LIST_ALL_FRAME_SIZES_REQUEST,
  LIST_ALL_FRAME_SIZES_SUCCESS,
  LIST_ALL_FRAME_SIZES_FAILURE,
  UPDATE_FRAME_SIZE_SUCCESS,
  CREATE_FRAME_SIZE_SUCCESS,
  DELETE_FRAME_SIZE_SUCCESS,
} from "../constants/frameSizes";

const initialState = {
  frameSizeList: [],
  loading: false,
  error: null,
};

let frameSizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FRAME_SIZE_SUCCESS:
      return {
        ...state,
        frameSizeList: [...state.frameSizeList, action.payload],
      };

    case LIST_ALL_FRAME_SIZES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_FRAME_SIZES_SUCCESS:
      return {
        ...state,
        frameSizeList: action?.payload,
        loading: false,
      };

    case LIST_ALL_FRAME_SIZES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_FRAME_SIZE_SUCCESS: {
      const updatedFrameSize = action.payload.frameSize;
      return {
        ...state,
        frameSizeList: state.frameSizeList.map((frame_size) => {
          if (frame_size.id === updatedFrameSize._id) {
            return {
              ...frame_size,
              frame_size: updatedFrameSize.frame_size,
              is_active: updatedFrameSize.is_active,
            };
          }
          return frame_size;
        }),
      };
    }

    case CREATE_FRAME_SIZE_SUCCESS: {
      const frameSize = action.payload.frameSize[0];
      const newframeSize = {
        id: frameSize._id,
        frame_size: frameSize.frame_size,
        is_active: frameSize.is_active,
      };
      return {
        ...state,
        frameSizeList: [...state.frameSizeList, newframeSize],
      };
    }

    case DELETE_FRAME_SIZE_SUCCESS: {
      const frameSizeId = action.payload;
      return {
        ...state,
        frameSizeList: state.frameSizeList.filter((frame_size) => frame_size.id !== frameSizeId),
      };
    }
    default:
      return state;
  }
};

export default frameSizeReducer;
