/**
 * The above code is a React component that renders a back button with an icon and text.
 * @returns The component `Back` is being returned.
 */
import React from "react";
import { AuthLoginBackIcon } from "./typography/Icons";
import { Para16Tag } from "../assets/css/styled";

const Back = ({ text, color }) => {
  /**
   * The handleBack function is used to navigate back to the previous page in the browser history.
   */
  const handleBack = () => window.history.back();

  return (
    <button
      onClick={handleBack}
      style={{ display: "flex" }}
      className="login_right_back_page_main_div_inner"
    >
      <AuthLoginBackIcon />
      <Para16Tag color={color ? color : "#868A8B"} weight={500}>
        {text}
      </Para16Tag>
    </button>
  );
};

export default Back;
