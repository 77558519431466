import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Table from "../../components/Table";
import { singleVisionTypeSchema } from "../../helper/yup.schema";
import { ButtonWrapper, FormInputLabel, Head24Tag, MainPadding, Para14Tag, Para16Tag } from "../../assets/css/styled";
import { modelStyle } from "../../assets/css/muiStyled";
import ModalCloseButton from "../../components/modalCloseButton/ModalCloseButton";
import { Field, Form, Formik } from "formik";
import FormErrorMessage from "../../components/FormErrorMessage";
import {
  listAllSingleVisionType,
  createSingleVisionType,
  updateSingleVisionType,
  deleteSingleVisionType,
} from "../../redux/actions/singleVisionTypeAction";
import { CheckBoxCustom } from "../../components/typography/CheckBox";

const SingleVisionTypes = () => {
  const [formInitialValues, setFormInitialValues] = useState({
    single_vision_type: "",
    description: "",
    price: "",
  });
  const [selectedSingleVisionTypeId, setSelectedSingleVisionTypeId] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);

  const dispatch = useDispatch();
  const { singleVisionTypesList } = useSelector((state) => state.singleVisionTypes);

  useEffect(() => {
    dispatch(listAllSingleVisionType());
  }, [dispatch]);

  const handleDeleteClick = (id) => () => {
    dispatch(deleteSingleVisionType({ id }));
  };

  const handleEditClick = (id) => () => {
    setSelectedSingleVisionTypeId(id);
    singleVisionTypesList.forEach((x) => {
      if (x.id === id) {
        setFormInitialValues({
          single_vision_type: x?.single_vision_type,
          description: x?.description,
          price: x?.price,
        });
        setActive(x?.is_active);
      }
    });
    setOpen(true);
    setEdit(true);
  };

  const columns = [
    {
      field: "single_vision_type",
      headerName: "Single Vision Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      headerAlign: "left",
      flex: 1,
      align: "left",
      editable: true,
    },
    {
      field: "is_active",
      headerName: "Active",
      headerAlign: "center",
      flex: 1,
      align: "center",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleAddCategory = () => {
    setOpen(true);
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setActive(false);
    setFormInitialValues({
      single_vision_type: "",
      description: "",
      price: "",
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    try {
      const body = {
        single_vision_type: values?.single_vision_type,
        description: values?.description,
        price: values?.price,
        is_active: active,
      };
      if (edit) {
        dispatch(updateSingleVisionType(body, selectedSingleVisionTypeId));
        setOpen(false);
        setEdit(false);
        setActive(false);
        setFormInitialValues({
          single_vision_type: "",
          description: "",
          price: "",
        });
        return;
      }

      // Create API
      dispatch(createSingleVisionType(body));
      resetForm();
      setActive(false);
      setOpen(false);
    } catch (error) {
      throw new Error(error)
    }
  };

  return (
    <MainPadding
      p_bottom={20}
      p_top={20}>
      <Box
        m="20px"
        mb={0}
        mt={0}>
        <div className="flex w-full justify-between items-center">
          <div>

          <Header
            title="Single Vision Types"
            subtitle="View All The Single vision types"
          />
          <Para16Tag className="font-black mt-2 max-w-xl">Note: Do not change any words because our logic and conditions are based on this text. If you change any word casing, then our flow will not work</Para16Tag>
          </div>
          <div className="flex justify-end">
            <ButtonWrapper
              variant="outlined"
              onClick={handleAddCategory}>
              Add New Single Vision Types
            </ButtonWrapper>
          </div>
        </div>
        <Table
          columns={columns}
          rows={singleVisionTypesList}></Table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modelStyle}>
            <div className="category_modal_add_form_main_div">
              <div className="payment_model_inner_head_main">
                <Head24Tag
                  color="#0E1213"
                  weight="600">
                  {`${edit ? "Update" : "Add"} Single Vision Type`}
                </Head24Tag>
              </div>
              <div
                className="network_insurance_modal_close"
                onClick={handleClose}>
                <ModalCloseButton />
              </div>
            </div>

            <div className="category_add_frame_form">
              <Formik
                initialValues={formInitialValues}
                validationSchema={singleVisionTypeSchema}
                onSubmit={handleSubmit}>
                {({ touched, errors }) => (
                  <Form>
                    <div className="technical_information_text_filed mt-5">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Single Vision Type</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Single Vision Type"
                            name="single_vision_type"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.single_vision_type && errors.single_vision_type && <FormErrorMessage errors={errors.single_vision_type} />}
                      </div>
                    </div>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Description</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Description"
                            name="description"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.description && errors.description && <FormErrorMessage errors={errors.description} />}
                      </div>
                    </div>
                    <div className="technical_information_text_filed">
                      <div className="form_inner_input_div_main">
                        <FormInputLabel>Price</FormInputLabel>
                        <div className="form_input_main">
                          <Field
                            type="text"
                            placeholder="Enter Description"
                            name="price"
                            className="from_input_tag"
                          />
                        </div>
                        {touched.price && errors.price && <FormErrorMessage errors={errors.price} />}
                      </div>
                    </div>
                    {/* Active checkbox */}
                    <div className="technical_information_text_filed">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="form_input_forget_text_main_pass_inner mt-4">
                        <div className="form_get_update_text_data_title">
                          <div
                            style={{ cursor: "pointer" }}
                            className="add_new_add_default_address">
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}>
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                className="main_check_box">
                                <CheckBoxCustom
                                  id="is_active"
                                  name="is_active"
                                  checked={active}
                                  onChange={() => setActive(!active)}
                                />
                              </div>
                              <div className="label_text">
                                <Para14Tag
                                  weight={500}
                                  color="#A7ABAC">
                                  Active
                                </Para14Tag>
                              </div>
                              {touched.is_active && errors.is_active && <FormErrorMessage errors={errors.is_active} />}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form_sign_in_btn_main_inner_update">
                      <ButtonWrapper
                        auto={"auto"}
                        type="submit">{`${edit ? "Update" : "Create"}`}</ButtonWrapper>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </Box>
    </MainPadding>
  );
};

export default SingleVisionTypes;
